import React, {Component} from 'react';
import { Container, Row, Col, Table, Spinner, Form, Button, ListGroup, Navbar, Nav, } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap';
import store from '../Store';
import api from '../Api'

class MiniChatMessages extends Component
{
    constructor(props)
    {
        super(props);
        this.state={};
    }

    render()
    {
        const {messages} = this.props;
        return(<ListGroup variant='flush'>
                {messages.map((message, index)=>
                <ListGroup.Item className="bg-light" key={index.toString()} style={{'padding':'1px'}}>
    {message.date_time} : <Link to={"/user/"+message.id_user}>{message.name_user}</Link> - {message.message}
                </ListGroup.Item>
                )}
            </ListGroup>)
    }
};


class MiniChat extends Component
{
    constructor(props)
    {
        super(props);
        this.state={mini_chat_message:'',
            messages:{},
            Entities:[],
            isLoad:false,
            Color:[],
            filter:'all'};
        this.SendMesage=this.SendMesage.bind(this);
        this.LoadMessages = this.LoadMessages.bind(this); 
    }
    UNSAFE_componentWillMount()
    {
        this.LoadMessages();
    }
    SendMesage(e)
    {
        e.preventDefault();
        if(this.state.mini_chat_message)
            api("GET","mini_chat","add-message",{message:this.state.mini_chat_message, token:store.User.token})
                .then((result)=>{
                    if(result.Result==='ok')
                    {
                        this.setState({mini_chat_message:''});
                        this.LoadMessages();
                    }
                    else
                        console.log(result);                        
                });
    }

    LoadMessages()
    {
        this.setState({isLoad:false});
        api("GET","mini_chat","get-messages",{})
        .then((result)=>{
            this.setState({messages:result, isLoad:true});
        })
        .then(()=>
        {api("GET", "orders-task", "get-orders-task", {filter:this.state.filter, token: store.User.token})
        .then(Result=>{this.setState({Entities:Result, isLoad:true})})});
    }
    render()
    {
        const {messages, isLoad, Entities} = this.state;
        return(<Container  style={{paddingTop:'20px'}}>
            <Row>
                <Col>
                    {isLoad?(<MiniChatMessages messages={messages}/>):
                    (<Spinner animation="border" />)}
                </Col>
            </Row>
            <Form onSubmit={(e)=>{this.SendMesage(e)}}>
                <Row noGutters>
                    <Col lg={2}>Сказать:</Col>
                    <Col lg={8}>
                        <Form.Control type="text" name="chat_text" size="sm"
                            value={this.state.mini_chat_message}
                            onChange={(e)=>{this.setState({mini_chat_message:e.target.value})}}/>
                    </Col>
                    <Col lg={2}>
                        <Button variant="info" type="submit" size="sm">
                                Отправить</Button>
                    </Col>

                </Row>
                </Form>
                <p></p>
                <p></p>
                <div >
                <div>Задачи</div>
                <Form>
                <Form.Check type='switch' name="filter" id="custom-switch" label="Отображать только мои задачи"  
                        	onClick={(e)=>{
                                e.target.value = e.target.checked ? 'one' : 'all';
                                this.setState({filter: e.target.value}, () => {this.LoadMessages()});
                                }}/>
                <Button size="sm" className="float-right">
        <LinkContainer to="/orders"><Nav.Link><h5 style={{ color: 'white' }}>Заказы</h5></Nav.Link></LinkContainer>
        </Button>
        </Form>
                {isLoad?(<Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Задача</th>
                        <th>Подробности</th>
                        <th>Планируемая дата исполнения </th>
                        <th>Дата выполнения </th>
                        <th>Ответственный</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    Entities.map((Row, key)=>{return( (Row.color==1||Row.color!=2 ?(<tr key={key} 
                    class={
						(Row.color==1)?
						"table-warning":
						(Row.color==2)?
						"table-success":
						'table-danger'
						}>
                    <td style={{'max-width': '300px'}}>{Row.task}</td>
                    <td style={{'max-width': '400px'}}>{Row.comment}</td>
                    <td>{Row.workdate}</td>
                    <td>{Row.finishdate}</td>
                    <td>{Row.worker}</td>
                    </tr>):(''))
                      )
                    })
                   }
                </tbody>
            </Table>):(<Spinner animation="border"/>)} </div>
        </Container>
        ); 
        

    }
}



export default MiniChat;