import React, {Component, useState} from 'react';
import { Container, Tab, Tabs, Table, Spinner, Button, Modal, Form } from 'react-bootstrap';
import api from '../Api';
import Select from '../Components/SelectDictionary';
import DropdownTreeSelect from "react-dropdown-tree-select";
import 'react-dropdown-tree-select/dist/styles.css';
import { result } from 'lodash';
import store from '../Store';

class fix extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {isLoad:false,
        Entities:{},
        EntityModalParam:{show:false}};
        
    }
    LoadData = () =>{
        this.setState({isLoad:false});
        api("GET", "device-fix", "get-device-fix")
        .then(Result=>{this.setState({Entities:Result, isLoad:true})});
        console.log(this.state.Entities);
    }
    componentDidMount()
    {
        this.LoadData();
    }
    

    render()
    {
        //const [EntityModalParam, setEntityModalParam] = React.useState({show:false});
        const {isLoad, Entities, EntityModalParam} = this.state;
        console.log(this.state.Entities);
        return(<div>
            <EntityModal show={EntityModalParam.show}
                onHide={()=>{
                    this.setState({EntityModalParam:{show:false}})
                }}
                LoadData={this.LoadData}
                    param={EntityModalParam}
            />
            {isLoad?(<Table striped btabed hover size="sm">
            <thead>
                <tr>
                    <th>Kонтрагент</th>
                    <th>Модель аппарата</th>
                    <th>Дата отправки</th>
                    <th>Дата получения</th>
                    <th>Описание проблемы</th>
                    <th>Описание ремонта</th>
                    <th>Комплектация</th>
                    <th>Статус</th>
                    <th style={{width:'30px'}}>
                        <Button size="sm"
                        onClick={()=>{this.setState({EntityModalParam:{show:true}})}}>
                            Добавить
                        </Button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {Entities.map((Row, key)=>{return(<tr key={key}>
                <td>{Row.conterparty}</td>
                <td>{Row.devmodel}</td>
                <td>{Row.dategive}</td>
                <td>{Row.dateaccept}</td>
                <td>{Row.pdescription}</td>
                <td>{Row.fixdescription}</td>
                <td>{Row.complect}</td>
                <td>{Row.status}</td>
                <td>
                    <Button size="sm"
                        onClick={()=>{this.setState({EntityModalParam:{show:true,
                            id:Row.id}})}}>
                        Редактировать
                    </Button>
                </td>
                </tr>)})}
            </tbody>
        </Table>):(<Spinner animation="border"/>)}</div>);
    }
};

class EntityModal extends Component
{
    data={
        id:this.props.param.id,
        conterparty:'',
        devmodel:'',
        provider:'',
        taccept:'',
        tgive:'',
        dateaccept:'',
        numberaccept:0,
        dategive:'',
        numbergive:0,
        pdescription:'',
        fixdescription:'',
        userfix:'',
        complect:'',
        package:'',
        status:'',
        users:'',
        name:'',
        
    }
    
    
       
    
    
    constructor(props)  
    {
        super(props)

        this.state={
            isLoading:false,
            data:this.data,
            comdata:[],
            users:[],
            value: '',
            devicemodels:[],
            devicemodel:[],
            tree:{},
            droptree:{}
            
            
            
        };
        this.AddData = this.AddData.bind(this);
        this.AddStatus = this.AddStatus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        
    }
    componentWillMount()
    {
            api("GET","device-fix","get-users",{idp:this.props.param.id})
            .then(result=>{this.setState({users:result, isLoading:true}); 
            console.log(this.state.users)}) 
            .then(()=>
            {
                api("GET","device-fix","details")
            .then(result=>{this.setState({devicemodels:result, isLoading:true}, ()=> console.log(this.state.devicemodels))} )
            // , hui:result.map((result) => result.name)
        })
       
    }
    
   

    componentDidUpdate()
    {
        
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "device-fix", "get-device-fix-by-id",{id:this.props.param.id})
            .then(result=>this.setState({data:result, isLoading:true}))
            .then(() =>{
                            api("GET", "device-fix", "get-comments",{idp:this.props.param.id})
                            .then(result=>{this.setState({comdata:result, isLoading:true}); 
                            console.log(this.state.comdata)})
                        });
        }
        
    }
    Save = () =>
{
                api("GET", "device-fix", "set-device-fix-data",
                {data:this.state.data, droptree:this.state.droptree, token:store.User.token})
        .then(()=>{
                    this.props.LoadData();
                    this.Close();
});
}
    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data, isLoading:false});
    }

    Delete = () =>
    {
        api("GET", "device-fix", "delete-device-fix",{id:this.props.param.id})
           .then(()=>{
            this.props.LoadData();   
            this.Close()
        });   

        

    }

    AddData()
	{
        console.log(this.state.data)
		api("GET","device-fix","add-device-fix-data",{id:this.state.data.id?this.state.data.id:0,
            data:this.state.data, droptree:this.state.droptree, token:store.User.token})
        .then(()=>{
            this.props.LoadData();   
            this.Close()
        })
      
}

AddStatus()
	{
        console.log(this.state)
		api("GET","status","add-status",{status:this.state.data.status})
        .then(()=>{
            this.props.LoadData();   
            this.Close()
        });

        
}

AddCommentar(e)
	{
		e.preventDefault();
		this.setState({isComLoad:false});
		api("GET","device-fix","addcom",{idp:this.props.param.id,comment:this.state.commentar,token:store.User.token})
				.then((result)=>{
                    if(result.Result==='done')
                    {
						this.LoadComments();
						this.setState({commentar:''});
                    }
                    else
                        console.log(result);                        
                })
	}
	
	

AddCounterparty()
        {
            const {parent} = this.props
            const {count, status} = this.state
     
            if(parent.type==='model')
            {
                api('GET', 'device-fix', 'add',{id: parent.id, count: count, status: status})
                .then((result)=>{   
                    this.props.reload();
                });
            }
            else if(parent.type==='unit')
            {
                api('GET', 'device-fix', 'add',{id: parent.id, count: count, status: status})
                .then((result)=>{   
                    this.props.reload();
                });
            }
        }


 conterpartyChange(e) {
     this.setState({data:{...this.state.data, conterparty:e.target.label}});
     }
     tacceptChange(e) {
         this.setState({data:{...this.state.data, taccept:e.target.label}});
         }
         tgiveChange(e) {
             this.setState({data:{...this.state.data, tgive:e.target.label}});
             }
             providerChange(e) {
                 this.setState({data:{...this.state.data, provider:e.target.label}});
                 }
                 StatusChange(e) {
                     this.setState({data:{...this.state.data, status:e.target.label}});
                     }
                     userfixChange(e) {
                         this.setState({data:{...this.state.data, userfix:e.target.label}});
                         }


    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});
   
    // handleChange(e) {
    //     this.setState({devicemodel:e.target.value});
    //    }

       handleSubmit(e) {
           e.preventDefault()};
            
    render()
    {
        const {data, comments, isComLoad, categories} = this.state;
        const dropdown = (currentNode, selectedNodes) => {
           this.state.tree = selectedNodes.map((tree )=>
            tree.label)
            console.log(this.state.tree)
            this.state.droptree = ''
            for (let i = 0; i < this.state.tree.length; i++) {
                this.state.droptree = this.state.droptree + this.state.tree[i] + ', ';
              }
          }
        data.id=this.props.param.id;
        return(<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
              <Form>
                <Modal.Header>
                    <Modal.Title>
                      {this.props.param.id?'Редактировать заказ':'Добавить заказ'}
                      {this.props.param.id && <Button onClick={this.Delete}>Удалить</Button>}
                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Kонтрагента</Form.Label>
                        <Select placeholder={this.state.data.conterparty}
                                dictionary="counterparties" 
                                name="name"
                                onChange={(e)=>{this.conterpartyChange(e)}}/> 
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Поставщик</Form.Label>
                        <Select placeholder={this.state.data.provider}
                                dictionary="counterparties" 
                                name="provider"
                                onChange={(e)=>{this.providerChange(e)}}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Транспортная Компания получения</Form.Label>
                        <Select placeholder={this.state.data.taccept}
                                dictionary="counterparties" 
                                name="taccept"
                                onChange={(e)=>{this.tacceptChange(e)}}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Дата отправки</Form.Label>
                        <Form.Control type="date" name="dategive" size="sm"
                            value={data.dategive} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Дата получения</Form.Label>
                        <Form.Control type="date" name="dateaccept" size="sm"
                            value={data.dateaccept} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Номер накладной получения</Form.Label>
                        <Form.Control type="nember" name="numberaccept" size="sm"
                            value={data.numberaccept} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Транспортная компания отправки</Form.Label>
                        <Select placeholder={this.state.data.tgive}
                                dictionary="counterparties" 
                                name="tgive"
                                onChange={(e)=>{this.tgiveChange(e)}}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Номер накладной отправки</Form.Label>
                        <Form.Control type="nember" name="numbergive" size="sm"
                            value={data.numbergive} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Описание проблемы</Form.Label>
                        <Form.Control type="text" name="pdescription" size="sm"
                            value={data.pdescription} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Описание ремонта</Form.Label>
                        <Form.Control type="text" name="fixdescription" size="sm"
                            value={data.fixdescription} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup" onSubmit={this.handleSubmit}>
                        <Form.Label>Пользователь-ремонтник:</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={data.userfix}/>
                        <select  name="userfix" value={data.userfix} onChange={this.changeValue}>
                                    {console.log(this.state)}
                                    {this.state.users.map((user, key)=>
                                    <option key={key} >{user.Name} {user.Surname}</option>)}
                                </select>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Комплектация</Form.Label>
                        <Form.Control type="text" name="complect" size="sm"
                            value={data.complect} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Вид упаковки</Form.Label>
                        <Form.Control type="text" name="package" size="sm"
                            value={data.package} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Статус</Form.Label>
                        <Select placeholder={this.state.data.status}
                               dictionary="device-fix-status" 
                               name="name"  
                               onChange={(e)=>{this.StatusChange(e)}}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup" onSubmit={this.handleSubmit}>
                        <Form.Label>Модель аппарата</Form.Label>
                        <DropdownTreeSelect
                             data={this.state.devicemodels}                             
                             onChange ={dropdown}
                             name="devmodel"
                             className="bootstrap-demo"/>    
                    </Form.Group>
                    {this.props.param.id && (<table class="table table-hover">
                        <tbody>{this.state.comdata.map((comment, key)=>
                        <tr key={key}>
                            <td>{comment.date}</td>
                            <td>{comment.comment}</td>
                            </tr>)}
                            </tbody>
                    </table>)}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.param.id?(<Button onClick={this.Save}>Сохранить</Button>):
                    (<Button onClick={this.AddData}>Добавить</Button>)}
                    <Button onClick={this.Close}>Отмена</Button>
                </Modal.Footer>
              </Form>
          </Modal>);
    };
};
class Comments extends Component{
	constructor(props)
	{
		super(props);
		this.state={...this.props.Row,
		isFocus: false};
		this.editRow = this.editRow.bind(this);

	}}


function TabStatus (){
    const [tab, setTab] = useState('tab'); 
    return (
        <Container>
            <Tabs id="controlled-tab-example" activeKey={tab} onSelect={k => setTab(k)}>
                <Tab eventKey="tab" title="Заказы">
                    <tabStatus/>
                </Tab>
            </Tabs>
        </Container>       
    );
}
export default fix;