import React, {Component} from 'react';
import api from '../Api'
import { Container, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

class Models extends Component{
    constructor(props)
    {
        super(props);
        this.state={isLoadingCategory:false,
            Categories:{}};
    
    }
    componentDidMount()
    {
        this.LoadCategories();  
    }
    LoadCategories()
    {
        this.setState({isLoadingCategory:false});
        api("GET", "warehouse", "get-category",{id_owner:1, isOpen:true})
        .then((result)=>
        { 
            this.setState({
                isLoadingCategory:true,
                Categories:result[0].children,
                })
        })
    }
    render()
    {
        const {isLoadingCategory, Categories} = this.state;
        return(<Container>
                {isLoadingCategory && <CategoriesTree Categories={Categories}/>}
        </Container>);
    }
}

const CategoriesTree = ({Categories}) => {
    return (<ul>
        {Categories.map((Category)=>
            <li key={Category.id}>
                {Category.children?
                (<div>{Category.name}<CategoriesTree Categories={Category.children}/></div>):
                (<div>{Category.name}</div>)}
                <ModelsTree category_id={Category.id} />
            </li>
            )}
    </ul>);
}

class ModelsTree extends Component{
    constructor(props)
    {
        super(props)
        this.state={models:[]}
    }
    componentDidMount()
    {
        api("GET", "warehouse", "get-details-by-category",{id_category:this.props.category_id})
        .then((result)=>
            this.setState({models:result}))
    }
    render()
    {
        const {models} = this.state;
    return (<ul  type="square">
        {models instanceof Array && models.map((model, key)=>
        <li key={key}>
            <LinkContainer to={"/model/"+model.id}><Nav.Link>{model.name}</Nav.Link></LinkContainer>
        </li>)}
    </ul>)
    }
}

export default Models;

