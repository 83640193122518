import React, {Component, useState, useEffect} from 'react';
import api from '../Api';
import mobx from 'mobx';
import { setConstantValue } from 'typescript';



function Areatext(props){
    const calcRows = (text) =>{
        let lineCount = 1;
        for(const l of text){
            if(l == "\n")
                lineCount ++;
        }
        return lineCount;
    }

    const [textareaVal, setTextareaVal] = useState(props.value);
    const [rows, setRows]               = useState(1);

    const onChangeTextarea = (e) => {
        const text = e.target.value;
        const lineCount = calcRows(text);
        if(rows !== lineCount)
            setRows(lineCount);

        setTextareaVal(text);
    };


    useEffect(() =>{
        // api("GET", "tm", "getone",{id:318}).then(
        //     res => {
        //     setTextareaVal(res[0].description);
        //     setRows(calcRows(res[0].description))
        //     }
        // )
        setTextareaVal(props.value);
        setRows(calcRows(props.value))
    }, []);


    const style = {
        width: "500px",
        height: "auto",
        'resize': "none",
    };
    
    return(
        <>
            <textarea value={props.value} rows={rows} onChange={onChangeTextarea} style={style}/>
        </>
    );
}

function Supply(){
    return(
        <>
        <div></div>
        </>
    )
}

export default Supply;