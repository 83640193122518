import React, {Component} from 'react';
import { Table, Spinner, Button, Modal, Form, NavItem, Nav } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import api from '../Api'
import * as Icon from 'react-bootstrap-icons';
import Swal from 'sweetalert2'

class PassMan extends Component{
	constructor(props)
	{
		super(props);
        this.SelectCategory = this.SelectCategory.bind(this);
        this.LoadCategories = this.LoadCategories.bind(this);
        this.LoadStrings = this.LoadStrings.bind(this);
		this.state={isLoad:false,
            messages:{},
            categories:[],
            cat_id:0,
            AddCategoryModalParam:{show:false},
            EntityModalParam:{show:false}};
    }

	LoadStrings(cat_id)
    {
        this.setState({isLoad:false});
        api("GET","passman","get-pass-by-category",{category:cat_id})
        .then((result)=>{
            this.setState({messages:result,
                isLoad:true
            });
			console.log(result);
        });
    }

    componentDidMount()
    {
        this.LoadStrings(0);
        this.LoadCategories();
    }

    LoadCategories()
  {
      this.setState({isCatLoad:false});
      api("GET", "passman", "get-categories",{})
      .then((result)=>
      { 
          this.setState(
              {categories:result}, ()=>{this.setState({isCatLoad:true})}
              )
              this.SelectCategory(this.state.cat_id);
      })
  }

  SelectCategory(id)
  {
      let categories = this.state.categories;
      function select(categories)
      {
          categories.map((category)=>
          {
              if(category.id == id)
                  category.selected = true;
              else
                  category.selected = false;
                  return 1;
          })

      }
      select(categories);
      this.setState({cat_id:Number(id),
          categories:categories});
      this.LoadStrings(id);
  }
	
	render()
	{
        const {isLoad, messages, EntityModalParam, AddCategoryModalParam, categories, isCatLoad} = this.state;
		return(<div>
        <EntityModal show={EntityModalParam.show}
            onHide={()=>{
                this.setState({EntityModalParam:{show:false}})
            }}
            LoadStrings={this.LoadStrings}
            SelectCategory={this.SelectCategory}
            param={EntityModalParam}/>
        <AddCategoryModal show={AddCategoryModalParam.show}
            onHide={()=>{
                this.setState({AddCategoryModalParam:{show:false}});
            }}
            LoadCategories={this.LoadCategories}
            SelectCategory={this.SelectCategory}
            param={AddCategoryModalParam}/>
                        {isCatLoad?(<div className="navbar navbar-expand-lg navbar-light " style={{'width':'100%'}}>
            <Icon.CardText width="24" height="24" style={{marginRight:'5px'}} /><a className="navbar-brand mb-0 h1"></a>
            <Nav id="navbarSupportedContent" className="navbar-nav mr-auto bg-dark" style={{paddingLeft:8, paddingRight:8, borderRadius:4}}>
            <strong><a className={(this.state.cat_id===0)?'nav-link text-dark bg-light':'nav-link text-light bg-dark'} href="#"
                                onClick={()=>{this.setState({cat_id:0}, ()=>{this.SelectCategory(this.state.cat_id)});
                                    }}>
								Без категории
							</a></strong>
            {categories.map((Name, key)=>{return(
                <NavItem className="nav-item" key={key}>
							  <strong><a className={Name.selected?'nav-link text-dark bg-light':'nav-link text-light bg-dark'} href="#"
                                onClick={()=>{this.setState({cat_id:Name.id}, ()=>{this.SelectCategory(this.state.cat_id)});
                                    }}>
								{Name.name}
							</a></strong></NavItem>
              )})}
              </Nav>
              <div className="form-inline my-2 my-lg-0">
						{this.state.cat_id!=0 && (<button className="btn btn-sm btn-dark mr-2"  onClick={()=>{this.setState({AddCategoryModalParam:{show:true,id:this.state.cat_id}
										})}}>Редактировать категорию</button>)}
                                        <button className="btn btn-sm btn-dark" onClick={()=>{this.setState({AddCategoryModalParam:{show:true}
										})}}>Добавить категорию</button>
				</div>
              </div>):(<div> </div>)}
             {isLoad?(<Table striped bordered hover size="sm">
             <thead>
                <AddRow LoadStrings={this.LoadStrings} cat_id={this.state.cat_id}/>
            </thead>
            <tbody>
            {messages.map((Row, key)=>{return(<tr key={key}>
                <td>
                    <a href={'http://'+Row.link} target="_blank" rel='noopener noreferrer'>{Row.link}</a>
                    </td>
                <td>

                         <CopyToClipboard text={Row.login}>
                             <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-clipboard-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                            style={{'cursor':'pointer'}}
                            onClick={()=> {
                                Swal.fire({
                                    position: 'top',
                                    icon: 'success',
                                    title: 'Скопировано в буфер обмена!',
                                    showConfirmButton: false,
                                    timer: 1000
                                  })
                      }}>
                      <path fillRule="evenodd" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                      <path fillRule="evenodd" d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3zm4.354 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    </svg> 
                         </CopyToClipboard>
                    {' ' + Row.login}
                </td>
                <td>
                         <CopyToClipboard text={Row.pass}>
                             <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-clipboard-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                            style={{'cursor':'pointer'}}
                            onClick={()=> {
                                Swal.fire({
                                    position: 'top',
                                    icon: 'success',
                                    title: 'Скопировано в буфер обмена!',
                                    showConfirmButton: false,
                                    timer: 1000
                                  })
                      }}>
                      <path fillRule="evenodd" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                      <path fillRule="evenodd" d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3zm4.354 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                         </CopyToClipboard> 
                    {' ' + Row.pass}
                </td>
				<td>{Row.mail}</td>
				<td>{Row.description}</td>
                {/* <td>{Row.category}</td> */}
                <td>
                    <Button size="sm"
                        onClick={()=>{this.setState({EntityModalParam:{show:true,
                            id:Row.id}})}}>
                        Редактировать
                    </Button>
                </td>
               </tr>)})}
               </tbody>
               </Table>):(<Spinner animation="border"/>)}
               </div>
               );
	}
}

class EntityModal extends Component
{
    data={
        id:'',
        link:'',
        login:'',
        pass:'',
        mail:'',
        description:'',
        category:''
    };

    constructor(props)
    {
        super(props)

        this.state={
            isLoading:false,
            data:this.data,
            categories:[]
        };
        this.state.data.id=this.props.param.id;
    }

    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "passman", "get-categories",{})
            .then((result)=>
            {this.setState({categories:result})});
            api("GET", "passman", "get-passman",{id:this.props.param.id})
            .then(result=>this.setState({data:{...result}, isLoading:true}))
        }
    }

    Save = () =>
    {
        api("GET", "passman", "edit",{id:this.state.data.id?this.state.data.id:0,
            ...this.state.data})
            .then(()=>{
                this.props.SelectCategory(this.state.data.category);
                this.Close();
            });
    }


    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data,  isLoading:false});
    }

    Delete = () =>
    {

		api("GET","passman","delete-row",{id:this.state.data.id})
                .then((result)=>{
                    this.props.SelectCategory(this.state.data.category);
                    this.Close()
                });
    }

    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

    render()
    {
        const {data, categories} = this.state;
        
        return(<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
              <Form>
                <Modal.Header>
                    <Modal.Title>
                        Редактировать
                      {this.props.param.id && <Button onClick={this.Delete}>Удалить</Button>}
                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Ссылка</Form.Label>
                        <Form.Control type="text" name="link" size="sm"
                            value={data.link} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Логин</Form.Label>
                        <Form.Control type="text" name="login" size="sm"
                            value={data.login} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control type="text" name="pass" size="sm"
                            value={data.pass} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>e-mail</Form.Label>
                        <Form.Control type="text" name="mail" size="sm"
                            value={data.mail} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Комментарий</Form.Label>
                        <Form.Control type="text" name="description" size="sm"
                            value={data.description} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Категория</Form.Label>
						<select className="form-control" name="category" value={data.category}
                        onChange={this.changeValue}>
                        <option value="0">Без категории</option>
						{categories.map((category, key)=>{return(
                        <option key={category.id} value={category.id}>{category.name}</option>
                        )})}
					    </select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.Save}>Сохранить</Button>
                    <Button onClick={this.Close}>Отмена</Button>
                </Modal.Footer>
              </Form>
          </Modal>);

    };
};

class AddCategoryModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={description:'', id:0, isLoading:false};
		this.Save = this.Save.bind(this);
        this.Add = this.Add.bind(this);
        this.Delete = this.Delete.bind(this);
        this.Close = this.Close.bind(this);
    }
    
    componentWillUnmount()
    {
        this.setState({description:'', id:0, isLoading:false});
    }

	componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "passman", "get-category-by-id",{id:this.props.param.id})
            .then(result=>this.setState({description:result.name, id:this.props.param.id, isLoading:true}))
        }
    }
	
    Save()
    {
        
        api("GET", "passman", "editcat",{id:this.props.param.id, name:this.state.description})
            .then(()=>{this.Close();
                this.props.LoadCategories(0);
                });
                
    }
    
    Delete()
    {
        api("GET", "passman", "delete-category",{id:this.props.param.id})
           .then(()=>{this.Close();
            this.props.LoadCategories();
            this.props.SelectCategory(0);});
           
    }
	
	Add()
    {
        api("GET", "passman", "addcat",{des:this.state.description})
            .then(()=>{this.Close();
                this.props.LoadCategories();});
    }

    Close()
    {
		this.props.onHide();
		this.setState({description:'', id:0, isLoading:false});
    }

    render()
    {
        return(
			<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          	>
              <Form>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.param.id?(<div>Редактировать категорию
                <Button onClick={(e)=>{e.preventDefault();
										Swal.fire({
											title: 'Все записи перенесутся в раздел "Без категории". Вы уверены?',
											icon: 'warning',
											confirmButtonColor: true,
											showCancelButton: true,
											confirmButtonText: 'Да',
											cancelButtonText: 'Отмена',
											confirmButtonColor: '#3085d6',
											cancelButtonColor: '#d33',
										}).then((result) => {
											if (result.isConfirmed) {
												Swal.fire({
													icon: 'success',
													title: 'Категория удалена',
													showConfirmButton: false,
													timer: 750
												})
												this.Delete()
											}
											else {
												e.preventDefault()
											}
										})}}>
                    Удалить
                </Button>
                </div>
                ):'Создать категорию'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Введите название категории</Form.Label>
                <Form.Control type="text" name="name" size="sm"
                            value={this.state.description}
                            onChange={(e)=>{this.setState({description:e.target.value})}}/>
                            </Form.Group>
            </Modal.Body>
            <Modal.Footer>
			{this.props.param.id?
				(<Button variant="primary" type="submit" onClick={(e)=>{
                    e.preventDefault();
                    this.Save();
                    }}>Сохранить</Button>):
                (<Button variant="primary" type="submit" onClick={(e)=>{
                    e.preventDefault();
                    this.Add();
                    }}>Добавить</Button>)
            }
              <Button onClick={this.Close}>Отмена</Button>
            </Modal.Footer>
            </Form>
          </Modal>  
        );
    }
}

class AddRow extends Component{
	constructor(props)
	{
		super(props);
		this.state={message:{
				id:'',
				link:'',
				login:'',
				pass:'',
				mail:'',
				description:'',
				category:this.props.cat_id
			}};
		this.SendString = this.SendString.bind(this);
		console.log(props);
	}
	
	SendString(e)
    {
		e.preventDefault();
        console.log(this.props.cat_id);
		api("GET","passman","add",{...this.state})
                .then((result)=>{
                    if(result.Result==='done')
                    {
                        this.props.LoadStrings(this.props.cat_id);
                    }
                    else
                        console.log(result);
                });
    }
	render()
	{
        this.state.category = this.props.cat_id;
		return(<tr>
                    <th><input className="form-control" name="link" size="sm" placeholder="Ссылка"
                            onChange={(e)=>{this.setState({link:e.target.value})}}/></th>
					<th><input className="form-control" name="login" size="sm" placeholder="Логин"
                            onChange={(e)=>{this.setState({login:e.target.value})}}/></th>
					<th><input className="form-control" name="pass" size="sm" placeholder="Пароль"
                            onChange={(e)=>{this.setState({pass:e.target.value})}}/></th>
					<th><input className="form-control" name="mail" size="sm" placeholder="e-mail"
                            onChange={(e)=>{this.setState({mail:e.target.value})}}/></th>
					<th><input className="form-control" name="description" size="sm" placeholder="Комментарий"
                            onChange={(e)=>{this.setState({description:e.target.value})}}/></th>
					<th><Button size="sm" type="submit" onClick={(e)=>{this.SendString(e)}}>Добавить</Button></th>
                </tr>);
	}
}

export default PassMan;