import React, {Component, useState, useRef, useEffect, createContext} from 'react';
import {Button, Tab,
    Row, Col, Container, Spinner,
    Nav, Form, InputGroup, FormControl, Modal, CloseButton } from "react-bootstrap"
import {LinkContainer} from 'react-router-bootstrap';
import Select from './../Components/SelectDictionary';
import * as Icon from 'react-bootstrap-icons';
import {BreadthFirstSearch, preorderDFS, DFS, BFS} from '../Common/common'
import api from "./../Api.js";
import _ from "lodash";

/* TODO При добавлении элемента к узлу сделать
    1. Сделать анимацию обновления данных
*/

const Store = {
    ReloadData: () => {return null},
}

const TableColors = {
    Grey: {'backgroundColor':'rgb(226, 227, 229)'},
    Blue: {'backgroundColor':'rgb(223, 239, 255)'},
    Blue2: {'backgroundColor':'rgb(191, 222, 255)'},
    Green: {'backgroundColor':'rgb(212, 237, 218)'},
    Yellow: {'backgroundColor':'rgb(255, 243, 205)'}
}

const DataColumn = (props) => {
    const [data, setData] = useState(props.data)
    
    useEffect(() => {
        setData(props.data)
    }, [props.data])

    return(
        <>
            <Col md={1} style={TableColors.Grey}>{data["Grey"]}</Col>
            <Col md={1} style={TableColors.Blue}>{data["Blue"]}</Col>
            <Col md={1} style={TableColors.Blue2}>{data["Blue2"]}</Col>
            <Col md={1} style={TableColors.Green}>{data["Green"]}</Col>
            <Col md={1} style={TableColors.Yellow}>{data["Yellow"]}</Col>
        </>
    )
}

class CounterEditor extends Component{
    constructor(props){
        super(props)

        this.state={
            val:1,
            error:false, 
            inputBorderColor: "green"
        }
        
    }

    async changeCount(x)
    {
        const {item, parent, count, annotation} = this.props;
        const val = this.state.val*x;
        
        if(item.cost + val > 0 || item.count+val>0)
        {
            if(this.state.error)
                return this.setState({error:false});
            
            let api_obj = {
                id_parent: parent.id,
                parent_type: parent.type,
                val: val
            }
            
            if (item.type === 'unit')
            {
                await api('GET', 'models_unit', 'change_count_unit', {...api_obj, id_unit: item.id})
            }
            else if (item.type === 'detail')
            {
                await api('GET', 'wh_detail', 'change_count_detail', {...api_obj, id_detail:item.id})
            }
            else if (item.type === 'operation')
            {
                await api('GET', 'operations_list', 'change_cost_detail', {...api_obj, id_operation:item.id})
            }

            return this.props.reload();
        }
        
		return this.setState({error:true});
    }

    del()
    {
        const {item, parent} = this.props;
            console.log({item:item, parent:parent})
            if(parent.type==='model')
            {
                if(item.type==='unit')
                    api('GET', 'models_models', 'del_unit',{id_model:parent.id, id_unit: item.id})
                    .then((result)=>{   
                        this.props.reload();
                    });
                else if(item.type==='detail')
                    api('GET', 'models_models', 'del_detail',{id_model:parent.id, id_detail: item.id})
                    .then((result)=>{   
                        this.props.reload();
                    });
                else if(item.type==='operation')
                     api('GET', 'models_models', 'del_operation',{id_model:parent.id, id_operation: item.id})
                     .then((result)=>{   
                         this.props.reload();
                     });
            }
            else if(parent.type==='unit')
            {
                if(item.type==='unit')
                    api('GET', 'models_unit', 'del_unit',{id_parent:parent.id, id_unit: item.id})
                    .then((result)=>{   
                        this.props.reload();
                    });
                else if(item.type==='detail')
                    api('GET', 'models_unit', 'del_detail',{id_unit:parent.id, id_detail: item.id})
                    .then((result)=>{   
                        this.props.reload();
                    });
                else if(item.type==='operation')
                     api('GET', 'models_unit', 'del_operation',{id_unit:parent.id, id_operation: item.id})
                     .then((result)=>{   
                         this.props.reload();
                     });
            }
    }
    
    
    render(){
        return(
            <div>
                <Button size="sm" onClick={()=>this.changeCount(-1)}>-</Button>
                <input type='text'
                    style = { {width: 30, height: 22, backgroundColor:this.state.error?'red':'white', borderRadius: 4, borderWidth: 1, textAlign: 'center', borderColor: this.state.inputBorderColor} }
                    onBlur = {(el) => { this.setState({ inputBorderColor: "black"}) }}
                    onFocus = {(el) =>  { this.setState({ inputBorderColor: "green"}) }}

                    onChange = {(e)=>this.setState({val:e.target.value.replace(',','.')})}
                    value = { this.state.val }
                    
                >
                </input>
                <Button size="sm" onClick={()=>{this.changeCount(1)}}>+</Button>
                <Button size="sm" variant="danger" onClick={()=>{this.del()}}>Del</Button>
            </div>
        )
    }
 }

 class AddBlock extends Component{
    constructor(props)
    {
        super(props)
        this.state={unit:{}, detail:{}, operation:{}, annotation:{}, count:1};
    }
 
    handleChange(e) {
        if(e.target.name==="count" && e.target.value < 0)
            return 0;
 
        if(e.target.type==='checkbox')
            this.setState({ [e.target.id]: e.target.checked });
        else
            this.setState({ [e.target.name]: e.target.value });
    }
 
    async AddUnit()
    {
        const {parent} = this.props
        const {unit, count} = this.state
 
        if(parent.type==='model')
            await api('GET', 'models_models', 'add_unit', {id_model:parent.id, id_unit:unit, count: count})

        else if(parent.type==='unit')
            await api('GET', 'models_unit', 'add_unit', {id_parent:parent.id, id_unit:unit, count: count})

        this.props.reload();
    }
    
    async AddDetail(){
        const {parent} = this.props
        const {detail, count} = this.state
 
        if(parent.type==='model')
            await api('GET', 'models_models', 'add_detail', {id_model:parent.id, id_detail:detail, count: count})
            
        else if(parent.type==='unit')
            await api('GET', 'models_unit', 'add_detail', {id_unit:parent.id, id_detail:detail, count: count})
           

        this.props.reload();
    }

	async AddOperation(){
        const {parent} = this.props
        const {operation, count} = this.state
    
        if(parent.type==='model')
            await api('GET', 'models_models', 'add_operation', {id_model:parent.id, id_operation:operation, cost:count})
        else if(parent.type==='unit')
            await api('GET', 'models_unit', 'add_operation', {id_unit:parent.id, id_operation:operation, cost:count})
            
        this.props.reload();
    }

    render()
    {
        return(
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={2}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                         <Nav.Link eventKey="first"><Icon.Diagram3Fill width="15" height="15"/> Узел</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                         <Nav.Link eventKey="second"><Icon.NutFill width="15" height="15"/> Деталь</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                         <Nav.Link eventKey="operation"><Icon.Tools width="15" height="15"/> Операция</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={6}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Select placeholder="Узел"
                                dictionary="models_unit" 
                                name="unit"
                                onChange={(e)=>{this.handleChange(e)}}/>
                            <Button style={{width:'100%'}} onClick={()=>{this.AddUnit()}}>
                                + Добавить
                            </Button>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <Select placeholder="Деталь"
                                dictionary="wh_detail" 
                                name="detail"
                                onChange={(e)=>{this.handleChange(e)}}/>
                            <Button style={{width:'100%'}} onClick={()=>{this.AddDetail()}}>
                                + Добавить
                            </Button>
                        </Tab.Pane>
                        <Tab.Pane eventKey="operation">
                            <Select placeholder="Операция"
                                dictionary="operations_list" 
                                name="operation"
                                onChange={(e)=>{this.handleChange(e)}}/>
                            <Button style={{width:'100%'}} onClick={()=>{this.AddOperation()}}>
                                + Добавить
                            </Button>
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                    <Col sm={4}>
                        <Form.Control type="number" name="count" step="any" min="0"
                        value={this.state.count}
                        onChange={(e)=>{this.handleChange(e)}}/>
                    </Col>
                </Row>
            </Tab.Container>
        )
    }
 }

class NormilizeView{
    // Нормирует значение до копеек
    static Rub(number){        
        if(!number)
            return ""
    
        else if(String(number).includes("."))
            return Number(number).toFixed(2)
        
        return number
    }

    // Нормирует значение до HH:MM
    static Time(minutes){
        if(!minutes)
            return "-"

        const hours = ~~( minutes / 60 )
        const min = ~~(minutes % 60)
        
        if(min < 10)
            return `${hours}:0${min}`
        else
            return `${hours}:${min}`
    }

}

/*
    

*/
 const UnitItem = ({item, parent, reload, changeOpen, link}) => {
    const count = Number(item.count)
    const work_cost = Number(count * item.work_cost)
    const details_cost = Number(count * item.detailsCost)
    const all_cost = Number(details_cost + work_cost)

    const ButtonAddBlock = () => {
        const Click = () => changeOpen(link+".isAddBlock", !item.isAddBlock)
        return(
            item.isAddBlock
                ?<Button size="sm" variant="secondary" onClick={Click}>-</Button>
                :<Button size="sm" variant="primary"   onClick={Click}>+</Button>
        )
    }

    return(
        <Container style={{paddingRight:0,  border:'1px solid #cecece'}}>
            <Row> 
                <Col md={1} onClick={()=>changeOpen(link+".isOpen", !item.isOpen)}>
                    {item.children.length>0&&
                    <div>{item.isOpen?'-':'+'}</div>}
                </Col>
                <Col md={3}><Icon.Diagram3Fill width="15" height="15" /> {item.name}</Col>
                <Col md={1}>
                    <ButtonAddBlock/>
                </Col>

                <DataColumn
                    data = {{
                        "Grey": count,
                        "Blue": NormilizeView.Rub(item.allCost.operationsCost),
                        "Blue2": NormilizeView.Time(item.allCost.operationsTime),
                        "Green": NormilizeView.Rub(item.allCost.detailsCost),
                        "Yellow": NormilizeView.Rub(0),
                    }}
                />
                
                <Col md={2}>
                    <CounterEditor item={{...item, type: item.type}} parent={parent} reload={reload}/>
                </Col>
            </Row>
            {item.isAddBlock&&
            <Row>
                <Col sm={1}/>
                <Col sm={9}>
                    <AddBlock parent={{type:'unit', id:item.id}} reload={reload}/>
                </Col>
                <Col sm={2}/>
            </Row>}
            {(item.children.length>0&&item.isOpen)?(
                <Row>
                    <Col sm={12}><List data={item.children} parent={item} reload={reload}
                                        changeOpen={changeOpen}
                                        link={link+".children"}/></Col>
                </Row>) : null}
        </Container>
    )
 }
 const DetailItem = ({item, parent, reload}) => {
	let setForm = useRef();
	
	const [visio, setVisio] = useState('none');
	const [sform, setF] = useState({annotation:'', count:1});
	const [annid, setId] = useState(null);
	
	const upDate = e => {setF({...sform, [e.target.name]:e.target.value});};
	
	const suBmit = e => {e.preventDefault();
		api('GET', 'model', 'add_annotation',{...sform, id_parent:item.id, type_parent:item.type, mode:'a'})
			.then((result) => {
				if (result.Result==='done') {
					reload();
				}
		});
	};
	
	const deLete = () => {
		api('GET', 'model', 'add_annotation', {id:annid, mode:'d'})
			.then((result) => {
				if (result.Result==='done') {
					reload();
				}
		});
	};
	 
	return (
        <Container style={{paddingRight:0,  border:'1px solid #cecece'}}>
            <Row> 
                <Col md={1}></Col>
                <Col md={3}><Icon.NutFill width="15" height="15" /> {item.name}</Col>
                <Col md={1}>
                    <Button variant="dark" size="sm" onClick={() => (visio==='none')?(setVisio('inline')):(setVisio('none'))}>
                        A
                    </Button>
                </Col>
             
                <DataColumn
                    data = {{
                        Grey: item.count,
                        Blue: '',
                        Blue2: '',
                        Green: NormilizeView.Rub(Number(item.count) * Number(item.cost)),
                        Yellow: '', 
                    }}
                />
                <Col md={2}>
                    <CounterEditor item={{...item, type: item.type}} parent={parent} reload={reload}/>
                </Col>
            </Row>
            {item.ann && item.ann.map((ann, key) =>
                <Row className="annotation" key={ann.id}>
                    <Col md={1}><Form.Check type="checkbox" onClick={() => setId(ann.id)} /></Col>
                    <Col md={4}><b>A:</b> {ann.annotation}</Col>
                    <Col md={1}>
                        <Button variant="danger" size="sm" onClick={deLete}>
                            X
                        </Button>
                    </Col>
                    <DataColumn/>
                    
                    <Col md={2}></Col>
                </Row>)}
            <Form inline style={{display:visio}}>
            <Form.Row className="align-items-center">
                <Col md={1}></Col>
                <Col md={4}><Form.Label size="sm" htmlFor="annotation" size="sm"></Form.Label>
                <InputGroup size="sm">
                    <InputGroup.Prepend>
                    <InputGroup.Text size="sm">A:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl size="sm" id="annotation"/>
                </InputGroup></Col>
                <Col md={1} style={TableColors.Grey}><Form.Control plaintext type="number" id="count" defaultValue="1" /> </Col>
                <Col md={1} style={TableColors.Blue}><Form.Control plaintext readOnly defaultValue=" " /> </Col>
                <Col md={1} style={TableColors.Blue2}><Form.Control plaintext readOnly defaultValue=" " /> </Col>
                <Col md={1} style={TableColors.Green}><Form.Control plaintext readOnly defaultValue=" " /> </Col>
                <Col md={1} style={TableColors.Yellow}><Form.Control plaintext readOnly defaultValue=" " /> </Col>
                <Col md={2}><Button variant="success" size="sm">Save</Button></Col>
            </Form.Row>
            </Form>
    </Container>
 );
 }


const ModalOperationEdit = ({show, setShow, name, defaultWorkCost, defaultTimeCost, idOperation}) => {
    const workCost = useRef(defaultWorkCost)
    const timeCost = useRef(defaultTimeCost)
    const [closing, setClosing] = useState(false)   
    
    useEffect(() => {
        console.log('rerender');
    })
    const Close = () => {
        setClosing(false)
        setShow(false)
    }
    
    const Save = async () => {
        setClosing(true)
        const data = {
            id_operation: idOperation,
            cost: workCost.current? workCost.current: 0,
            work_time: timeCost.current? timeCost.current: 0
        }
        console.log('data', data);

        await api("GET", "operations_list", "change", data)
        await Store.ReloadData()
        Close()
    }

    useEffect(() => {
        if(show)
            document.addEventListener('keydown', pressEnter)
        else
            document.removeEventListener('keydown', pressEnter)
    }, [show])

    const pressEnter = (e) => {
        if(e.code === 'Enter' || e.code === 'NumpadEnter')
            Save()
    }
    
    const Buttons = () => {
        return(
            <>
             <Button variant="secondary" tabIndex={1} onClick={Close}> Закрыть</Button>
             <Button variant="primary" onClick={Save}> Сохранить изменения</Button>
            </>
        )
    }

    
    return(
        <Modal show={show} onHide={Close} animation={false}>
                <Modal.Header>
                    <Modal.Title>{name}</Modal.Title>
                    <CloseButton tabIndex={-1}/>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Стоимость работы, руб</Form.Label>
                                <Form.Control
                                    defaultValue={defaultWorkCost}
                                    onChange={(e) => workCost.current = e.target.value}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Время работы, мин</Form.Label>
                                <Form.Control
                                    defaultValue={defaultTimeCost}
                                    onChange={(e) => timeCost.current = e.target.value}    
                                />
                            </Form.Group>
                        </Row>
                    </Form>
            
                </Modal.Body>

                <Modal.Footer>
                    {closing 
                        ?<Spinner animation="border"/>
                        :<Buttons/>
                    }
                </Modal.Footer>

            </Modal>
    )
}

 const OperationItem = ({item, parent, reload}) => {
	let setForm = useRef();
	
	const [visible, setVisible] = useState('none');
	const [sform, setF] = useState({annotation:'', count:1});
	const [annid, setId] = useState(null);

    const [showE, setShowE] = useState(false);


	const update = e => {setF({...sform, [e.target.name]:e.target.value});};
	
	const submit = e => {e.preventDefault();
		api('GET', 'model', 'add_annotation',{...sform, id_parent:item.id, type_parent:item.type, mode:'a'})
			.then((result) => {
				if (result.Result==='done') {
					reload();
				}
		});
	};
	
    // console.log(item);
	const del = async () => {
        const result = await api('GET', 'model', 'add_annotation', {id:annid, mode:'d'})
        if(result.Result === 'done')
            reload()
	};

	return (
        <>
            <ModalOperationEdit
                show={showE} setShow={setShowE} 
                defaultWorkCost={NormilizeView.Rub(item.cost)} defaultTimeCost={item.work_time}
                name={item.name}
                idOperation={item.id}
            />

            <Container style={{paddingRight:0, border:'1px solid #cecece'}}>
                <Row> 
                    <Col md={1}></Col>
                    <Col md={3}><Icon.Tools width="15" height="15" /> {item.name}</Col>
                    <Col md={1}>
                        <Button variant="dark" size="sm" onClick={() => (visible==='none')?(setVisible('inline')):(setVisible('none'))}>
                            A
                        </Button>
                        <Button variant="outline-secondary" size="sm" onClick={() => setShowE(true)}>
                            E
                        </Button>
                    </Col>

            

                    <DataColumn
                        data = {{
                            "Blue": NormilizeView.Rub(item.cost),
                            "Blue2": NormilizeView.Time(item.work_time)
                        }}>
                    </DataColumn>

                    <Col md={2}>
                        <CounterEditor item={{...item, type: item.type}} parent={parent} reload={reload}/>
                    </Col>
                </Row>

                {item.ann && item.ann.map((ann, key)=>
                <Row className="annotation" key={ann.id} onClick={() => setId(ann.id)}>
                    <Col md={1}><Form.Check type="checkbox" onClick={() => setId(ann.id)} /></Col>
                    <Col md={3}><b>A:</b> {ann.annotation}</Col>
                    <Col md={1}>
                        <Button variant="danger" size="sm" onClick={del}>
                            X
                        </Button>
                    </Col>
                    {/* <DataColumn/> */}
                    <Col md={2}></Col>
                </Row>)}

                <Form inline style={{display:visible}} onSubmit={submit}>
                <Form.Row className="align-items-center">
                    <Col md={1}></Col>
                    <Col md={3}><Form.Label size="sm" htmlFor="annotation" size="sm"></Form.Label>
                    <InputGroup size="sm">
                        <InputGroup.Prepend>
                        <InputGroup.Text size="sm">A:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl size="sm" value={sform.annotation} name="annotation" onChange={update}/>
                    </InputGroup></Col>
                    <Col md={1} style={TableColors.Grey}><Form.Control plaintext type="number" value={sform.count} name="count" onChange={update}/></Col>
                    <Col md={1} style={TableColors.Blue}><Form.Control plaintext readOnly defaultValue=" " /></Col>
                    <Col md={1} style={TableColors.Blue2}><Form.Control plaintext readOnly defaultValue=" " /></Col>
                    <Col md={1} style={TableColors.Green}><Form.Control plaintext readOnly defaultValue=" " /></Col>
                    <Col md={1} style={TableColors.Yellow}><Form.Control plaintext readOnly defaultValue=" " /></Col>
                    <Col md={2}><Button type="submit" variant="success" size="sm">Save</Button></Col>
                </Form.Row>
                </Form>
        </Container>
    </>
 );
 }

 const List = ({data, parent, reload, changeOpen, link}) => 
    data.map((item, i) => {
        if(item.type === "unit")
            return <UnitItem key={i} item={item} parent={parent} reload={reload} changeOpen={changeOpen} link={link+'['+i+']'}/>

        if(item.type === "detail")
            return <DetailItem key={i} item={item} parent={parent} reload={reload}/>

        if(item.type === "operation")
            return <OperationItem key={i} item={item} parent={parent} reload={reload}/>
    })


class Model extends Component{

    constructor(props)
    {
        super(props);
        this.state={isLoad:false, data:{}, model:{name:'', id:0}, view: {allCost: 0}};
        this.changeOpen = this.changeOpen.bind(this);
        this.ReloadData = this.ReloadData.bind(this);
        Store.ReloadData = this.ReloadData
    }



    // Закрывает все открытые узлы
    defaultView(nodes){
        function recAddisOpen(lists) {
            lists.forEach(list => {
                if (list.type === 'unit') {
                    list.isOpen = false;
                    list.isAddBlock = false;
                    recAddisOpen(list.children)
                }
            })
        }

        recAddisOpen(nodes)
    }


    calculateUnit(nodes_of_forest){
        // performance.mark('calcOptimisation-mark')

        const addAllCountsLevel = (node) => {
            let allCounts = node.parents.map(n => n.count)
            allCounts = [node.count, ...allCounts]


            for(let i = 0; i < allCounts.length - 1; i++){
                allCounts[i+1] = allCounts[i+1] * allCounts[i]
            }
            
            node.allCounts = allCounts
        }

        const calculateCostForSingleUnit = (unit) => {
            const singleUnitCost = {
                detailsCost: 0,
                operationsCost: 0,
                operationsTime: 0
            }
            
            for(const node of unit.children){
                if(node.type === 'operation'){
                    singleUnitCost.operationsCost += Number(node.cost)
                    singleUnitCost.operationsTime += Number(node.work_time)
                }
                if(node.type === 'detail'){
                    singleUnitCost.detailsCost += Number(node.count) * Number(node.cost)
                }
            }
            return singleUnitCost
        }

        const createFields = (node) => {
            node.allCost = {
                detailsCost: 0,
                operationsCost: 0,
                operationsTime: 0
            }
        }

        const aggregation = (node) => {
            if(node.type !== 'unit')
                return
            addAllCountsLevel(node)
            node.singleUnitCost = calculateCostForSingleUnit(node)
            createFields(node)
        }


        for(const node of nodes_of_forest){
            BFS.addParentsNode(node)
            DFS.preorderIterate(node, aggregation)       
        }

        const calculateAllCost = (node) => {
            if(node.type !== 'unit')
                return
                

            let root_level = node.parents.length
            const root_node = node

            BFS.iterate(node, (node) => {
                if(node.type !== 'unit')
                    return
                const current_level = node.parents.length - root_level
                const allCounts = node.allCounts[current_level]
                const singleUnitCost = node.singleUnitCost

                root_node.allCost.detailsCost += singleUnitCost.detailsCost * allCounts
                root_node.allCost.operationsCost += singleUnitCost.operationsCost * allCounts
                root_node.allCost.operationsTime += singleUnitCost.operationsTime * allCounts
                
                 
            })
        }

        for(const node of nodes_of_forest){
            BFS.iterate(node, calculateAllCost)
        }

        // performance.measure('calcOptimisation', 'calcOptimisation-mark')
        // console.log(performance.getEntriesByName('calcOptimisation'));
        
        

    }

    async componentDidMount(){
        this.setState({isLoad:false});

        let model_result = await api("GET", "warehouse", "get-detail-by-id", {id: this.props.match.params.id})
        let nodes_of_forest = await api("GET", "model", "get-tree-by-model-id", {id_model: this.props.match.params.id})
        
        this.calculateUnit(nodes_of_forest)

        this.defaultView(nodes_of_forest)

        this.setState({
            isLoad: true,
            model: model_result,
            data: nodes_of_forest,
        })

    }

    async ReloadData()
    {   

        let result = await api("GET", "model", "get-tree-by-model-id", {id_model:this.props.match.params.id})
       
        this.calculateUnit(result)

        function compareAndChange(currentTree, newTree) {
            newTree.forEach(newList => {
                let isFind = false;
                if (newList.type === 'unit') {
                    currentTree.map(currentList => {
                        if (currentList.type === 'unit') {
                            if (newList.id === currentList.id) {
                                isFind = true;
                                newList.isOpen = currentList.isOpen;
                                newList.isAddBlock = currentList.isAddBlock;
                                compareAndChange(currentList.children, newList.children)
                            }
                        }
                    })
                }
                if (!isFind) {
                    newList.isOpen = false;
                    newList.isAddBlock = false;
                }
            })
        }

        compareAndChange(this.state.data, result)
        
        this.setState({
            isLoad: true,
            data: result,
        })
        
        
    }

    
    changeOpen(link, status)
    {
        let temp_state_data = {data:this.state.data};
        _.set(temp_state_data, link, status);
        this.setState({data:temp_state_data.data});
    }


    calculateSummaryCost(roots, type){
        let cost = 0

        if(type === 'details'){
            for(const node of roots){
                if(node.type === 'detail')
                    cost += Number(node.count * node.cost)
                    
                else if(node.type === 'unit')
                    cost += Number(node.allCost.detailsCost)   
            }
        }

        else if(type === 'operationsCost'){
            for(const node of roots){
                if(node.type === 'unit')
                    cost += Number(node.allCost.operationsCost)
                
                else if(node.type === 'operation')
                    cost += Number(node.cost)
            }
            
        }

        else if(type === 'operationsTime'){
            for(const node of roots){
                if(node.type === 'unit')
                    cost += Number(node.allCost.operationsTime)

                else if(node.type === 'operation')
                    cost += Number(node.work_time)
            }
        }

        console.log(roots);
        return cost
    }

    
    render()
    {
        const {isLoad, model, data} = this.state;
        
        return(
            <Container>
                <Row>
                    <Col md={4}><h2>{model.name}</h2></Col>
                    <Col md={3}>
                        <LinkContainer to="/models">
                            <Button>Назад</Button>
                         </LinkContainer>
                     </Col>
                </Row>
                <Row>   
                    <Col>
                        {isLoad? 
                        (<div>
                            <AddBlock parent={{type:'model', id:model.id}} reload={this.ReloadData}/>
                            <Row style={{paddingLeft: "15px"}}>
                                <Col md={5}></Col>
                                <DataColumn 
                                    data = {{
                                        Grey: "Кол-во",
                                        Blue: "Работ ₽",
                                        Blue2: "Время работ чч:мм",
                                        Green: "Деталей ₽",
                                        Yellow: "Всего  ₽",
                                    }}
                                />
                                <Col md={1}></Col>
                            </Row>
                            <Row style={{paddingLeft: "15px"}}>
                                <Col md={5}></Col>
                                <DataColumn
                                    data = {{
                                        Grey: '',
                                        Blue: NormilizeView.Rub(this.calculateSummaryCost(data, 'operationsCost')),
                                        Blue2: NormilizeView.Time(this.calculateSummaryCost(data, 'operationsTime')),
                                        Green: NormilizeView.Rub(this.calculateSummaryCost(data, 'details')),
                                        Yellow: NormilizeView.Rub(this.calculateSummaryCost(data, 'details') + this.calculateSummaryCost(data, 'operationsCost')),
                                    }}
                                />
                                <Col md={1}></Col>
                            </Row>

                            <List data={data}
                                    parent={{type:"model",id:model.id}} 
                                    reload={this.ReloadData}
                                    changeOpen={this.changeOpen} 
                                    link="data" ref='list'
                            />
                        </div>):(
                        <Spinner animation="grow" />)}
                    </Col>
                </Row>
            </Container>)
    }
 }

export default Model;

