import React, { Component } from 'react';
import {Container, Row, Col, Form, Button, Alert} from "react-bootstrap";
import {Login} from './Store';
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom';
const AuthPage = observer(
class AuthPage extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            login:'',
            pass:'',
            saved:true,
            error:''};
        
        this.ChangeValue = this.ChangeValue.bind(this);
    }

    ChangeValue(e)
    {
        if(e.target.type==="checkbox")
            this.setState({[e.target.name]:e.target.checked});
        else
            this.setState({[e.target.name]:e.target.value});
    }

    render(){
        const {login, pass, saved, error} = this.state;
        return (<Container>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <Form>
                            <Form.Group>
                                <Form.Label>Логин</Form.Label>
                                <Form.Control
                                 type="text" name="login"
                                 placeholder="Введите логин"
                                 onChange={(e)=>this.ChangeValue(e)}
                                 value={login} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Пароль</Form.Label>
                                <Form.Control
                                 type="password" name="pass"
                                 placeholder="Пароль"
                                 onChange={(e)=>this.ChangeValue(e)}
                                 value={pass} />
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" name="saved"
                                 label="Запомнить"
                                 onChange={(e)=>this.ChangeValue(e)}
                                 checked={saved} />
                            </Form.Group>
                            {error&&(<Alert variant="danger">{error}</Alert>)}
                            <Container>
                                <Row className="justify-content-md-center">
                                    <Col>
                                        <Button variant="primary" type="submit"
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            Login(login,pass,saved)
                                            .catch((error)=>this.setState({error:error}));
                                            }}>
                                            Войти
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Link to="/restore" className="btn btn-primary">Восстановить</Link>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col>
                                        <Link to="/reg" className="btn btn-primary" style={{marginTop: .5 + 'em'}}>Регистрация</Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    </Col>
                </Row>
        </Container>);
    }
});

export default AuthPage;