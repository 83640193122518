import axios from "axios";
//import { observer } from 'mobx-react'
import store from './Store';

const api_address=process.env.REACT_APP_API_HOST;
/*if(process.env.NODE_ENV!=='development' )
        api_address="http://erp-back-yii.ru/web/";
    else
        api_address="https://bitrix.aria-invertor.ru/api/v1/web/";
*/
//   console.log(api_address);
//   console.log(process.env);


function api(method, endpoint, func, params)
{

    var headers = {'AuthorizationToken': store.User.token?store.User.token:null, 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'};

    switch(method)
    {
        case 'GET':
            return new Promise(function(resolve, reject)
            {
                axios.get(api_address+endpoint+'/'+func,{params:params},
                    {useCredentails: true , mode:'cors',headers: headers})
                    .then(response=>{

                        if(!response.data.error)
                            resolve(response.data); //Данные от API получены без ошибок
                        else
                            reject(response.data.error); //Ошибка запроса к API
                    })
                    .catch(error=>{
                        console.log('System error '+error); //Системаня ошибка
                    });
            });

        case 'POST':
                // return new Promise((resolve, reject) => {
                //     axios.post(api_address+endpoint+'/'+func, requestBody, {params:params},
                //     {useCredentails: true , mode:'cors', headers: headers})
                //     .then(response=>{

                //         if(!response.data.error)
                //             resolve(response.data); //Данные от API получены без ошибок
                //         else
                //             reject(response.data.error); //Ошибка запроса к API
                //     })
                //     .catch(error=>{
                //         console.log('System error '+error); //Системаня ошибка
                //     });
                // })
                
        case 'PATCH':
        case 'DELETE':
            return new Promise(function(resolve, reject)
            {
                axios.post(api_address+endpoint+'/'+func,params,
                    {useCredentails: true , mode:'cors',headers: headers})
                    .then(response=>{

                        if(!response.data.error)
                            resolve(response.data); //Данные от API получены без ошибок
                        else
                            reject(response.data.error); //Ошибка запроса к API
                    })
                    .catch(error=>{
                        console.log('System error '+error); //Системаня ошибка
                    });
            });

        default:
            console.log('error method '+method);

    }
};




export default api;

