import React, {Component, useState} from 'react';
import { Container, Tab, Tabs, Table, Spinner, Button, Modal, Form } from 'react-bootstrap';
import api from '../Api';
import Select from '../Components/SelectDictionary';
import store from '../Store';

class tab extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {isLoad:false,
        Entities:{},
        EntityModalParam:{show:false}};
        
    }
    LoadData = () =>{
        this.setState({isLoad:false});
        api("GET", "tab-status", "get-tab-status")
        .then(Result=>{this.setState({Entities:Result, isLoad:true})});
        console.log(this.state.Entities);
    }
    componentDidMount()
    {
        this.LoadData();
    }
    

    render()
    {
        //const [EntityModalParam, setEntityModalParam] = React.useState({show:false});
        const {isLoad, Entities, EntityModalParam} = this.state;
        console.log(this.state.Entities);
        return(<div>
            <EntityModal show={EntityModalParam.show}
                onHide={()=>{
                    this.setState({EntityModalParam:{show:false}})
                }}
                LoadData={this.LoadData}
                    param={EntityModalParam}
            />
            {isLoad?(<Table striped btabed hover size="sm">
            <thead>
                <tr>
                    <th>Kонтрагент</th>
                    <th>Сумма</th>
                    <th>Комментарий</th>
                    <th>Статус</th>
                    <th>Доплата</th>
                    <th style={{width:'30px'}}>
                        <Button size="sm"
                        onClick={()=>{this.setState({EntityModalParam:{show:true}})}}>
                            Добавить
                        </Button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {Entities.map((Row, key)=>{return(<tr key={key}>
                <td>{Row.conterparty}</td>
                <td>{Row.summ}</td>
                <td>{Row.description}</td>
                <td>{Row.status}</td>
                <td>{Row.doplata}</td>
                <td>
                    <Button size="sm"
                        onClick={()=>{this.setState({EntityModalParam:{show:true,
                            id:Row.id}})}}>
                        Редактировать
                    </Button>
                </td>
                </tr>)})}
            </tbody>
        </Table>):(<Spinner animation="border"/>)}</div>);
    }
};

class EntityModal extends Component
{
    data={
        id:0,
        conterparty:'',
        summ:0,
        description:'',
        status:'',
        doplata:0};
    
    
    constructor(props)
    {
        super(props)

        this.state={
            isLoading:false,
            data:this.data,
            comdata:[],
        };
        this.state.data.id=this.props.param.id;
        this.AddData = this.AddData.bind(this);
        this.AddStatus = this.AddStatus.bind(this);
    }

    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "tab-status", "get-tab-status-by-id",{id:this.props.param.id})
            .then(result=>this.setState({data:result, isLoading:true}))
            .then(() =>{
                            api("GET", "tab-status", "get-comments",{idp:this.props.param.id})
                            .then(result=>{this.setState({comdata:result, isLoading:true}); 
                            console.log(this.state.comdata)})
                        });
        }
        
    }
    Save = () =>
{
                api("GET", "tab-status", "set-tab-status-data",
                {id:this.data.id , data:this.state.data, token:store.User.token})
        .then(()=>{
                    this.props.LoadData();
                    this.Close();

})
}
    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data, isLoading:false});
    }

    Delete = () =>
    {
        api("GET", "tab-status", "delete-tab-status",{id:this.state.data.id})
           .then(()=>{
            this.props.LoadData();   
            this.Close()
        });   

        

    }

    AddData()
	{
        console.log(this.state)
		api("GET","tab-status","add-tab-status-data",{id:this.state.data.id?this.state.data.id:0,
            data:this.state.data, token:store.User.token})
        .then(()=>{
            this.props.LoadData();   
            this.Close()
        
        });
}
AddStatus()
	{
        console.log(this.state)
		api("GET","status","add-status",{status:this.state.data.status})
        .then(()=>{
            this.props.LoadData();   
            this.Close()
        });
}

AddCommentar(e)
	{
		e.preventDefault();
		this.setState({isComLoad:false});
		api("GET","tab-status","addcom",{idp:this.props.param.id,comment:this.state.commentar})
				.then((result)=>{
                    if(result.Result==='done')
                    {
						this.LoadComments();
						this.setState({commentar:''});
                    }
                    else
                        console.log(result);                        
                })
	}
	
	

AddCounterparty()
        {
            const {parent} = this.props
            const {count, status} = this.state
     
            if(parent.type==='model')
            {
                api('GET', 'tab-status', 'add',{id: parent.id, count: count, status: status})
                .then((result)=>{   
                    this.props.reload();
                });
            }
            else if(parent.type==='unit')
            {
                api('GET', 'tab-status', 'add',{id: parent.id, count: count, status: status})
                .then((result)=>{   
                    this.props.reload();
                });
            }
        }


conterpartyChange(e) {
            this.setState({data:{...this.state.data, conterparty:e.target.label}});
            }
        
StatusChange(e) {
    this.setState({data:{...this.state.data, status:e.target.label}});
    }

    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

    

    render()
    {
        
        const {data, comments, isComLoad, categories} = this.state;
        return(
        <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered    
          >
              <Form>
                <Modal.Header>
                    <Modal.Title>
                      {this.props.param.id?'Редактировать заказ':'Добавить заказ'}
                      {this.props.param.id && <Button onClick={this.Delete}>Удалить</Button>}
                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Kонтрагента</Form.Label>
                        <Select placeholder={this.state.data.conterparty}
                                dictionary="counterparties" 
                                name="name"
                                onChange={(e)=>{this.conterpartyChange(e)}}/> 
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Сумма</Form.Label>
                        <Form.Control type="nember" name="summ" size="sm"
                            value={data.summ} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Описание</Form.Label>
                        <Form.Control type="text" name="description" size="sm"
                            value={data.description} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Статус</Form.Label>
                        <Select placeholder={this.state.data.status}
                               dictionary="status" 
                               name="name"  
                               onChange={(e)=>{this.StatusChange(e)}}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Доплата</Form.Label>
                        <Form.Control type="nember" name="doplata" size="sm"
                            value={data.doplata} onChange={this.changeValue}/>
                    </Form.Group>
                    {this.props.param.id &&(<table class="table table-hover">
                        <tbody>{this.state.comdata.map((comment, key)=>
                        <tr key={key}>
                            <td>{comment.date}</td>
                            <td>{comment.comment}</td>
                            </tr>)}
                            </tbody>
                    </table>)}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.param.id?(<Button onClick={this.Save}>Сохранить</Button>):
                    (<Button onClick={this.AddData}>Добавить</Button>)}
                    <Button onClick={this.Close}>Отмена</Button>
                </Modal.Footer>
              </Form>
          </Modal>
          );
    };
};
class Comments extends Component{
	constructor(props)
	{
		super(props);
		this.state={...this.props.Row,
		isFocus: false};
		this.editRow = this.editRow.bind(this);

	}}


function TabStatus (){
    const [tab, setTab] = useState('tab'); 
    return (
        <Container>
            <Tabs id="controlled-tab-example" activeKey={tab} onSelect={k => setTab(k)}>
                <Tab eventKey="tab" title="Заказы">
                    <tabStatus/>
                </Tab>
            </Tabs>
        </Container>       
    );
}
export default tab;