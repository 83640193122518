import React, {Component} from 'react';
import { Modal,  Form, Row, Container,Table, Spinner, Button} from 'react-bootstrap';
import api from '../Api'
import {Link} from "react-router-dom";
import store from '../Store';
import * as Icon from 'react-bootstrap-icons';


class Profile extends React.Component {
    
    constructor(props)
    {
        super(props);
        this.state = {isLoad:false,
        Entities:{},
        };
        this.state={
        title:'Без заголовка',
        Profile:[],
        Users:[],
        Deps:[],
        DepsUsers:[],
        isCatLoad:false,
        isUsersLoad: false,
        EntityModalParam:{show:false}
        };

    }
    LoadData = () =>{
        this.setState({isCatLoad:false});
      api("GET", "user", "get-by-token",{token:store.User.token})
      .then((result)=>
      { 
          this.setState(
              {Profile:result}, ()=>{this.setState({isCatLoad:true, isLoad:true})}
              )})
              .then(()=>{
        this.setState({isLoad:false});
        api("GET", "profile", "get-profile")})
        .then(Result=>{this.setState({Entities:Result, isLoad:true})});
    }

    LoadUsers = () =>{
        this.setState({isUsersLoad:false});
      api("GET", "profile", "get-users")
      .then((result)=>
      { 
          this.setState(
              {Users:result}, ()=>{this.setState({isUsersLoad:true})}
              )})
    }

    componentDidMount()
    {
        api("GET","profile","get-dep")
        .then((result)=>
        this.setState({Deps:result}, ()=>{console.log(this.state.Deps)}));
        api("GET","profile","get-dep-users")
        .then((result)=>
        this.setState({DepsUsers:result}, ()=>{console.log(this.state.DepsUsers)}));
        this.LoadData();
        this.LoadUsers();
    }

    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

  render()
  {
    const {Profile, isLoad,EntityModalParam, Users, Deps, DepsUsers} = this.state;
    console.log(DepsUsers);
      return(
          <div>
               {(Profile.ID===23||Profile.ID===41)&&<div className="container">
					<Container>
                    <Table bordered hover size="sm">
				    <thead>
                        <tr>
							<th>ФИО</th>
							<th>Статус</th>
							<th>Отдел</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Users.map((row, key)=>(
                    <tr key={row.ID}>
						<td>{row.Name} {row.Patronymic} {row.Surname}</td>
						<td><select className="form-control" name="status" value={row.status}
                        onChange={(e)=>{api("GET", "profile", "edit-status", {id:row.ID, status:e.target.value})
                        .then((result)=>{if(result.Result==='done'){this.LoadUsers()}})}}>
								<option value="1">Не подтвержден</option>
								<option value="2">Активен</option>
								<option value="3">Заблокирован</option>
							</select></td>
						<td>
                        <select className="form-control" name="Departament" value={0}
                                    onChange={(e)=>{api("GET", "profile", "add-dep-to-id", {id:row.ID, depa:e.target.value})
                                    .then((result)=>{if(result.Result==='done'){this.LoadUsers()}
                                    })}}>
                                        <option value="0">Назначить отдел</option>)
                                               {Deps.map((dep, key) =>
                                               <option key={key+1} value={key+1}>{dep}</option>)}
                                           </select>
                            {row.Departament.map((depa) =>
                                <select className="form-control" name="Departament" value={
                                    row.Departament.length==0?(0):(depa.id_dep)}
                                    onChange={(e)=>{api("GET", "profile", "edit-dep", {id:row.ID, dep_new:e.target.value, dep:depa.id_dep})
                                    .then((result)=>{if(result.Result==='done'){this.LoadUsers()}})}}>
                                        <option value="0">Не назначен</option>)
                                               {Deps.map((dep, key) =>
                                               <option key={key+1} value={key+1}>{dep}</option>)}
                                           </select>
                                           
                            )}
                        </td>
                    </tr>))}
                    </tbody>
                    </Table>
                    </Container>
				</div>}
      <Container>
           <EntityModal show={EntityModalParam.show}
                onHide={()=>{
                    this.setState({EntityModalParam:{show:false}})
                }}
                LoadData={this.LoadData}
                id={Profile.ID}
                param={EntityModalParam}
            />
        <Button style={{float: 'right'}} size="sm"
      onClick={()=>{this.setState({EntityModalParam:{show:true}})}}>
      Редактировать
  </Button> 
  {isLoad?(<Table striped btabed hover size="sm">
  <Row className="justify-content-md-center">
        <Form> 
         <td><Form.Group>
         <Form.Label>Логин:</Form.Label>
         <Form.Control plaintext readOnly Value={Profile.login} />
         </Form.Group>
        
         </td>
         <Form.Group>
         <Form.Label>Почта:</Form.Label>
         <Form.Control plaintext readOnly defaultValue={Profile.EMail}></Form.Control>
         </Form.Group>

         <Form.Group>
         <Form.Label>Имя:</Form.Label>
         <Form.Control plaintext readOnly defaultValue={Profile.Name}></Form.Control>
         </Form.Group>

         <Form.Group>
         <Form.Label>Отчество:</Form.Label>
         <Form.Control plaintext readOnly defaultValue={Profile.Patronymic}></Form.Control>
         </Form.Group>

         <Form.Group>
         <Form.Label>Фамилия:</Form.Label>
         <Form.Control plaintext readOnly defaultValue={Profile.Surname}></Form.Control>
         </Form.Group>

         <Form.Group>
         <Form.Label>Телефон:</Form.Label>
         <Form.Control plaintext readOnly defaultValue={Profile.Personal_Phone}></Form.Control>
         </Form.Group>

         <Form.Group>
         <Form.Label>Дата Рождения:</Form.Label>
         <Form.Control plaintext readOnly defaultValue={Profile.Birthday}></Form.Control>
         </Form.Group>
         </Form>
         </Row> 
         </Table>):(<Spinner animation="border"/>)}
         </Container>
         </div>
         )
}
}
class EntityModal extends Component
{
    data={
      id:this.props.param.id,
      login:'',
      pass:'',
      Email:'',
      Name:'',
      Surename:'',
      Patronymic:'',
      Personal_Phone:'',
      Birthday:'',
      status :0,
      

    }
    constructor(props)  
    {
        super(props)

        this.state={
            isLoading:false,
            data:this.data,
            
        };
        this.state.data.id=this.props.param.id;
        this.AddData = this.AddData.bind(this);
    }

    componentDidUpdate()
    {
        
        if(this.props.id && this.state.isLoading===false)
        {
            api("GET", "profile", "get-profile-by-id",{ID:this.props.id})
            .then(result=>this.setState({data:{...result}, isLoading:true,},))
            
        }
        
    }
    Save = () =>
    {   
        api("GET", "profile", "set-profile-data",{data:this.state.data})
            .then(()=>{
                this.props.LoadData();
                this.Close();
                });
    }


    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data, isLoading:false});
    }

    AddData()
	{
       
		api("GET","profile","add-profile-data",{id:this.state.data.id?this.state.data.id:0,
            data:this.state.data, token:store.User.token})
        .then(()=>{
            this.props.LoadData();   
            this.Close()
        
        });
}

    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});
  
    render()
    {
        const {data} = this.state;
        
        return(<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
              <Form>
                <Modal.Header>
                    <Modal.Title>
                      {this.props.param.id && 'Редактирование профиля'}
                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>                              
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Логин</Form.Label>
                        <Form.Control type="text" name="login" size="sm"
                            value={data.login} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Почта</Form.Label>
                        <Form.Control type="text" name="EMail" size="sm"
                            value={data.EMail} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control type="text" name="Name" size="sm"
                            value={data.Name} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Отчество</Form.Label>
                        <Form.Control type="text" name="Patronymic" size="sm"
                            value={data.Patronymic} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Фамилия</Form.Label>
                        <Form.Control type="text" name="Surname" size="sm"
                            value={data.Surname} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Телефон</Form.Label>
                        <Form.Control type="text" name="Personal_Phone" size="sm"
                            value={data.Personal_Phone} onChange={this.changeValue}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.Save}>Сохранить</Button>
                    <Button onClick={this.Close}>Отмена</Button>
                    </Modal.Footer>
                </Form>
                </Modal>)
  }
}
export default Profile;