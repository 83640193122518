import React, {Component, useState, useRef, useEffect, useImperativeHandle} from 'react';
import { Container, Spinner, Row, Col, Button, Modal, Table, Form } from 'react-bootstrap';
import Select from "../Components/SelectDictionary"
import { StringMethod } from '../Common/common.js'
import api from '../Api'
import axios from 'axios'
import store from '../Store';

/*  TODO Когда нажимается раздел дерева, должен заново срабатывать фильтр
    TODO Добавить дерево операций
    TODO Добавить анимацию когда срабатывает фильтр
    

*/


class SearchRange{
    constructor(obj){
        this.name = obj.name
        this.min = Number(obj.min)
        this.max = Number(obj.max)
    }

    find(obj){
        const num = Number(obj[this.name])
        return num >= this.min && num <= this.max
    }
}



const FiltrateData = (data, ...filters) => {
    let searchRanges = []
    let names = []
    for(const filter of filters){
        if(filter instanceof SearchRange)
            searchRanges.push(filter)
        else if(typeof(filter) === "string")
            names.push(filter)
    }

    let filtratedData = [...data]

    for(const SearchRangeFilter of searchRanges){
        filtratedData = filtratedData.filter(obj => SearchRangeFilter.find(obj))
    }

    for(const name of names){
        filtratedData = filtratedData.filter(obj => StringMethod.containWord(name, obj.name))
    }

    return filtratedData
}


const FilterUI = ({readState, toggleReset}) => {
    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "7px",
        backgroundColor: "#fff",
        padding: "10px 5px 5px 5px",
        border: "1px solid #dee2e6",
    }
    
    const filterSelectConfig = [["id", "id"], ["price", "Стоимость"], ["count", "Кол-во"]]

    const default_config = {
        stateFilter_0: { min: -Infinity, max: Infinity, name: "id"},
        stateFilter_1: { min: -Infinity, max: Infinity, name: "price"},
        stateFilter_2: { min: -Infinity, max: Infinity, name: "count"},
        stateFilterName: ""
    }

    const inputNameRef = useRef("")
    const [clearInput, setClearInput] = useState(false)
    const setDefault = () => {
        setStateFilter_0(default_config.stateFilter_0)
        setStateFilter_1(default_config.stateFilter_1)
        setStateFilter_2(default_config.stateFilter_2)
        setStateFilterName(default_config.stateFilterName)
        setClearInput(!clearInput)
        inputNameRef.current.value = ""
    }

    const [stateFilter_0, setStateFilter_0] = useState(default_config.stateFilter_0)
    const [stateFilter_1, setStateFilter_1] = useState(default_config.stateFilter_1)
    const [stateFilter_2, setStateFilter_2] = useState(default_config.stateFilter_2)
    const [stateFilterName, setStateFilterName] = useState("")

    useEffect(() => {
        setDefault()
    }, [toggleReset])

    const generatorReceiveSelectFilterStates = (stateFilter, setStateFilterCallBack) => {
        return ((val, type) => {
            if(type === "min"){
                setStateFilterCallBack({
                    min: val,
                    max: stateFilter.max,
                    name: stateFilter.name
                })
            }
            else if(type === "max"){
                setStateFilterCallBack({
                    min: stateFilter.min,
                    max: val,
                    name: stateFilter.name
                })
            }
            else if(type === "name"){
                setStateFilterCallBack({
                    min: -Infinity,
                    max: Infinity,
                    name: val
                })
            }
        })
    }

    const applyFilters = [
        generatorReceiveSelectFilterStates(stateFilter_0, setStateFilter_0),
        generatorReceiveSelectFilterStates(stateFilter_1, setStateFilter_1),
        generatorReceiveSelectFilterStates(stateFilter_2, setStateFilter_2),
    ]

    useEffect(() => {
        const pass_data = {
            stateFilter_0: stateFilter_0,
            stateFilter_1: stateFilter_1,
            stateFilter_2: stateFilter_2,
            findByName: stateFilterName.toString()
        }
        readState(pass_data)
    
    }, [stateFilter_0, stateFilter_1, stateFilter_2, stateFilterName])

    
    return(
        <>
            <div style={containerStyle}>
                
                <div style={{"display": "flex", "justify-content": "space-around"}}>
                    <Form>
                        <Form.Control size="lg" placeholder="Поиск" ref={inputNameRef} onChange={e => setStateFilterName(e.target.value)}/>
                    </Form>
                    <Button onClick={setDefault} style={{"wdith": "20%"}} size="sm" variant="dark">Сброс</Button>
                </div>

                <FilterSelect readState={applyFilters[0]} filterSelectOptions={filterSelectConfig} selected={"id"}    clear={clearInput}></FilterSelect>
                <FilterSelect readState={applyFilters[1]} filterSelectOptions={filterSelectConfig} selected={"price"} clear={clearInput}></FilterSelect>
                <FilterSelect readState={applyFilters[2]} filterSelectOptions={filterSelectConfig} selected={"count"} clear={clearInput}></FilterSelect>
            </div>
        </>
    )
}


const FilterSelect =({readState, filterSelectOptions, selected, clear}) => {
    const containerStyle = {
        display: "flex",
    }

    const selectStyle = {
        color: "#495057",
        backgroundColor: "#fff",    
        backgroundClip: "padding-box",
        border: "1px solid #ced4da",
        borderRadius: "0.25rem",
        width: "100%"
    }   

    const minRef = useRef("")
    const maxRef = useRef("")
    useEffect(() => {
        // Когда меняется пропс clear, очищает поле От и До
        minRef.current.value = ""
        maxRef.current.value = ""
    }, [clear])

    const selectHandler = (e) => {
        minRef.current.value = ""       // Обнуление поля "От"
        maxRef.current.value = ""       // Обнуление поля "До"
        
        readState(e.target.value, "name")
    }

    return(
        <div style={containerStyle}>
            <select style={selectStyle} onChange = {selectHandler}>
                {filterSelectOptions
                    ? filterSelectOptions.map(option =>
                        <option selected={option[0] === selected} value={option[0]}>{option[1]}</option>
                    ) 
                    :   <></>
                }
            </select>
            <Form.Control placeholder="От" style={{width: "35%"}} onChange={e => readState(e.target.value, "min")} ref={minRef}/>
            <Form.Control placeholder="До" style={{width: "35%"}} onChange={e => readState(e.target.value, "max")} ref={maxRef}/>
        </div>
    )
}


class Warehouse extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            isLoadingCategory: false,
            isLoadingDetails: false,
            SelectedCategoryId: 0,
            Categories: [],
            Details: [],
            viewDetails: [],
            filterReset: false,
            tableType: ""

        };
        
    }

    componentDidMount()
    {
        this.LoadCategories();  
    }

    resetFilter = () => {
        this.setState({ filterReset: !this.state.filterReset })
    }

    LoadCategories = async() =>
    {   
        this.setState({isLoadingCategory:false});
        const result = await api("GET", "warehouse", "get-category", {id_owner:0, isOpen:true})
        console.log(result);
        this.setState({
            isLoadingCategory:true,
            Categories:result,
        })

        this.SelectCategory(0);
        
    }

    LoadData = async(id_category) => 
    {
        this.setState({isLoadingDetails:false});
        const result = await api("GET", "warehouse", "get-details-by-category", {id_category:id_category})
        
        this.setState({viewDetails: result})
    

        this.setState({
            isLoadingDetails:true,
            Details:result,
        })
    }

    ReadFiltersStates = (filtersStates) => {
        const filtratedData = FiltrateData(
            this.state.Details,
            new SearchRange(filtersStates.stateFilter_0),
            new SearchRange(filtersStates.stateFilter_1),
            new SearchRange(filtersStates.stateFilter_2),
            filtersStates.findByName
        )
        
        this.setState({ viewDetails: filtratedData })
       
    }

    SelectCategory = (id) => 
    {   
        let Categories = this.state.Categories;
        function select(categories)
        {
            categories.map((category)=>
            {
                if(category.id === id)
                    category.selected = true;
                else
                    category.selected = false;

                if(category.children)
                    select(category.children);
                
                    return 1;
            })
        }
        select(Categories);
        this.setState({ SelectedCategoryId:id, Categories:Categories });
        this.LoadData(id);
        this.resetFilter()
    }

    render()
    {
        
        const {Categories, Details, SelectedCategoryId,
             isLoadingCategory, isLoadingDetails, viewDetails} = this.state;

        return(<Container>
                <Row>
                    <Col lg={3}>
                        {isLoadingCategory?(
                        <CategoriesTree 
                            Categories={Categories}
                            SelectCategory={(id)=>{this.SelectCategory(id)}}
                            ReloadDetails={()=>{this.LoadData(SelectedCategoryId)}}
                            ReloadCategories={()=>{this.LoadCategories()}}
                            />
                        ):(<Spinner animation="border" />)}

                    <FilterUI readState={this.ReadFiltersStates} toggleReset={this.state.filterReset}/>
                    </Col>

                    <Col lg={9}>
                        {isLoadingDetails?
                        (<DetailsTable Details={viewDetails}
                            ReloadDetails={()=>this.LoadData(SelectedCategoryId)}
                            IdCategory={SelectedCategoryId}/>
                        )
                        :(<Spinner animation="border" />)}
                    </Col>
                </Row>
        </Container>)
    }
};

const CategoriesTree = ({Categories, SelectCategory, ReloadDetails, ReloadCategories}) =>
{
    const [CategoryModalParam, setCategoryModalParam] = React.useState({show:false});
    return (
        <div>
            <AddCategoryModal
             show={CategoryModalParam.show}
             onHide={() => {ReloadCategories();
                setCategoryModalParam({show:false})}}
             param={CategoryModalParam}/>

            <Tree Categories={Categories}
             onSelect={(id)=>{SelectCategory(id)}}
             ReloadDetails={ReloadDetails}
             EditCategory={(param)=>{setCategoryModalParam({show:true, ...param})}}/>
        </div>
    );
}

const Tree = ({Categories, onSelect, ReloadDetails, EditCategory}) =>
{
    return(
        <div >
        <ul className="treeCSS">
            {
                Categories.map((category, index)=>{
                    return (<li key={index} 
                    style={{cursor:'pointer'}}>
                        <div
                        style={{color:category.selected?'red':'blue'}}
                        onClick={()=>{onSelect(category.id)}}
                        onDragOver={(e)=>{e.preventDefault()}}
                        onDrop={(e)=>{
                            api("GET", "warehouse", "set-category-for-detail",
                            {id_detail:e.dataTransfer.getData('id_detail'),
                            id_category:category.id})
                            .then(()=>{ReloadDetails()})
                           }}>
                            {category.name}
                            {category.id>=1 &&(<Button size="sm" variant="outline-secondary"
                             onClick={()=>{EditCategory({id:category.id, id_owner:category.id})}}>
                                 e
                            </Button>)}
                            <Button size="sm" variant="outline-secondary"
                             onClick={()=>{EditCategory({id_owner:category.id})}}>
                                 +
                            </Button>

                        </div>
                    {category.children.length>=1 &&(<div>{category.id>0 &&(<div className='drop'
                    onClick={function(e)
                        {
                            e.target.textContent = (e.target.textContent === '+' ? '−' : '+');
                            e.target.className = (e.target.className === 'drop' ? 'drop dropM' : 'drop');
                          }
                    }>+</div>)}<Tree Categories={category.children} ReloadDetails={ReloadDetails}
                    onSelect={(id)=>{onSelect(id)}}
                    EditCategory={(param)=>{EditCategory(param)}}/></div>)}
                    </li>);
                })
            }
        </ul>
        </div>
    );
};

class AddCategoryModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={name:'', id:0, isLoading:false};
        this.Save = this.Save.bind(this);
        this.Close = this.Close.bind(this);
        this.Delete = this.Delete.bind(this);
    }
    componentWillUnmount()
    {
        this.setState({name:'', id:0,  isLoading:false});
    }
    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "warehouse", "get-category-by-id",{id:this.props.param.id})
            .then(result=>this.setState({name:result.name, id:this.props.param.id, isLoading:true}))
        }
    }
    Save()
    {
        api("GET", "warehouse", "set-category-data",{id:this.state.id, id_owner:this.props.param.id_owner,
             name:this.state.name})
            .then(()=>{this.Close()});
    }

    Close()
    {
        this.props.onHide();
        
    }
    Delete()
    {
        api("GET", "warehouse", "delete-category",{id:this.state.id})
           .then(()=>{this.Close()});
    }
    render()
    {
        return(
            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
              <Form>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.param.id?'Редактировать категорию':'Создать категорию'}
                <Button onClick={this.Delete}>
                    Удалить
                </Button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Введите название категории</Form.Label>
                <Form.Control type="text" name="name" size="sm"
                            value={this.state.name}
                            onChange={(e)=>{this.setState({name:e.target.value})}}/>
                            </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.Save}>Сохранить</Button>
              <Button onClick={this.Close}>Отмена</Button>
            </Modal.Footer>
            </Form>
          </Modal>  
        );
    }
}

const OperationsTable = () => {

    return(
        <>
        <thead>
            <tr>
                <th>id</th>
                <th>Наименование</th>
                <th>Стоимость</th>
                <th>Время операций</th>
                <th colSpan={2} >Кол-во</th>
            </tr>
        </thead>
        </>
    )    
}

const DetailsTable = ({Details, ReloadDetails, IdCategory}) =>
{
    const [name, setName] = useState('');
    const [count, setCount] = useState('');
    const [price, setPrice] = useState('');
    const [DetailModalParam, setDetailModalParam] = useState({show:false});
    const [showCounterpartiesModal, setShowCounterpartiesModal] = useState(false);   
    const [detailInfoCounterpartiesModal, setDetailInfoCounterpartiesModal] = useState([undefined, ""]);

    
    const addDetail = (e) =>{
        e.preventDefault();
        api("GET", "warehouse", "add-detail",{id_category:IdCategory, name:name, count:count, price:price})
        .then(()=>{
            ReloadDetails();
        })
    }
    return(
        <div>
           <DetailModal
                show={DetailModalParam.show}
                onHide={() => {ReloadDetails(IdCategory); setDetailModalParam({show:false})}}
                param={DetailModalParam}
             />
            <CounterpartiesModal 
                show = {showCounterpartiesModal}
                handleClose = {() => { setShowCounterpartiesModal(false) }}
                detailInfo = { detailInfoCounterpartiesModal }
            />

    <Table striped bordered hover size="sm">
        <thead>
            <tr>
                <th>id</th>
                <th>Наименование</th>
                <th>Стоимость</th>
                <th colSpan={2} >Кол-во</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colSpan={5}>
                    <Container>
                    <Form onSubmit={addDetail}>
                        <Row noGutters>
                            <Col sm={2}>
                                Наименование:
                            </Col>
                            <Col sm={3}>
                                <Form.Control type="text" name="name" size="sm" value={name}
                                onChange={(e)=>{setName(e.target.value)}}/>
                            </Col>
                            <Col sm={2} style={{marginLeft:'1em'}}>
                            Стоимость:
                            </Col>
                            <Col sm={1}>
                                <Form.Control type="number" name="price" size="sm" value={price}
                                onChange={(e)=>{setPrice(e.target.value)}}/>
                            </Col>
                            <Col sm={1} style={{marginLeft:'1em'}}>
                            Кол-во:
                            </Col>
                            <Col sm={1}>
                                <Form.Control type="number" name="count" size="sm" value={count}
                                onChange={(e)=>{setCount(e.target.value)}}/>
                            </Col>
                            <Col sm={1}>
                                <Button size="sm" type="submit" style={{marginLeft:'1em'}}>Добавить</Button>
                            </Col>
                        </Row>
                        </Form>
                    </Container>                    
                </td>
            </tr>
            {Details.map((detail, index)=>{return(
            <tr key={index}>
                <td>{detail.id}</td>
                <td
                draggable={true}
                onDragStart={(e)=>{
                    e.dataTransfer.setData('id_detail', detail.id);
                    e.dataTransfer.effectAllowed='move';
                    e.dataTransfer.setDragImage(e.target,0,0);
                    }}
                >{detail.name}</td>
                <td>{detail.price} ₽</td>
                <td>{detail.count}</td>
                <td><Button size="sm" variant="outline-secondary"
                             onClick={()=>{setDetailModalParam({show:true, id:detail.id})}}>
                                 e
                    </Button>
                    <Button
                        size="sm" variant="outline-secondary"
                        onClick={(e) => {
                            setShowCounterpartiesModal(true);
                            const id_detail = e.currentTarget.parentElement.parentElement.childNodes[0].innerHTML
                            const name_detail = e.currentTarget.parentElement.parentElement.childNodes[1].innerHTML
                            setDetailInfoCounterpartiesModal([id_detail, name_detail])
                        }}>
                        Поставщики
                    </Button>     
                </td>
            </tr>
            )})}
        </tbody>
    </Table>
    </div>);
}

/* Модуль поставщиков
    API: контроллер werehouse-components
        Таблица: wh__details_counterparties
            create-counterparty
            read-counterparty
            delete-counterparty
            update-cost-counterparty

        Таблица: counterparties__entity
            add
            views
*/
/* props:
    detail_info: [id_detail: number, name_detail: string]
    counterparty = {id_counterparty: number, name: string}
    show  = boolean
    handleClose = function
*/
const api_address = process.env.REACT_APP_API_HOST
var headers = {'AuthorizationToken': store.User.token?store.User.token:null, 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'};
function apiPost(endpoint, func, params, requestBody){
    return new Promise((resolve, reject) => {
            axios.post(api_address+endpoint+'/'+func, requestBody, {params:params},
            {useCredentails: true , mode:'no-cors', headers: headers})
            .then(response=>{

                if(!response.data.error)
                    resolve(response.data); //Данные от API получены без ошибок
                else
                    reject(response.data.error); //Ошибка запроса к API
            })
            .catch(error=>{
                console.log('System error '+error); //Системаня ошибка
            });
        })
}


const CounterpartiesModal = ({show, handleClose, detailInfo}) => {
    const controller = "warehouse-components"

    const apiGetCounterparties = () => {
        // apiPost("POST", controller, "read-counterparty", "", {id_detail: detailInfo[0]})
        apiPost(controller, "read-counterparty", "", {id_detail: detailInfo[0]})
            .then(res => {setCounterparties(res)})
    }

    const [keySelect, setKeySelect] = useState(new Date().getTime())
    const [counterparties, setCounterparties] = useState([])   
    const [selectDictionaryState, setSelectDictionaryState] = useState(undefined)
    const [counterpartyExist, setCounterpartyExist] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)            // Номер выбранной строки в таблице

    const setDefault = () => {
        setCounterparties([])
        setSelectDictionaryState(undefined)
        setCounterpartyExist(false)
        setSelectedRow(null)
        setKeySelect(new Date().getTime())      // Обновляет selectdictionary
    }

    const handleCloseAndSetDefault = () => {
        setDefault()
        handleClose()
    }

    
    const tableRef = useRef(null)
    useEffect(() => {
        // Очищает предыдущую строку
        if(selectedRow != null){
            const prevTdElements = tableRef.current.childNodes[selectedRow].childNodes
            for(let i = 0; i < prevTdElements.length; i++){
                prevTdElements[i].style.backgroundColor = ""
            }
            setSelectedRow(null)
        }
        if(!counterpartyExist)
            return

        // Подсвечивает в таблице выбранного контрагента
        const find_id = selectDictionaryState.target.value
        const indexInTable = counterparties.map(counterparty => counterparty.id_counterparty).indexOf(find_id)
      
        const tdElements = tableRef.current.childNodes[indexInTable].childNodes
        for(let i = 0; i < tdElements.length; i++){
            tdElements[i].style.backgroundColor = "#b0ffb0"
        }

        setSelectedRow(indexInTable)
    }, [counterpartyExist])

    useEffect(() => {
        if(selectDictionaryState === undefined){
            return
        }

        const id_counterparty = selectDictionaryState.target.value
        const exist = counterparties.find(counterparty => counterparty.id_counterparty === id_counterparty)
        setCounterpartyExist(exist)

    }, [selectDictionaryState])
    


    useEffect(() => {
       if(detailInfo[0]){
            apiGetCounterparties()
       }
        
    }, [detailInfo])

    const getRow = (e) => {
        let el = e.target.parentElement
        let i = -1;
        while(el){
            el = el.previousSibling
            i++
        }

    }

    const rowClickHandler = (e) => {
        let el = e.target.parentElement
        let i = -1;
        while(el){
            el = el.previousSibling
            i++
        }
    }

    const inputCost = useRef(undefined)

    const addCounterparties = async() => {
        const validate = () => {
            if(selectDictionaryState === undefined)     return false
            if(inputCost.current === undefined)         return false
            if(Number(inputCost.current.value) < 0 )    return false

            return true
        }

        if(!validate())
            return
        
        const id_detail = detailInfo[0]
        const id_counterparty = selectDictionaryState.target.value
        const cost = inputCost.current.value

        api("GET", controller,
            `create-counterparty?
            id_detail=${id_detail}&
            id_counterparty=${id_counterparty}&
            cost=${cost}`
        ).then(() => {
            setDefault()
            apiGetCounterparties()      // Реактивно обновляет страницу меняя состояние conunterparties
        })
        
        inputCost.current.value = ""

    }

    const deleteCounterparty = () => {
        const id_counterparty = selectDictionaryState.target.value
        const id_detail = detailInfo[0]

        // http://erp-back-yii.ru/web/werehouse-components/delete-counterparties?id_detail=10&id_counterparty=2
        api("GET", controller,
            `delete-counterparty?
             id_counterparty=${id_counterparty}&
             id_detail=${id_detail}`
        ).then(() => {
            setSelectedRow(null)
            apiGetCounterparties()      // Реактивно обновляет страницу меняя состояние conunterparties
        }).then(() => {
            setDefault()
            setKeySelect(new Date().getTime())

        })
        
        inputCost.current.value = ""
    }


    const deleteCounterpartyInRow = (e) => {
        let el = e.target.parentElement.parentElement
        
        let i = -1;
        while(el){
            el = el.previousSibling
            i++
        }

        const id_counterparty = counterparties[i].id_counterparty
        const id_detail = detailInfo[0]

         // http://erp-back-yii.ru/web/werehouse-components/delete-counterparties?id_detail=10&id_counterparty=2
        api("GET", controller,
            `delete-counterparty?
             id_counterparty=${id_counterparty}&
             id_detail=${id_detail}`
        ).then(() => {
            setSelectedRow(null)
            apiGetCounterparties()      // Реактивно обновляет страницу меняя состояние conunterparties
        }).then(() => {
            setDefault()
            setKeySelect(new Date().getTime())
            
        })
        
        inputCost.current.value = ""
    }


    const updateCounterparty = () => {
        const id_counterparty = selectDictionaryState.target.value
        const id_detail = detailInfo[0]
        const new_cost = inputCost.current.value

        api("GET", controller,
            `update-cost-counterparty?
             id_counterparty=${id_counterparty}&
             id_detail=${id_detail}&
             new_cost=${new_cost}`
        ).then(() => {
            setSelectedRow(null)
            apiGetCounterparties()      // Реактивно обновляет страницу меняя состояние conunterparties
        }).then(() => {
            setDefault()
            setKeySelect(new Date().getTime())
        })

        inputCost.current.value = ""
    }
    return(
        <Modal show={show} onHide={handleCloseAndSetDefault} size="xl">
            <Container>
                <Row>
                    <Col md={2}><h2>ID = {detailInfo[0]}</h2></Col>
                    <Col><h2 style={{textAlign: "center"}}>{detailInfo[1]}</h2></Col>
                </Row>
            </Container>
    
            <Modal.Body>
                <Row>
                    <Col>
                        <Select 
                            key = {keySelect}
                            dictionary={controller}
                            onChange={(e)=>{setSelectDictionaryState(e)}}
                        />
                        </Col>
                    <Col>
                        <Form.Control ref={inputCost} style={{height: "100%"}} type="email" placeholder="Цена детали" />
                    </Col>
                    <Col>
                        {counterpartyExist
                            ?<>
                                <Button onClick={updateCounterparty} style={{height: "75%"}} variant="warning">Редактировать</Button>
                                <Button onClick={deleteCounterparty} style={{height: "75%"}} variant="danger">Удалить</Button>
                             </>
                            :   <Button onClick={addCounterparties} style={{height: "75%"}} variant="success">Добавить</Button>
                        }
                    </Col>
                </Row>
                

                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Поставщик</th>
                            <th>Цена, ₽</th>
                        </tr>
                    </thead>
                    <tbody ref={tableRef}>
                        {counterparties.map((row) =>
                            <tr>    
                                <td onClick={rowClickHandler}>{row.counterparty}</td>
                                <td onClick={getRow}>{row.cost}</td>
                                <td>
                                    <Button onClick={deleteCounterpartyInRow} size="sm" variant="outline-secondary"
              
                                    >
                                    del
                                    </Button> 
                                </td>
                            </tr>
                        )}
                    </tbody>    
                </Table>
            </Modal.Body>
        </Modal>
    )
}

class DetailModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={name:'', count:0, price:0, id:0, isLoading:false};
        this.Save = this.Save.bind(this);
        this.Close = this.Close.bind(this);
        this.Delete = this.Delete.bind(this);
    }
    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "warehouse", "get-detail-by-id",{id:this.props.param.id})
            .then(result=>this.setState({name:result.name, count:result.count, price:result.price, id:this.props.param.id, isLoading:true}))
        }
    }
    Save()
    {
        api("GET", "warehouse", "set-detail-data",{id:this.state.id,
             name:this.state.name, count:this.state.count, price:this.state.price})
            .then(()=>{this.Close()});
    }

    Close()
    {
        this.props.onHide();
        this.setState({name:'', id:0, count:0, price:0, isLoading:false});
    }
    Delete()
    {
        api("GET", "warehouse", "delete-detail",{id:this.state.id})
           .then(()=>{this.Close()});
    }
    render()
    {
        return(
            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
              <Form>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.param.id?'Редактировать деталь':'Добавить деталь'}
                <Button onClick={this.Delete}>
                    Удалить
                </Button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Введите наименование детали</Form.Label>
                <Form.Control type="text" name="name" placeholder="Название" size="sm"
                            value={this.state.name}
                            onChange={(e)=>{this.setState({name:e.target.value})}}/>
                <Form.Label>Введите стоимость детали</Form.Label>
                <Form.Control type="number" min="0" name="price" placeholder="Стоимость" size="sm"
                            value={this.state.price}
                            onChange={(e)=>{this.setState({price:e.target.value})}}/>
                <Form.Label>Введите количество детали</Form.Label>
                <Form.Control type="number" min="0" value="1" name="count" placeholder="Количество" size="sm"
                            value={this.state.count}
                            onChange={(e)=>{this.setState({count:e.target.value})}}/>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.Save}>Сохранить</Button>
              <Button onClick={this.Close}>Отмена</Button>
            </Modal.Footer>
            </Form>
          </Modal>  
        );
    }
}
export default Warehouse;