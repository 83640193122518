import React, {Component} from 'react';
import {Route,  Switch} from "react-router-dom";
import {Container, Button, Row, Col, Table, Modal, Form, Spinner} from 'react-bootstrap'
import api from '../Api'
export default class Manufacture extends Component{

    render()
    {
        return(<Switch>
        <Route path="*/edit">
            edit{this.props.match.params.id}
        </Route>
        <Route path="*/add">
            add
        </Route>
        <Route exact path="*/">
        {this.props.match.params.id?(
            <div>
                {this.props.match.params.id}
            </div>
        ):(
           
                <ManufactureAll/>
        )}
        </Route>
        </Switch>);
    }
}

class ManufactureAll extends Component{
    constructor(props)
    {
        super(props)
        this.state={date:{month:new Date().getMonth()+1, year:new Date().getFullYear()},
                    categories:[],isLoadingCategory:false, open: false, devices: [], EntityModalParam:{show:false}, dev_id: 0, item_id: 0}
    }
    componentDidMount()
    {
       this.loadData(this.state.date.month, this.state.date.year);
    }

    loadData = (month, year) =>
    {
        this.setState({isLoadingCategory:false});
        api("GET", "manufacture_device", "get-devices",{month:month, year:year})
        .then((result)=>this.setState({isLoadingCategory:true,
            categories:result,
            date:{...this.state.date, month:month, year:year}
        }))
    }
    addModel = (id_model) =>
    {
        api("GET", "manufacture_device", "add-device",{id_model:id_model, 
            month:this.state.date.month, year:this.state.date.year})
        .then((result)=>{if(result===1)this.loadData(this.state.date.month, this.state.date.year)})
    }
    nextMonth=()=>
    {
        if(this.state.date.month===12)
            this.loadData(1,this.state.date.year+1);
        else
            this.loadData(this.state.date.month+1,this.state.date.year);
    }
    previousMonth=()=>
    {
        if(this.state.date.month===1)
            this.loadData(12,this.state.date.year-1);
        else
            this.loadData(this.state.date.month-1,this.state.date.year);
    }
    nextYear=()=>
    {
            this.loadData(this.state.date.month,this.state.date.year+1);
    }
    previousYear=()=>
    {
            this.loadData(this.state.date.month,this.state.date.year-1);
    }
    render()
    {
        const {date, categories, isLoadingCategory, EntityModalParam}=this.state;
        var months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", 
            "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
        return(
            <Container>
                <EntityModal show={EntityModalParam.show}
                onHide={()=>{
				this.setState({EntityModalParam:{show:false}});
            }}
            param={EntityModalParam}/>
                <Row>
                    <Col></Col>
                    <Col xs lg="2">
                        <Button onClick={this.previousMonth} size="sm">&lt;</Button>
                        {months[date.month-1]}
                        <Button onClick={this.nextMonth} size="sm">&gt;</Button>
                    </Col>
                    <Col>
                        <Button onClick={this.previousYear} size="sm">&lt;</Button>
                        {date.year}
                        <Button onClick={this.nextYear} size="sm">&gt;</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {isLoadingCategory&&
                        categories.map((category,key)=>
                            <Table striped bordered hover size="sm" key={key}>
                                <thead>
                                    <tr>
                                        <td>{category[0].name}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {category[0].models&&
                                    category[0].models.map((model,key_model)=>
                                    <Container style={{paddingRight:0, paddingTop:'15px', paddingBottom:'15px', border:'1px solid #cecece'}}>
                                    <Row key={key_model}>
                                        <Col md={8}>{model[0].name}</Col>
                                        <Col md={1}>{model[0].count}</Col>
                                        <Col md={1}><Button size="sm"
                                             onClick={()=>this.addModel(model[0].id)}>+</Button></Col>
                                    </Row>
                                    <Table striped bordered hover size="sm" className="mt-2"> 
                                               {
                                               model[0].details.map((dev, key)=>
                                               <tr key={key}>
                                               <td>
                                                   Серийный номер: {dev.id}</td>
                                                <td>
                                                   Начало производства: {dev.start_date}</td>
                                                <td><Button size="sm"
                        onClick={()=>{this.setState({EntityModalParam:{show:true, dev_id: dev.id_model, item_id: dev.id}})}}>
                        Открыть
                    </Button></td>
                                               </tr>
                                               )}
                                        </Table>
                                    </Container>)}
                                </tbody>
                            </Table>)}
                    </Col>
                </Row>
            </Container>
        )
    }
}

class UnitItem extends Component{
    constructor(props)
    {
        super(props)
        this.state={open: false,
             add: false,
             isLoad: false,
             children: []};
    }
 
    componentDidMount()
    {
     this.load();
    }
 
    load()
    {
        const {item} = this.props;
        this.setState({isLoad: false,
            children: []});
        api('GET', 'models_unit', 'get_content',{id_unit:item.id})
        .then((result)=>{   
            this.setState({children:result, isLoad:true});
        });
    }

    GetCount()
    {
        const {item, parent, dev} = this.props;
        const {name, count, isLoad, rdy} = this.state;
        api('GET', 'manufacture_compound', 'get-count',{wh_id:item, dev_id:dev, name:this.state.name})
        .then((result)=>{   
            this.setState({rdy:result});
        });
    }
    

    render()
    {
        const {item, parent, dev} = this.props;
        const {open, isLoad, children} = this.state;
        return(
            <Container style={{paddingRight:0, paddingTop:'15px', paddingBottom:'15px', border:'1px solid #cecece'}}>
                <Row> 
                    <Col md={1} onClick={()=>{this.setState({open:!open})}}>{open?'-':'+'}</Col>
                    <Col md={7}>Узел: {item.name}</Col>
                    <Col md={1}>Сер.н.{dev}</Col>
                    <Col md={1}></Col>
                    <Col md={2}>что-то</Col>
                </Row>
                {(children&&open)?(
                    <Row>
                        {isLoad?(
                        <Col sm={12}><List item={children} parent={item} /></Col>):(
                            <Col><Spinner animation="grow" /></Col>
                        )}
                    </Row>) : null}
            </Container>)
            
    }    
 }
 
 class DetailItem extends Component{
    constructor(props)
    {
        super(props)
        this.state={name:'', count:0, isLoad:false, rdy: 0};
        this.AddU = this.AddU.bind(this);
    }
 
    componentDidMount()
    {
     this.load();
     this.GetCount();
    }
 
    load()
    {
        this.setState({name:'', count:0, isLoad:false});
 
        const {item} = this.props;
        const {type, id} = this.props.parent;
 
        if(type==='model')
            api('GET', 'models_models', 'get_detail',
            {id_model:id, id_detail:item})
            .then((result)=>{   
                this.setState({name:result.name, count:result.count, isLoad:true})})
        else if(type==='unit')
            api('GET', 'models_unit', 'get_detail',
            {id_unit:id, id_detail:item})
            .then((result)=>{   
                this.setState({name:result.name, count:result.count, isLoad:true})})
    }

    GetCount()
    {
        const {item, parent, dev} = this.props;
        const {name, count, isLoad, rdy} = this.state;
        api('GET', 'manufacture_compound', 'get-count',{dev_id:dev, name:this.state.name})
        .then((result)=>{   
            this.setState({rdy:result});
        });
    }

    AddU()
    {
        const {item, dev} = this.props;
        api('GET', 'manufacture_compound', 'add-unit',{wh_id:item, dev_id:dev, type:'detail', name:this.state.name})
        .then((result)=>{   
            // console.log(result);
        });
    }
    
    render()
    {
        const {item, parent, dev} = this.props;
        const {name, count, isLoad, rdy} = this.state;
        
        if(isLoad)
            return(
                <Container style={{paddingRight:0, paddingTop:'15px', paddingBottom:'15px', border:'1px solid #cecece'}}>
                    <Row> 
                        <Col md={1}></Col>
                        <Col md={7}>Деталь: {name}</Col>
                        <Col md={1}>
                            Надо:{count}
                        </Col>
                        <Col md={1}>Готово {rdy}</Col>
                        <Col md={2}><Button onClick={this.AddU}>Готово</Button></Col>
                    </Row>
                </Container>)
        else
            return <Spinner animation="grow" />     
    }    
 }
 
 class OperationItem extends Component{
     constructor(props)
     {
         super(props)
         this.state={name:'', cost:0, isLoad:false};
         this.AddU = this.AddU.bind(this);
     }
  
     componentDidMount()
     {
      this.load();
     }
  
     load()
     {
         this.setState({name:'', cost:0, isLoad:false});
  
         const {item, dev} = this.props;
         const {id} = this.props.parent;
  
         api('GET', 'operations_list', 'view',
             {id:item})
             .then((result)=>{   
                 this.setState({name:result.name, cost:result.cost, isLoad:true});
             });
     }

     AddU()
     {
        const {item, dev, parent} = this.props;
  
         api('GET', 'manufacture_compound', 'add-unit',{wh_id:'', dev_id:dev, type:'operation', name: this.state.name})
         .then((result)=>{   
            //  console.log(result);
         });
     }

     GetCount()
     {
         const {item, parent, dev} = this.props;
         const {name, count, isLoad, rdy} = this.state;
         api('GET', 'manufacture_compound', 'get-count',{dev_id:dev, name:this.state.name})
         .then((result)=>{   
             this.setState({rdy:result});
         });
     }

     render()
     {
         const {item, parent, dev} = this.props;
         const {name, cost, isLoad} = this.state;
         if(isLoad)
             return(
                 <Container style={{paddingRight:0, paddingTop:'15px', paddingBottom:'15px', border:'1px solid #cecece'}}>
                     <Row> 
                        <Col md={1}></Col>
                         <Col md={8}>Операция: {name}</Col>
                         {/* {console.log(parent)} */}
                         <Col md={1}></Col>
                         <Col md={2}><Button onClick={this.AddU}>Готово</Button></Col>
                     </Row>
                 </Container>)
         else
             return <Spinner animation="grow" />     
     }    
  }
 
 class List extends Component{
    constructor(props)
    {
        super(props)
        this.state={items:[], isLoad:false}
    }
 
    componentDidMount()
    {
     this.load();
    }
 
    load()
    {
        //this.state={items:[], isLoad:false}
         this.setState({isLoad:false})
        const {type, id, dev} = this.props.parent;
 
        if(type==='model')
            api('GET', 'models_models', 'get_content',{id_model:id})
            .then((result)=>{   
                this.setState({items:result, isLoad:true});
            });
        else if(type==='unit')
            api('GET', 'models_unit', 'get_content',{id_unit:id})
            .then((result)=>{   
                this.setState({items:result, isLoad:true});
            });
    }
 
    render()
    {
        const {parent} = this.props;
        const {dev} = this.props.parent;
        // console.log(this.props.parent)
        if(this.state.isLoad)
            return this.state.items.map((item,i)=>{
                if(item.type==="unit")
                    return(<UnitItem key={i} item={item} dev={dev}
                         reloadList={()=>{this.load()}} parent={parent}/>)
                else if(item.type==="detail")
                    return(<DetailItem key={i} item={item.id} dev={dev}
                         reloadList={()=>{this.load()}} parent={parent}/>)
                 else if(item.type==="operation")
                    return(<OperationItem key={i} item={item.id} dev={dev}
                         reloadList={()=>{this.load()}} parent={parent}/>)
                 return (<div></div>)
            })
        else
            return <Spinner animation="grow" />
    }
 }
 


class EntityModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={dev_id: 0, item_id:0, isLoad: false, list: {}};
        this.Close = this.Close.bind(this);
        this.GetU = this.GetU.bind(this);
    }
    
    componentDidMount()
    {
        api("GET", "models_models", "get_content",{id_model:this.props.param.dev_id})
        .then(result=>this.setState({list:result, dev_id:this.props.param.dev_id, item_id:this.props.param.item_id, isLoad:true})) }

	componentDidUpdate()
    {
        if (this.props.param.dev_id && this.state.isLoad===false) {
        api("GET", "models_models", "get_content",{id_model:this.props.param.dev_id})
        .then(result=>this.setState({list:result, dev_id:this.props.param.dev_id, item_id:this.props.param.item_id, isLoad:true})) }
        this.GetU();
    }
	
    Close()
    {
        this.props.onHide();
        this.setState({dev_id: 0, item_id:0, isLoad:false});
    }

    GetU()
    {
        api('GET', 'manufacture_compound', 'get-unit',{dev_id:this.state.item_id})
        .then((result)=>{   
            // console.log(result);
        });
    }


    render()
    {
        return(
			<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          	>
              <Form>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Серийный номер: {this.state.item_id}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <List parent={{type:'model', id:this.props.param.dev_id, dev:this.state.item_id}} ref='list'/>
            </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.Close}>Отмена</Button>
            </Modal.Footer>
            </Form>
          </Modal>  
        );
    }
}