import React, {Component} from 'react';
import { Table, Spinner, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import api from '../Api'
import * as Icon from 'react-bootstrap-icons';

class Messagelist extends Component
{
    constructor(props)
    {
        super(props);
        this.state={date:{month:new Date().getMonth()+1,
            year:new Date().getFullYear()},
            messagelist:'',
            messages:{},
            EntityModalParam:{show:false},
            isLoad:false};
           this.LoadMessages = this.LoadMessages.bind(this); 
    }
    componentDidMount()
    {
        /*this.loadData(this.state.date.month, this.state.date.year);*/
        this.LoadMessages(this.state.date.month, this.state.date.year);
    }
    LoadMessages = (month, year)=>
    {
        this.setState({isLoad:false});
        api("GET","messagelist","restjson",{month:month, year:year})
        .then((result)=>{
            this.setState({messages:result,
                isLoad:true,
                date:{...this.state.date, month:month, year:year}
            });
            /*console.log(result);*/
        });
    }
    nextMonth=()=>
    {
        if(this.state.date.month===12)
            this.LoadMessages(1,this.state.date.year+1);
        else
            this.LoadMessages(this.state.date.month+1,this.state.date.year);
    }
    previousMonth=()=>
    {
        if(this.state.date.month===1)
            this.LoadMessages(12,this.state.date.year-1);
        else
            this.LoadMessages(this.state.date.month-1,this.state.date.year);
    }
    nextYear=()=>
    {
            this.LoadMessages(this.state.date.month,this.state.date.year+1);
    }
    previousYear=()=>
    {
            this.LoadMessages(this.state.date.month,this.state.date.year-1);
    }
    render()
    {
        const {date, messages, isLoad, EntityModalParam} = this.state;
        var months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", 
            "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
        
        return(<div>
            <EntityModal show={EntityModalParam.show}
            onHide={()=>{
                this.setState({EntityModalParam:{show:false}})
            }}
            LoadMessages={this.LoadMessages} month = {this.state.date.month} year = {this.state.date.year}
            param={EntityModalParam}/>
            <Row>
            <Col></Col>
            <Col xs lg="2">
                <Button onClick={this.previousMonth} size="sm">&lt;</Button>
                {months[date.month-1]}
                <Button onClick={this.nextMonth} size="sm">&gt;</Button>
            </Col>
            <Col>
                <Button onClick={this.previousYear} size="sm">&lt;</Button>
                {date.year}
                <Button onClick={this.nextYear} size="sm">&gt;</Button>
            </Col>
        </Row>{isLoad?(<Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Дата</th>
                    <th>Имя</th>
                    <th>Обращение</th>
                    <th>Комментарий</th>
                    <th>телефон</th>
                    <th>e-mail</th>
                    <th>Страница</th>
                </tr>
            </thead>
            <tbody>
                {messages.map((Row, key)=>{return(<tr key={key}>
                <td>{Row.date_msg}</td>
                <td>{Row.theme_msg}</td>
                <td>{Row.text_msg}</td>
                <td style={{cursor: 'pointer'}} onClick={()=>{this.setState({EntityModalParam:{show:true, id:Row.ID}})}}><Icon.PencilSquare width="24" height="24" style={{marginRight:'5px'}}/>
                    {Row.description}
                </td>
                <td>{Row.user_phone}</td>
                <td>{Row.user_mail}</td>
                <td>{Row.page_link}</td>
                </tr>)})}
            </tbody>
        </Table>):(<Spinner animation="border"/>)}</div>);
    }
}

class EntityModal extends Component
{
    data={
        id:'',
        description:''
    };

    constructor(props)
    {
        super(props)

        this.state={
            isLoading:false,
            data:this.data,
        };
        this.state.data.id=this.props.param.id;
    }

    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "messagelist", "get-message",{id:this.props.param.id})
            .then(result=>
                this.setState({data:{...result}, isLoading:true}))
        }
    }

    Save = () =>
    {
        console.log(this.state.data.description);
        api("GET", "messagelist", "editcom",{id:this.props.param.id,
            description:this.state.data.description})
            .then(()=>{
                this.Close();
            });
            
    }


    Close = () =>
    {
        this.props.LoadMessages(this.props.month, this.props.year);
        this.props.onHide();
        this.setState({data:this.data,  isLoading:false});
    }

    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

    render()
    {
        const {data} = this.state;
        
        return(<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
              <Form>
                <Modal.Header>
                    <Modal.Title>
                        Редактировать
                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Комментарий</Form.Label>
                        <Form.Control type="text" name="description" size="sm"
                            value={data.description} onChange={this.changeValue}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.Save}>Сохранить</Button>
                    <Button onClick={this.Close}>Отмена</Button>
                </Modal.Footer>
              </Form>
          </Modal>);

    };
};

export default Messagelist;