import React, { Component } from 'react';

import Creatable from 'react-select/creatable';
import api from "../Api"

export default class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: [],
      attributes: [],
      value: undefined,
	  label:'',
    };
	this.ReloadSpis = this.ReloadSpis.bind(this);
  }

  apiView(){
    api('GET', this.props.dictionary, 'views', this.props.params)
    .then((result)=>{
        // console.log("api", result);
        var arr = Object.keys(result).map(function (key) { return {value:result[key]['id'], label:result[key]['name']}; });
        this.setState({options:arr, attributes:result});
    });
  }


  componentWillMount()
  {
    this.apiView()
  }
  
  ReloadSpis()
  {
    this.apiView()
  }

  onChange = (newValue) => {
    if(!newValue) newValue={value:'', label:''};
    console.log(newValue);
    this.setState({ value: newValue , label: newValue.label});
    const e = {
      target:{
        name: this.props.name,
        type: 'custom-select',
        value: newValue.value,
        label: newValue.label,
        // attributes: this.state.attributes(newValue)
        attributes: this.state.attributes.find(el => el.id == newValue.value)
      }
    };
    
    if(this.props.onChange)
      this.props.onChange(e);
  }

  createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  onCreateOption = (inputValue) => {
    this.setState({ isLoading: true });
    api('GET', this.props.dictionary, 'add', {value:inputValue})
    .then((result)=>{
      this.ReloadSpis();
      const { options } = this.state;
      var newOption = this.createOption(inputValue); 
      this.setState({
        isLoading: false,
        options: [...options, result],
        value: newOption
      });
      this.onChange({value:result.value, label:result.name})
    });
  }
    render() {
      const { isLoading, options, value, attributes} = this.state;
      return (
        <Creatable
          name={this.props.name}
          placeholder={this.props.placeholder}
          isClearable
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={this.onChange}
          onCreateOption={this.onCreateOption}
          options={options}
          attributes={attributes}
          value={value}
        />
      );
    }
  }
