import React, {Component, useState, useEffect} from 'react';

export default function Areatext(props){
    const calcRows = (text) =>{
        if(text === undefined){
            return 1;
        }

        let local_new_line = 0;
        let lineCount = 1;

        let a = 0, b = 0, c = 0;
        while(b != text.length){
            a = c * 80;
            b = a + 79;
            if(b >= text.length)
                b = text.length;

            for(const l of text.substring(a, b)){
                if(l == "\n")
                    local_new_line++;
            }

            lineCount += local_new_line;
            if(local_new_line == 0)
                lineCount++;
            
            local_new_line = 0;
            c++;
        }
        
        return lineCount--;
    }

    const [textareaVal, setTextareaVal] = useState('');
    const [rows, setRows]               = useState(1);

    const onChangeTextarea = (e) => {
        const text = e.target.value;
        const lineCount = calcRows(text);
        if(rows !== lineCount)
            setRows(lineCount);
        setTextareaVal(e.target.val);
    };

    const handleChangeVal = (e) =>{
        props.onChangeVal(e);
    }

    const onChange = (e) => {
        onChangeTextarea(e);
        handleChangeVal(e);

    }

    // Для инициализации
    useEffect(() =>{
        setTextareaVal(props.initval);
        setRows(calcRows(props.initval));
        
    }, [props.initval]);

    const style_1 = {
        width: "100%",
        height: "auto",
        'resize': "none",
        border: "#ced4da 1px solid",
        padding: ".275rem .55rem",
        "border-radius": ".25rem",
    };

    const style_2 = {
        width: "100%",
        padding: 0,
        height: "auto",
        'resize': "none",
        padding: ".375rem .75rem",
        border: 0,

    };
    
    if(props.stylePicker === "1"){
        return(
            <textarea value={textareaVal} rows={rows} onChangeVal={handleChangeVal} initval={props.initval} onChange={onChange} style={style_1} stylePicker={props.stylePicker}/>
        );
    }
    
    else{
        return(
            <textarea value={textareaVal} rows={rows} initval={props.initval} onChange={onChange} style={style_2} stylePicker={props.stylePicker}/>
        );
    }
    
}
