import {observable} from "mobx";
import Cookies from "universal-cookie";
import api from "./Api.js"

var cookie = new Cookies();

const store = observable({
    User:{token:cookie.get('token')}
});

function Login (login, pass, saved) {
    return new Promise(function(resolve, reject)
    {
        api("POST", "auth", "login", {login:login,pass:pass})
        .then(res=>{
            Check(res.token);
            if(saved)
                cookie.set('token',res.token);
        })
        .catch(error=>{
            reject(error);
        });
    });
};

const Logout = () => {
    cookie.set("token", "");
    api("POST", 'auth', 'logout', {token:store.User.token});
    store.User={token:''};
};

const Check = (token) => {
    api("GET", 'user', 'get-by-token',{token:token})
    .then(user=>{
        store.User={token:token, ...user};
   })
    .catch(error => {
        Logout();
      cookie.set("token", "");
      alert("Check user error: "+error);
      return 1;
    });
}

export {Login, Logout, Check};

export default store;