

// Выводит сообщения с [#start_tick, #end_tick]
class ConsoleLogWithTick{
    #current_tick
    #start_tick
    #end_tick

    constructor(start_tick, end_tick) {
        this.#start_tick = start_tick
        this.#end_tick = end_tick
        this.#current_tick = 0
    }

    log(log_obj){
        if(this.#current_tick < this.#start_tick){
            this.#current_tick++
        }
            
        else if(this.#current_tick <= this.#end_tick){
            this.#current_tick++
            console.log(`This tick: ${this.#current_tick}`);
            console.log(log_obj);
        }
        
    }
}


export class StringMethod{
    // Ищет индексы вхождение буквы в string
    // TODO проверить на indexof
    static findPositionCharacter(char, string){
        if(char.length !== 1)
            throw Error("Параметром функции должен быть символ")

        char = char.toLowerCase()
        string = string.toLowerCase()
        let positions = []
        for(let i = 0; i < string.length; i++){
            if(string[i] === char)
                positions.push(i)
        }
        return positions
    }
    
    static containWord(findWord, string){
        if(!findWord)
            return true
            
        if(findWord.length > string.length)
            return false
        
        findWord = findWord.toLowerCase()
        const positionsFirstChar = StringMethod.findPositionCharacter(findWord[0], string)
        if(findWord.length === 1)
            return positionsFirstChar.length > 0
        
        for(let i of positionsFirstChar){
            i += 1
            let j = 1
            while(i < string.length){
                if(findWord[j] != string[i].toLowerCase())
                    break
                
                i++; j++
                
                if(j === findWord.length)
                    return true
            }
        }
        return false
    }
}

/**
 * Проходятся в глубину по дереву и применяет к каждому узле коллбек
 */
export class DFS{                           
    static preorderIterate(node, callback){
        if(!node)
            return

        callback(node)
        
        if(!Array.isArray(node.children))
            return
        
        for(const next_node of node.children)
            this.preorderIterate(next_node, callback)
    }
}

/**
 * Алгоритмы к дереву с проходом в ширину
 */
export class BFS{                           
    /**
     * Проходится в ширину по дереву и применяет к каждому узлу коллбек
     */
    static iterate(tree, callback){
        let queue = [tree]                  //  shift <- [1, 2, 3] <- push
        while(queue.length){
            const node = queue.shift()
            callback(node)
            if(!node.children)
                continue
    
            for(const next_node of node.children){
                queue.push(next_node)
            }
        }
    }
    
    
    /**
     * Добавляет указатель на родительский узел
     * 
     */
    static addParentsNode(tree){
        let queue = [tree]          // Конец очереди первый элемент массива, начало последний. shift <- [1, 2, 3] <- push
        tree.parents = []           // Добавляет к корню пустой список родителей
        while(queue.length){
            const node = queue.shift()
            if(!node.children)
                continue
    
            let parents = [node]
            if(node.parents)
                parents = [node, ...node.parents]
        
            // parents.push(node.val)
            for(const next_node of node.children){
                next_node.parents = [...parents]
                queue.push(next_node)
            }
        }
    }
}