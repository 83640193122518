
import React, {FC, ChangeEventHandler, ReactNode, useState, useEffect, useMemo, useRef} from 'react';
import { Table, Button, Spinner, Nav, NavItem, Form, Container, Modal, Row } from "react-bootstrap"
import { ISelectController, readController } from '../Common/controllers';
import Select from './SelectDictionary';
import {default as ReactSelect} from 'react-select';
import {objectAdapter} from '../Common/commot_ts'

/** Интерфейс для входа/выхода в режим редактирования */
interface SelectTableProps {
    /** Порядковый номер строки */
    y: number,
    selectedY: number,
    selectClick: () => void
}

/** Добавляет обработчик для считывания изменений одного или нескольких инпутов */
interface InputTDProps {
    /** Cчитывает данные из инпут элемента */
    onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> 
}

interface DescritionTDProps extends SelectTableProps, InputTDProps{
    children?: string
}

interface LinkURL{
    description: string,
    url: string
}

interface NumberTDProps extends SelectTableProps, InputTDProps{
    children?: string
    min?: number
    max?: number
    step?: number
}

interface DateTDProps extends SelectTableProps, InputTDProps{
    children?: string | Date,
    outputFormat?: string
}

interface UrlTDProps extends SelectTableProps, InputTDProps{
    children?: string
}

// interface UrlTDProps extends SelectTDProps, InputTDProps{
//     children?: string
// }




interface SelectTDPRops extends SelectTableProps, InputTDProps{
    children: number,
    selected: number,

    // selectController: readController<void, {id:any, name:any}[]>
    selectController(): Promise<{id: string, name: string}[]>
    
}


interface SelectWithControllerProps{
    onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> | any,
    selectController(): Promise<{name: string, id: string}[]>
}

export const SelectWithController: FC<SelectWithControllerProps> = ({
    onChange,
    selectController
}) => {

    const selectStyle = {
        width: '100%',
        'font-size': '1rem',
        height: 'calc(1.5em + 0.75rem + 2px)',
        'border': '1px solid #ced4da',
        'border-radius': '0.25rem'
    }

    const [options, setOptions] = useState<{id:string, name:string}[]>([])
    useEffect(() => {
        selectController().then(res => {
            setOptions(res)
        })
    }, [])

    return(
        <select style={selectStyle} onChange={onChange}>
            {options.map(obj => <option value={obj.id}>{obj.name}</option>)}
        </select>
    )
}

export const ReactSelectWithController: FC<SelectWithControllerProps> = ({
    onChange,
    selectController
}) => {
    
    const [options, setOptions] = useState<{id:string, name:string}[]>([])
    useEffect(() => {
        selectController().then(res => {
            res = res.map(obj => 
                objectAdapter(obj, {id: 'value', name: 'label'})
            )
            setOptions(res)
        })
    }, [])

    return(
        <ReactSelect
            options={options}
            onChange={onChange}
        />
    )
}


export const SelectTD: FC<SelectTDPRops> = ({
    children,
    y,                          
    selectedY,                  // выбранная строка в таблице 
    selectClick,                // меняет состояния selectId в одном из родительских элементов
    selected,

    onChange,                  // handler: считывает поле input, каждый раз когда оно изменяется

    /** SelectController: Promise  */
    selectController
    }) => {

        const [options, setOptions] = useState<{name: string, id: string}[]>([])
        
        useEffect(() => {
            if(y !== selectedY || selectedY === undefined)         
                return 

            selectController()
             .then(res => {
                setOptions(res)   
            })
             
        }, [selectedY])



        const selectStyle = {
            width: '100%',
            'font-size': '1rem',
            height: 'calc(1.5em + 0.75rem + 2px)',
            'border': '1px solid #ced4da',
            'border-radius': '0.25rem'
        }


        return(
            <>
                {
                    y === selectedY
                        ?<td>
                            <select style={selectStyle} onChange={onChange}>
                                {options.map(val => 
                                    <option
                                        value={val.id}
                                        selected={Number(val.id) === Number(selected)}
                                        // defaultValue={val.id}
                                        children={val.name}
                                    />
                                    )
                                }
                            </select>
                        </td>

                        :<td onClick={selectClick}>{children}</td>
                }
            </>
        )
}


interface SelectDictionaryTDProps extends  SelectTableProps, InputTDProps{
    children: string,
    placeholder: string,

    /** Название используемого контроллера*/
    dictionary: string,
    name: string

}

export const SelectDictionaryTD: FC<SelectDictionaryTDProps> = ({
    children,
    y,                          
    selectedY,                  // выбранная строка в таблице 
    selectClick,                // меняет состояния selectId в одном из родительских элементов

    onChange,                  // handler: считывает поле input, каждый раз когда оно изменяется

    dictionary,
    placeholder,
    name

    }) => {
        return(
            <>
                {
                    y === selectedY
                    ?<td>
                        <Select
                            placeholder={placeholder}
                            dictionary={dictionary} 
                            name={name}
                            onChange={onChange}
                        />
                    </td>
                    :<td onClick={selectClick}>{children}</td>     
                }
            </>
            

        )


    }

interface TDProps extends DescritionTDProps, NumberTDProps, UrlTDProps{
    /** Тип элемента */
    type: string
}

export const TD: FC<TDProps> = ({
    children,
    y,
    selectedY,
    selectClick,

    onChange,

    min, 
    step,
    max,

    type
}) => {
    switch(type){
        case 'description':
            return (
                <DescriptionTD
                    onChange={onChange}
                    selectClick={selectClick}
                    selectedY={selectedY}
                    y={y}
                    children={children}
            />)
        
        case 'number':
            return (
                <NumberTD
                    onChange={onChange}
                    selectClick={selectClick}
                    selectedY={selectedY}
                    y={y}
                    children={children}

                    max={max}
                    min={min}
                    step={step}
            />)

    }

    return <td children={children}></td>
}

export const DescriptionTD: FC<DescritionTDProps> = ({
    children,
    y,                          
    selectedY,                  // выбранная строка в таблице 
    selectClick,                // меняет состояния selectId в одном из родительских элементов

    onChange,                  // handler: считывает поле input, каждый раз когда оно изменяется
    }) => {


        return(
            <>
                {
                    y === selectedY
                        ?<td>
                            <Form.Control onChange={onChange} placeholder={children}/>
                        </td>

                        :<td onClick={selectClick}>{children}</td>
                }
            </>
        )
}



export const NumberTD: FC<NumberTDProps> = ({
    children,
    y,                          
    selectedY,                   
    selectClick,                

    onChange,                  
    
    min, 
    step,
    max

    }) => {

        return(
            <>
                {
                    y === selectedY
                        ?<td>
                            <Form.Control type='number' onChange={onChange} placeholder={children} min={min} step={step} max={max}/>

                        </td>

                        :<td onClick={selectClick}>{children}</td>
                }
            </>
        )
}



/**
 * children: Y-M-D H:M:S
 */
export const DateTD: FC<DateTDProps> = ({
    children,
    y,                          // number: порядковый номер в строки
    selectedY,                   // number: выбранная строка в таблице 
    selectClick,                // handler: меняет состояния selectId в одном из родительских элементов

    onChange,                  // handler: считывает поле input, каждый раз когда оно изменяется

    }) => {

        const [view, setView] = useState<string | undefined>('')

        // children: Y-M-D H:M:S
        useEffect(() => {
            if(children === undefined)
                return

            if(children instanceof Date){
                let day = String(children.getDay())
                let month = String(children.getMonth())
                const year = String(children.getFullYear())

                if(day.length === 1)
                    day = '0' + day
                if(month.length === 1)
                    month = '0' + month

                setView(`${day}.${month}.${year}`)
                
                return 
            }
            // console.log(children);
            const arr = children?.split('-')
            // console.log(arr);
            if(arr){
                const day = arr[2].slice(0, 2)
                const month = arr[1]
                const year = arr[0]
                const hours = children.slice(11, 13)
                const minutes = children.slice(14, 16)
                const seconds = children.slice(17, 19)
                
                // console.log(`${hours}:${minutes}:${seconds}`);
    
                setView(`${day}.${month}.${year}`)
            }
            else{
                setView(children)
            }

        }, [])

        return(
            <>
                {   
                    y === selectedY
                        ?<td>
                            <Form.Control type='date' onChange={onChange}/>
                            <div>{view}</div>
                        </td>

                        :<td onClick={selectClick}>{view}</td>
                }
            </>
        )
}


export const UrlTD: FC<UrlTDProps> = ({
    children,
    y,                          // number: порядковый номер в строки
    selectedY,                   // number: выбранная строка в таблице 
    selectClick,                // handler: меняет состояния selectId в одном из родительских элементов

    onChange                  // handler: считывает поле input, каждый раз когда оно изменяется
    }) => {

        return(
            <>
                {   
                    y === selectedY
                        ?<td>
                            <Form.Control onChange={onChange} placeholder={'Ссылка'} />
                        </td>

                        :<td onClick={selectClick}>
                            {
                            children
                                ?<a href={children}>Ссылка</a>
                                :<></>
                        }
                            
                        </td>
                }
            </>
        )
}







interface ReactSelectTDProps extends SelectTableProps{
    children?: string
    selectController(): Promise<{id: string, name: string}[]>
    onChange?: any
}

export const ReactSelectTD: FC<ReactSelectTDProps> = ({
    y,                          // number: порядковый номер в строки
    selectedY,                   // number: выбранная строка в таблице 
    selectClick,   

    onChange,
    
    selectController,
    children,
}) => {


    
    const [options, setOptions] = useState<{value: string, label: string}[]>([])

    const adapterCallback = (e: any) => {
        const event = {
            target: {
                value: e.value
            }
        }
        onChange(event)
    }

    useEffect(() => {
        if(y !== selectedY || selectedY === undefined)         
            return 

        selectController()
         .then(res => {
            const options = res.map(obj => 
                objectAdapter(obj, {id: 'value', name: 'label'})
            )
            setOptions(options)   
        })
         
    }, [selectedY])



    return(
        <>
        {   
            y === selectedY
                ?<td>
                    <ReactSelect
                        options={options}
                        onChange={adapterCallback}
                        placeholder={children}
                    />
                </td>

                :<td onClick={selectClick}>{children}</td>
        }
    </>
    )
}


interface addButtonProps{
    onClick: React.MouseEventHandler<HTMLElement>
    children?: string
}
export const TableAddButton: FC<addButtonProps> = ({
    onClick,
    children
}) => {
    return (
        <Button
         onClick={onClick}
         children={children}

         variant='success'
         size='sm'
        />
    )
}



/*
    finance__enum__payment_status
    finance__enum__periodic_payment
    finance__payments
    finance__periodic_payment
*/