import React, {Component} from 'react';
import {Spinner, Button, Modal, Form, ListGroup, Card, Nav, NavItem } from 'react-bootstrap';
import {Link} from "react-router-dom";
import api from '../Api';
import * as Icon from 'react-bootstrap-icons';
import store from '../Store';
import Swal from 'sweetalert2'
import Areatext from '../Components/Areatext'

function getCurrDay(){
    var today = new Date();
    var date = [today.getFullYear(), today.getMonth() + 1, today.getDate()]
    var str = "";
    for(const a of date){
        if(a < 10){
            str += "0" + a;
        }
        else
            str += a;
        str += "-";
    }
    return str.substr(0, 10)
}

class TaskManager extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
			description:'',
			importance:0,
            navname:['Все задачи','Низкий','Обычный','Средний','Повышенный','Высокий'],
			category:1,
			allcat:0,
			status:0,
			sortby: "date_10days",
            messages:[],
			categories:[],
			namestat:['Отложенные','Активные','В работе','Завершённые'],
			coulen:['(','последние (',')'],
			limit:1,
			coloms:['','primary','info','secondary','warning','danger'],
			textcol:['text-dark','text-primary','text-info','text-secondary','text-dark','text-dark','text-light'],
			bgcol:['bg-dark','bg-primary','bg-info','bg-secondary','bg-warning','bg-danger'],
			headcol:'card-header bg-dark text-light',
			footcol:'card-footer bg-dark text-light',
			linksnext:[
				'nav-link text-light',
				'nav-link text-primary',
				'nav-link text-info',
				'nav-link text-secondary',
				'nav-link text-warning',
				'nav-link text-danger'],
			isLoad:false,
			EntityModalParam:{show:false},
			AddCategoryModalParam:{show:false}};
			this.LoadMessages = this.LoadMessages.bind(this);
			this.ActiveKey = this.ActiveKey.bind(this);
			this.YellowBlack = this.YellowBlack.bind(this);
    }
    componentDidMount()
    {
        this.LoadMessages(0, 0, this.state.sortby);
	}
	
	LoadMessages(e, ev, sortby)
    {
        this.setState({isLoad:false});

		api("GET","tm","getcat",{i:e})
        .then((result)=>{
            this.setState({categories:result});
			api("GET","tm","restjson",{importance:e, category:ev, sortby: sortby})
			.then((result)=>{
				this.setState({messages:result, isLoad:true});
			console.log(result);
			});
		});
    }
	
	YellowBlack(key)
	{
		
		if(key===4){
			this.setState({importance:key,
			headcol:'card-header '+this.state.bgcol[key]+' '+this.state.textcol[0],
			footcol:'card-footer '+this.state.bgcol[key]+' '+this.state.textcol[0]});
		} else{
			this.setState({importance:key,
			headcol:'card-header '+this.state.bgcol[key]+' '+this.state.textcol[6],
			footcol:'card-footer '+this.state.bgcol[key]+' '+this.state.textcol[6]});
		}
		
	}
	
	ActiveKey(cat)
	{
		var trfls;
		
		if(cat===this.state.category){
			if(this.state.importance===4){
				trfls = "nav-link text-dark "+this.state.bgcol[this.state.importance]+" text-right active";
			} else{
				trfls = "nav-link text-white "+this.state.bgcol[this.state.importance]+" text-right active";
			}
		} else{
			trfls = "nav-link text-right "+this.state.textcol[this.state.importance];
		}
		
		return trfls;
	}
    
    render()
    {
        const {navname, messages, isLoad, categories, namestat, coulen, limit, coloms, headcol, footcol, linksnext, EntityModalParam, AddCategoryModalParam} = this.state;

        return(
		<div className="col-md-12" style={{'height':'calc(100% - 86px)'}}>
				<EntityModal show={EntityModalParam.show}
            onHide={()=>{
				this.setState({EntityModalParam:{show:false},category:1});
            }}
			LoadMessages={this.LoadMessages}
			category={this.state.category}
            param={EntityModalParam}/>
			<AddCategoryModal show={AddCategoryModalParam.show}
            onHide={()=>{
				this.setState({AddCategoryModalParam:{show:false},category:1});
			}}
			LoadMessages={this.LoadMessages}
            param={AddCategoryModalParam}/>
			<div className="row">
				<div className="navbar navbar-expand-lg navbar-light bg-light" style={{'width':'100%'}}>
				<Icon.AlarmFill width="24" height="24" style={{marginRight:'5px'}} /><div className="navbar-brand mb-0 h1"></div>
						<Nav id="navbarSupportedContent" className="navbar-nav mr-auto bg-dark" style={{paddingLeft:8, paddingRight:8, borderRadius:4, fontSize:'90%' }}>
							<div className="nav-link text-secondary disabled" href="#">Приоритет:</div>
								{navname.map((name, key) => <NavItem className="nav-item" key={key}>
							<a className={linksnext[key]} href="#"
								onClick={()=>{ this.YellowBlack(key); this.LoadMessages(key, this.state.category, this.state.sortby);}}
								onDragOver={(e)=>{e.preventDefault()}}
										onDrop={(e)=>{api("GET", "tm", "set-importance-for-task", {id_task:e.dataTransfer.getData("key"), id_importance:key, token:store.User.token})
										.then((result)=>{if(result==='done'){this.LoadMessages(0, this.state.category, this.state.sortby)}})
										}}>
								{name}
							</a></NavItem>)}
						</Nav>
						<div className="form-inline my-2 my-lg-0 ml-2" style={{fontSize:'90%'}}>
						
						{this.state.category!=0 && (<button className="btn btn-sm btn-dark mr-2"  onClick={()=>{this.setState({AddCategoryModalParam:{show:true,id:this.state.category}
										})}}>Редактировать категорию</button>)}
							<button className="btn btn-sm btn-dark" onClick={()=>{this.setState({EntityModalParam:{show:true, category:this.state.category}});
							}}>Добавить задачу</button>
						<select className="form-control ml-2" name="sortby" 
                        	onChange={(e)=>{this.setState({sortby: e.target.value}, () => {this.LoadMessages(this.state.importance, this.state.category, this.state.sortby)})}}>
							<option value="date_10days">Завершенные за 10 дней</option>					
							<option value="date_last_update">Последнему изменению</option>
							
							<option value="date_time">Дате создания</option>
							<option value="importance">Приоритету</option>
						</select>
						</div>
				</div>
				{isLoad?(<div className="card-body card-deck bg-light ml-1 mr-1 justify-content-center" style={{height:'calc(100vh - 150px)', overflowY: 'hidden'}}>
				<div className="row d-flex justify-content-center" style={{marginLeft:'-20px', width:'100%'}}>
					<Card variant="dark" className="card col-md-2" style={{marginRight:25}}>
						<div className="row">
							<div className="card-body">
								<div className="d-sm-flex justify-content-sm-between"><b>Категории</b>
								<button className="btn btn-sm btn-dark" onClick={()=>{this.setState({AddCategoryModalParam:{show:true}
										})}}>+</button></div>
								<div className="nav flex-column nav-pills bg-light" style={{marginTop:10}}>
									<li className="nav-item" key={0}><a className={this.ActiveKey(0)} href="#"
									onClick={()=>{ this.setState({category:0}); this.LoadMessages(this.state.importance, 0, this.state.sortby);}}>Все категории</a></li>
									{categories.map((category, key) => <li className="nav-item" key={key+1}>
										<a className={this.ActiveKey(key+1)} href="#" onClick={()=>{ this.setState({category:key+1});
										this.LoadMessages(this.state.importance, key+1, this.state.sortby);}}
										onDragOver={(e)=>{e.preventDefault()}}
										onDrop={(e)=>{api("GET", "tm", "set-category-for-task", {id_task:e.dataTransfer.getData("key"), id_category:key+1, token:store.User.token})
										.then((result)=>{if(result==='done'){this.LoadMessages(0, this.state.category, this.state.sortby)}})
										}}
										>{category}</a>
									</li>)}
								</div>
							</div>
						</div>
					</Card>
					{messages.map((msg,key) => <div id={key} key={key+1} className="card col-md-3" style={{marginLeft:10, marginRight:10, availHeight:'auto'}}
						onDragOver={(e)=>{e.preventDefault()}}
						onDrop={(e)=>{api("GET", "tm", "draggable", {id:e.dataTransfer.getData("key"), status:key+1, token:store.User.token})
						.then((result)=>{if(result.Result==='done'){this.LoadMessages(this.state.importance, this.state.category, this.state.sortby)}})
						}}>
						<div className="row" style={{height:'calc(100vh - 275px)'}}>
							<div className={headcol} style={{'width':'100%'}}>
								<b>{namestat[key]}</b> {(key===2&&limit===0)?(coulen[1]+'10'+coulen[2]):(coulen[0]+msg.length+coulen[2])}
							</div>
							<ListGroup className="list-group list-group-flush" style={{'width':'100%',height:'100%', overflowY: 'auto'}}>{msg.map(message =>
								<ListGroup.Item className="list-group-item container" key={message.id} action variant={coloms[message.importance]} style={{'width':'100%'}}
									draggable={true} onDragStart={(e)=>{e.dataTransfer.setData("key", message.id);
									e.dataTransfer.effectAllowed='move';
									e.dataTransfer.setDragImage(e.target,0,0);}}
									onClick={()=>{this.setState({EntityModalParam:{show:true,
										id:message.id, name_user:message.name_user}})}}>
										 {message.description.length > 110 ?
                       					 message.description.substring(0, 110) + '...' : message.description}
										<br />
										{message.count!=0?(<div className="text-right"><span className="numberInCircle">{message.count}</span></div>):<div />}
								</ListGroup.Item>)}
							</ListGroup>
							<div className={footcol} style={{'width':'100%', 'height':'35px'}}></div>
						</div>
					</div>)}
				</div></div>):(<div className="row" style={{'width':'100%'}}> </div>)}</div></div>);
    }
}

class EntityModal extends Component
{
    data={
		id: 0,
		name_user:'',
		description: '',
		importance: 2,
		status: 2,
		category: this.props.param.category,
		deadline: getCurrDay(),
		date_time: '',

		};
	
	bg_color_priority = ["#9FCDFF", "#BEE5EB", "#D6D8DB", "#FFE8A1", "#F5C6CB"];

    constructor(props)
    {
        super(props)

        this.state={
			isLoading:false,
			isComLoad:false,
			data:this.data,
			comments:{},
			commentar:'',
			count:'',
			categories:[],
			...this.props.Com,
			priority_color: 0,

		};
		this.AddMessages = this.AddMessages.bind(this);
		this.AddCommentar = this.AddCommentar.bind(this);
		this.LoadComments = this.LoadComments.bind(this);
		this.state.data.id=this.props.param.id;
		this.state.data.name=this.props.param.name_user;

		this.swalBehavior = [
			{
				title: 'Вы хотите удалить задачу?',
				icon: 'warning',
				confirmButtonColor: true,
				showCancelButton: true,
				confirmButtonText: 'Да',
				cancelButtonText: 'Отмена',
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33'
			},
			{
				icon: 'success',
				title: 'Задача удалена',
				showConfirmButton: false,
				timer: 750
			}
		];

	}

	componentDidMount()
    {
        api("GET","tm","getcat",{i:0})
        	.then((result)=>
            this.setState({categories:result}));
	}

    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {			api("GET","tm","getcat",{i:0})
        			.then((result)=>{
            this.setState({categories:result});
            	api("GET", "tm", "getone",{id:this.props.param.id})
				.then(result=>{

				this.setState({data:{...result[0]},comments:[...result[1]], count:[...result[2]],  isLoading:true, isComLoad:true});

				// Conversion date to [day-month-year hours-minutes]
				let data = this.state.data;
				let date = data.date_time;
				date = date.substring(8, 10) + "-" +  date.substring(5, 7) + "-" +  date.substring(0, 4) + " " + date.substring(11, 16);
				data.date_time = date;
				this.setState({data:data});

				// Conversion priority data to background header gradient color
				let color = result[0].importance;
				color = this.bg_color_priority[color-1];
				this.setState({priority_color: color});
			})})
		}
	}

	AddCommentar(e)
	{
		e.preventDefault();
		this.setState({isComLoad:false});
		api("GET","tm","addcom",{idp:this.props.param.id,comment:this.state.commentar, token:store.User.token})
				.then((result)=>{
                    if(result.Result==='done')
                    {
						this.LoadComments();
						this.setState({commentar:''});
                    }
                    else
                        console.log(result);                        
                })
	}
	
	LoadComments()
	{
		this.setState({isComLoad:false});
		api("GET", "tm", "getone",{id:this.props.param.id})
			.then(result=>{
				this.setState({comments:[...result[1]], count:[...result[2]], isLoading:true, isComLoad:true});
			})
	}

    Save = () =>
    {
        api("GET", "tm", "edit",{id:this.state.data.id?this.state.data.id:0,
            ...this.state.data, token:store.User.token})
            .then(()=>{
				this.props.LoadMessages(0,this.state.data.category, 'date_last_update');
                this.Close();
            });
    }

    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data,  isComLoad:false, isLoading:false, comment:[]});
		let x; // объявляем undefined переменную, чтобы "обнулить" категорию до первоначального своего состояния
		this.data.category=x; // превращаем категорию в undefined
    }

    Delete = () =>
    {

		api("GET","tm","del",{id:this.state.data.id})
                .then((result)=>{
                    this.props.LoadMessages(0,0,'date_last_update');
                    this.Close()
                    if(result.Result==='done')
                    {
						console.log("Строка №:"+this.state.data.id);
                    }
                    else
						console.log("Строка №:"+this.state.data.id);
                        console.log(result);                        
                });
    }
	
	AddMessages()
	{	console.log(this.data);
		console.log(this.state.data);
		api("GET","tm","add",{...this.state.data, token:store.User.token})
                .then((result)=>{
                    if(result.Result==='ok')
                    {
						this.setState({description:''});
						this.props.LoadMessages(0,this.state.data.category, 'date_last_update');
						this.Close();
                    }
                    else
                        console.log(result);                        
                });
    }

    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

	changePrioritySelector = (e) => {
		this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

		if     (e.target.value === "1") e.target.style.backgroundColor = this.bg_color_priority[0];
		else if(e.target.value === "2") e.target.style.backgroundColor = this.bg_color_priority[1];
		else if(e.target.value === "3") e.target.style.backgroundColor = this.bg_color_priority[2];
		else if(e.target.value === "4") e.target.style.backgroundColor = this.bg_color_priority[3];
		else if(e.target.value === "5") e.target.style.backgroundColor = this.bg_color_priority[4];
	}

    render()
    {
		const {data, comments, isComLoad, categories} = this.state;
		// console.log(typeof data.category);
		if (!this.props.param.id & typeof data.category==='undefined') data.category=this.props.param.category;
        return(
		<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered>
              <Form>
                <Modal.Header style={{background:`linear-gradient(${this.state.priority_color} 20%, #FFFFFF)`, display:"flex", paddingBottom:"8px"}}>
                    <Modal.Title> {this.props.param.id?("Редактировать"):("Добавить")}
						<Form.Group style={{"margin-top":"0.5rem"}}>	
								<select className="form-control" name="category" value={data.category} onChange={this.changeValue}>
									{categories.map((category, key) =>
									<option key={key+1} value={key+1}>{category}</option>)}
								</select>
							</Form.Group>
					
					</Modal.Title>

					<Form.Group style={{display:"flex", flexDirection:"column", width:"35%", margin:"0"}}>	
						<div style={{height:"30%", textAlign:"right", fontWeight:"bold", fontSize:"1.4rem"}}>
							<Link style={{fontWeight:"bold"}} to={"/user/"+data.id_user}>{this.props.param.name_user}</Link>
						</div>

						<div style={{textAlign:"right", fontWeight:"bold", marginBottom:"18px"}}>{this.state.data.date_time}</div>

						{this.props.param.id && (
						<div style={{"padding-left":"74%"}}> 
							<Button style={{height:"20%"}} size="sm" variant="danger" type="submit" onClick={(e)=>{e.preventDefault();
								Swal.fire(this.swalBehavior[0]).then(
									(result) => {
										if (result.isConfirmed) {
											Swal.fire(this.swalBehavior[1])
											this.Delete()
										}
										else {
											e.preventDefault()
										}
								})
							}}>Удалить
						</Button>
					</div>)}
				</Form.Group>
                </Modal.Header>
				
                <Modal.Body>
					<Form.Group style = {{"width":"100%", display:"flex", "margin":0}} controlId="NameControlGroup">
						<Form.Group style = {{width: "33%"}}>
							<Form.Label>Приоритет</Form.Label>
							<select className="form-control" name="importance" style={{"background-color": `${this.state.priority_color}`}} value={data.importance} onChange={this.changePrioritySelector}>
								<option value="1" style={{"background-color":this.bg_color_priority[0]}}>Низкий</option>
								<option value="2" style={{"background-color":this.bg_color_priority[1]}}>Обычный</option>
								<option value="3" style={{"background-color":this.bg_color_priority[2]}}>Средний</option>
								<option value="4" style={{"background-color":this.bg_color_priority[3]}}>Повышенный</option>
								<option value="5" style={{"background-color":this.bg_color_priority[4]}}>Высокий</option>
							</select>
						</Form.Group>
						
						<Form.Group style = {{width: "33%"}}>
							<Form.Label>Статус</Form.Label>
							<select className="form-control" name="status" value={data.status} onChange={this.changeValue}>
								<option value="1">Отложенное</option>
								<option value="2">Активное</option>
								<option value="3">В работе</option>
								<option value="4">Завершено</option>
							</select>
						</Form.Group>

						<Form.Group style = {{width: "33%"}}>
							<Form.Label>Дата дедлайна</Form.Label>
							<input type="date" class="form-control" name="deadline" onChange={this.changeValue} value={data.deadline} />
						</Form.Group>

					</Form.Group>

					<Form.Group controlId="NameControlGroup" style={{margin:"0"}}>
                        <Form.Label>Описание</Form.Label>
						<Areatext
						initval={this.state.data.description} stylePicker="1"
						onChangeVal = {(e) => {
							this.data = this.state.data;
							this.data.description = e.target.value;
							this.setState({data:{...this.data}});
							}}
						/>
                    </Form.Group>

				<Modal.Footer style={{padding:"0", paddingTop:"12px"}}>
					{this.props.param.id?(<Button onClick={(e)=>{this.state.commentar && this.AddCommentar(e); this.Save()}}>Сохранить</Button>):
					(<Button onClick={this.AddMessages}>Добавить</Button>) }
						<Button onClick={this.Close} style={{marginRight:"0"}}>Отмена</Button>
                </Modal.Footer>

					{this.props.param.id && <Form.Group controlId="NameControlGroup">
					{isComLoad?(<div>

        			<h3 className="title-comments">Комментарии ({this.state.count})
					</h3>
					<textarea className="form-control" style={{'resize':'none'}} name="commentar" size="sm" placeholder="Комментарий"
                            onChange={(e)=>{this.setState({commentar:e.target.value})}}>
					</textarea>
					<div style={{display:"flex", flexDirection:"row-reverse"}}>
						<Button className="mt-1" size="sm" type="submit"
								onClick={(e)=>{this.state.commentar?(this.AddCommentar(e)):(this.LoadComments())
							}}>Добавить
						</Button>
					</div>

				    {comments.map((Row, key)=> <Comments key={key} Row={Row}
					LoadComments={this.LoadComments}/>)}
					</div>):(<Spinner animation="border"/>)}
					</Form.Group>}
                </Modal.Body>
                
              </Form>
          </Modal>);

    }
}

class Comments extends Component{
	constructor(props)
	{
		super(props);
		this.state={...this.props.Row,
		isFocus: false};
		this.editRow = this.editRow.bind(this);

	}
	
	editRow(e)
    {
		e.preventDefault();
		api("GET","tm","editcom",{...this.state})
                .then((result)=>{
                    if(result.Result==='done')
                    {
						this.props.LoadComments();
                    }
                    else
                        console.log(result);                        
                });
    }
	
	deleteRow(e)
    {
		e.preventDefault();
		api("GET","tm","delcom",{...this.state})
                .then((result)=>{
                    if(result.Result==='done')
                    {
						this.props.LoadComments();
                    }
                    else
                        console.log(result);                        
                });
    }

	render()
	{
		return(
               <div className="container">
				<div className="card mt-2 mr-1">
	    			<div className="card-body" style={{padding:"5px 15px"}}>
	        			<div>
							<div style={{width:'50%', float:'left', textAlign:'left', fontWeight:'bold'}}>
								<Link to={"/user/"+this.state.autor}>{this.state.name_user}</Link></div>
								<div style={{textAlign:'right', fontWeight:'bold'}}>{this.state.date_time}</div>
							</div>

							<div>
								<textarea style={{'border':'none'}} rows='3' className="cursor-edit card-textarea ml-2" name="comment" value={this.state.comment}
								onChange={(e)=>{this.setState({comment:e.target.value, isFocus:true})}}>{this.state.comment}</textarea>
							</div>

							<div className='text-right mt-1' style={{position:"absolute", zIndex:"10", top:"68px", right:"9px"}}>
									{this.state.isFocus?
										(<Button size="sm" variant="info" type="submit" onClick={(e)=>{this.editRow(e)}}>Изменить</Button>):
										(<Button size="sm" variant="danger" type="submit" onClick={(e)=>{e.preventDefault();
										Swal.fire({
											title: 'Вы хотите удалить коментарий?',
											icon: 'warning',
											confirmButtonColor: true,
											showCancelButton: true,
											confirmButtonText: 'Да',
											cancelButtonText: 'Отмена',
											confirmButtonColor: '#3085d6',
											cancelButtonColor: '#d33',
										}).then((result) => {
											if (result.isConfirmed) {
												Swal.fire({
													icon: 'success',
													title: 'Комментарий удалён',
													showConfirmButton: false,
													timer: 750
												})
												this.deleteRow(e)
											}
											else {
												e.preventDefault()
											}
										})
								}}>X</Button>)}
							</div>

	   					</div>
					</div>
				</div>);
	}
}

class AddCategoryModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={description:'', id:0, isLoading:false};
		this.Save = this.Save.bind(this);
		this.Add = this.Add.bind(this);
        this.Close = this.Close.bind(this);
	}

	componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "tm", "get-category-by-id",{id:this.props.param.id})
            .then(result=>this.setState({description:result.description, id:this.props.param.id, isLoading:true}))
        }
    }
	
    Save()
    {
        api("GET", "tm", "editcat",{id:this.props.param.id, des:this.state.description})
			.then(()=>{this.Close();
				this.props.LoadMessages(0,0, 'date_last_update')});
	}
	
	Add()
    {
        api("GET", "tm", "addcat",{des:this.state.description})
			.then(()=>{this.Close();
				this.props.LoadMessages(0,0, 'date_last_update')});
    }

    Close()
    {
		this.props.onHide();
		this.setState({description:'', id:0, isLoading:false});
    }

    render()
    {
        return(
			<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          	>
              <Form>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.param.id?'Редактировать категорию':'Создать категорию'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Введите название категории</Form.Label>
                <Form.Control type="text" name="description" size="sm"
                            value={this.state.description}
                            onChange={(e)=>{this.setState({description:e.target.value})}}/>
                            </Form.Group>
            </Modal.Body>
            <Modal.Footer>
			{this.props.param.id?
				(<Button variant="primary" type="submit" onClick={(e)=>{
                    e.preventDefault();
                    this.Save();
                    }}>Сохранить</Button>):
                (<Button variant="primary" type="submit" onClick={(e)=>{
                    e.preventDefault();
                    this.Add();
                    }}>Добавить</Button>)
            }
              <Button onClick={this.Close}>Отмена</Button>
            </Modal.Footer>
            </Form>
          </Modal>  
        );
    }
}

export default TaskManager;