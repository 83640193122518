import api from '../Api'

export interface readController<I, O>{
    read(input: I): Promise<O>
}

type autoIncrementID = number | string       
type ID = number | string


// TODO Обощить на темплейт оператор T
interface CRUD {
    read(...args: any[]): Promise<any[]>,                                                                          
    add(...args: any[]): Promise<void>,
    update(...args: any[]): Promise<void>,
    delete(...args: any[]): Promise<void>
}

type id = number | string

interface CRUDEnum {
    read()                              : Promise<{id: number, description: string}[]>,                                                                          
    add(id: id, description: string)    : Promise<void>,
    update(id: id, description: string) : Promise<void>,
    delete(id: id)                      : Promise<void>,

    /** Нужен для адаптера */
    columnsNames: {
        id: string,
        description: string,
    }
}


/*
        EnumPaymentStatus
    C: http://erp-back-yii.ru/web/finance/add-enum-payment-status?description=Hello
    R: http://erp-back-yii.ru/web/finance/read-enum-payment-status
    U: http://erp-back-yii.ru/web/finance/update-enum-payment-status?description='test'
        &id_status=1
    D: http://erp-back-yii.ru/web/finance/delete-enum-payment-status?id_status=1
*/

export const controllerEnumPaymentStatus: CRUDEnum = {
    columnsNames: {
        id: 'id_status',
        description: 'description'
    },

    async read(){
        let res = await api('GET', 'finance', 'read-enum-payment-status')
        
        res = res.map((obj: any) => {
            const id_key = this.columnsNames.id
            const description_key = this.columnsNames.description

            const map_obj = {
                id: obj[id_key],
                description: obj[description_key]
            }
            return map_obj
        })
            
        return res
    },


    async delete(id_status){
        await api('GET', 'finance', 'delete-enum-payment-status', {id_status: id_status})
    },

    async add(description){
        await  api('GET', 'finance', 'add-enum-payment-status', {description: description})
        
    },
    
    async update(id_status, description){
        await api('GET', 'finance', 'update-enum-payment-status', {id_status: id_status, description: description})
    }
}


// http://erp-back-yii.ru/web/finances/periodic-payment/create?periodic=abcde
// http://erp-back-yii.ru/web/finances/periodic-payment/update?id_periodic=2&periodic=UPDATE
// http://erp-back-yii.ru/web/finances/periodic-payment/delete?id_periodic=4

export const selectControllerEnumPeriodicPayment: readController<void, {id: any, name: any}[]> = {
    async read(){
        let res = await api('GET', 'finances/enum-periodic-payment', 'read')
        
        res = res.map((val: any) => {
            return({
                id: val.id_periodic,
                name: val.periodic
            })
        })
        return res
    }
}

export const controllerFinanceEnumPeriodicPayment: CRUDEnum = {
    columnsNames: {
        id: 'id_periodic',
        description: 'periodic'
    },

    
    async read(){
        let res = await api('GET', 'finances/enum-periodic-payment', 'read')
        res = res.map((obj: any) => 
            ({
                id: obj[this.columnsNames.id],
                description: obj[this.columnsNames.description]
            })
        )

        return res
    },

    async delete(id: id): Promise<void>{
        const params = {
            [this.columnsNames.id]: id
        }
        await api('GET', 'finances/enum-periodic-payment', 'delete', params)
    },

    async add(description: string): Promise<void>{
        const params = {
            [this.columnsNames.description]: description
        }
        await  api('GET', 'finances/enum-periodic-payment', 'create', params)
    },
    
    async update(id: autoIncrementID, description: string): Promise<void>{
        const params = {
            [this.columnsNames.id]: id,
            [this.columnsNames.description]: description
        }
        await api('GET', 'finances/enum-periodic-payment', 'update', params)
    }

}



export const ControllerTest = {
    /** Проверяет является ли id autoincrement */
    autoInrecment: async(
        controller: CRUD,
        addKeys: any[],
        indKey: string
        )
        :Promise<boolean> => {

            const res_1 = await controller.read()
            await controller.add(...addKeys)
            const res_2 = await controller.read()

            const ind_1 = res_1[res_1.length-1][indKey]
            const ind_2 = res_2[res_2.length-1][indKey]
            
            await controller.delete(ind_2)
            return Number(ind_2) === Number(ind_1) + 1
        },

    
    
}


type PeriodicPaymentFields = {
    description: string,
    amount: number | string,
    date: string,
    id_periodic: id
}   

interface CRUDPeriodicPayment{
    read(): Promise<Array<id | PeriodicPaymentFields>>,
    add(fields: PeriodicPaymentFields): Promise<void>,
    update(id: id, fields: PeriodicPaymentFields): Promise<void>,
    delete(id: id) : Promise<void>
}


export const controllerPeriodicPayment: CRUDPeriodicPayment = {
    async add(fields){
        await api('GET', 'finances/periodic-payment', 'create', fields)
    },

    // http://erp-back-yii.ru/web/counterparties/get-counterparty-by-id?id=200
    async read() {
        let res = await api('GET', 'finances/periodic-payment', 'read')
        return res
    },

    async delete(id: id): Promise<void>{
        await api('GET', 'finances/periodic-payment', 'delete', {id: id})
    },

    async update(id: id, fields: PeriodicPaymentFields): Promise<void>{
        const param = {
            id: id,
            ...fields
        }

        await api('GET', 'finances/periodic-payment', 'update', param)
    }
}


interface PaymentFields{
    amount: number,
    id_counterparty: number,
    id_periodic_payment: number,
    description: string,
    date: Date,
    id_user: number,
    id_status: number,
    url: string,
    priority: number
}

interface StatusEnum{
    status: string
}

interface PeriodicEnum{
    periodic_payment: string
}

interface PaymentRead extends PaymentFields, StatusEnum, PeriodicEnum{
    id: number
}

interface CRUDPayment{
    read(): Promise<PaymentRead[]>,
    add(fields: PaymentFields): Promise<void>,
    update(id: number, fields: PaymentFields): Promise<void>,
    delete(id: number) : Promise<void>
}

export const controllerPayment: CRUDPayment = {
    async add(params){
        await api('GET', 'finances/payment', 'create', params)
    },

    async delete(id){
        await api('GET', 'finances/payment', 'delete', {id: id})
    },

    async read(){
        return api('GET', 'finances/payment', 'read')
    },

    async update(id, fields){
        const param = {
            id: id,
            ...fields
        }
        await api('GET', 'finances/payment', 'update', param)
    },

    
}

/** Интерфейс для SelectionTD */
// export interface ISelectController{
//     Promise<{id: string, name: string}[]>
// }

export type ISelectController = Promise<{id: string, name: string}[]>

export const FinanceController = {
    async statusPaymentSelect(): ISelectController{
        return api('GET', 'finances/select', 'status')
    },

    async userSelect(): ISelectController{
        return api('GET', 'finances/select', 'user')
    },

        // http://erp-back-yii.ru/web/finances/select/periodic

    // Периодичность платежа
    async enumPeriodicSelect(): ISelectController{
        return api('GET', 'finances/select', 'enum-periodic')
    },
    
    // http://erp-back-yii.ru/web/finances/select/periodic-payment
    async periodicPaymentSelect(): ISelectController{
        return api('GET', 'finances/select', 'periodic-payment')
    }

    
}