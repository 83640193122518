import React, {Component, useState} from 'react';
import { Container, Spinner, Row, Col, Button, Modal, Table, Form, Tabs, Tab } from 'react-bootstrap';
import api from '../Api'
import BootstrapTable from 'react-bootstrap-table-next';
import TreeView from 'treeview-react-bootstrap';
import Swal from 'sweetalert2';
import './glyphicon.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BidModal from '../Components/BidModal.js'

class Repository extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            level:0,
            store:[],
            products:[],
            nomenculature:[],
            bids:[],
            operations:[],
            storeFilter:{},
            prodsFilter:{},
            bidsFilter:{},
            opsFilter:{},
            nCols:[{
                    dataField: 'id',
                    text: 'ID'
                    }, {
                    dataField: 'name',
                    text: 'Наименование'
                    }, {
                    dataField: 'barcode',
                    text: 'Код'
                    }, {
                    dataField: 'price',
                    text: 'Стоимость'
                    }],
            bCols:[{
                    dataField: 'id',
                    text: 'ID'
                    }, {
                    dataField: 'created_at',
                    text: 'Создана'
                    }, {
                    dataField: 'updated_at',
                    text: 'Изменена'
                    }, {
                    dataField: 'nomenculature',
                    text: 'Номенкулатура'
                    }, {
                    dataField: 'status',
                    text: 'Статус'
                    }],
            oCols:[{
                    dataField: 'id',
                    text: 'ID'
                    },{
                    dataField: 'created_at',
                    text: 'Дата'
                    }, {
                    dataField: 'nomenculature',
                    text: 'Наименование'
                    }, {
                    dataField: 'store',
                    text: 'Склад'
                    }, {
                    dataField: 'quantity',
                    text: 'Количество'
                    }]
        };

        this.updateState = this.updateState.bind(this);
        this.LoadStore = this.LoadStore.bind(this);
        this.LoadState = this.LoadState.bind(this);
        this.LoadNomenculature = this.LoadNomenculature.bind(this);
        this.LoadBids = this.LoadBids.bind(this);
        this.LoadOperations = this.LoadOperations.bind(this);
        this.addDetail = this.addDetail.bind(this);
        this.addBid = this.addBid.bind(this);
//         this.showBidModal = this.showBidModal.bind(this);
    }

    componentDidMount()
    {
        this.LoadStore(this.state.level)
        this.updateState()
    }

    showBidModal () {
//         BidModal.handleShow;
    }

    updateState(model=''){
        switch(model){
            case 'Operations':
                this.LoadOperations()
                break;
            case 'Nomenculature':
                this.LoadNomenculature()
                break;
            case 'Bids':
                this.LoadBids()
                break;
            case 'Store':
                this.LoadState()
                break;
            default:
                this.LoadState()
                this.LoadNomenculature()
                this.LoadBids()
                this.LoadOperations()
        }
    }

    LoadStore(level = 0){
        api('GET','store','hierarchy',{level:level}).then(r =>r.success? this.setState({store:r.result}):console.log(r))
    }
    LoadState(){
        api('POST','store','state',this.state.storeFilter).then(r => r.success?this.setState({products:r.result}):console.log(r))
    }
    LoadNomenculature(){
        api('POST','store','find?model=Nomenculature',this.state.prodsFilter).then(r => r.success?this.setState({nomenculature:r.result}):console.log(r))
    }
    LoadBids(){
        api('POST','store','find?model=Bids',this.state.bidsFilter).then(r => r.success?this.setState({bids:r.result}):console.log(r))
    }
    LoadOperations(){
        api('POST','store','find?model=Operations',this.state.opsFilter).then(r => r.success?this.setState({operations:r.result}):console.log(r))
    }

    addNewObject(object,model){
        api('POST','store','newobject?model='+model, object).then(r => {
            if(r.success){
                this.updateState(model)
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка сохранения',
                    text: 'При выполнении операци произошла ошибка: '+r.error,
                    });
            }
        })
    }

    addDetail(){
    Swal.fire({
        title: 'Добавить наименование',
        html:
            '<form id="addNumenculature">' +
            '<label> Наименование' +
            '<input id="addformInputName" name="name" class="swal2-input">' +
            '</label>' +
            '<label> Артикул|Штрих-код' +
            '<input id="addformInputBarcode" name="barcode" class="swal2-input">' +
            '</label>' +
            '<label> Производитель' +
            '<input id="addformInputVendor" name="vendor" class="swal2-input">' +
            '</label>' +
            '<label> Стоимость' +
            '<input id="addformInputPrice" name="price" class="swal2-input">' +
            '</label>' +
            '<label> Аттрибуты' +
            '<div class="numenculatureAttrs"><input onkeyup="this.nextElementSibling.setAttribute(\'name\',this.value)" placeholder="name"><input placeholder="value"><button onClick="p = this.parentNode;p.parentNode.removeChild(p);return false;"> - </button></div>' +
            '<button onClick="p=this.parentNode;p.insertBefore(p.firstElementChild.cloneNode(true),this);return false">Еще</button>' +
            '</label></form>',
        focusConfirm: false,
        preConfirm: () => {
            let n = document.getElementById('addformInputName')
            if (n.value.length>2){
                n.classList.remove('errored')
                let els = document.getElementById('addNumenculature').querySelectorAll('input[name]')
                let obs = {details:{}}
                for(var i=0;i<els.length;i++){
                    if(els[i].placeholder=='value'){
                        obs.details[els[i].getAttribute("name")]=els[i].value
                    }else{
                        obs[els[i].getAttribute("name")] = els[i].value;
                    }
                }
                return obs;
            }else{
                if(!n.classList.contains('errored')) n.classList.add('errored');
              return false
            }
        }
        }).then(r=>{
            this.addNewObject(r.value,'Nomenculature')
        })
    }

    addBid(){
    Swal.fire({
        title: 'Создать заявку',
        html:
            '<form id="addBid">' +
            '<label> Тип' +
            '<select onchange="let v=this.value;let vals = [\'._inset\',\'._outset\',\'._move\']; let e; for(var i=0;i<vals.length;i++){e = document.querySelector(vals[i]);if(e.offsetParent !== null){e.style.display=\'none\'}}document.querySelector(\'._\'+v).style.display=\'block\'">' +
                '<option value="inset">Поступление</option>' +
                '<option value="outset">Списание</option>' +
                '<option value="move">Перемещение</option>' +
            '</select>' +
            '</label>' +
            '<label class="_inset"> Номенкулатура' +
            '<div class="numenculatureList"><input placeholder="наименование"><input placeholder="склад"><input placeholder="количество"><button onClick="p = this.parentNode;p.parentNode.removeChild(p);return false;"> - </button></div>' +
            '<button onClick="p=this.parentNode;p.insertBefore(p.firstElementChild.cloneNode(true),this);return false">Еще</button>' +
            '</label>' +
            '<label class="_outset"> Номенкулатура' +
            '<div class="numenculatureList"><input placeholder="наименование"><input placeholder="склад"><input placeholder="количество"><button onClick="p = this.parentNode;p.parentNode.removeChild(p);return false;"> - </button></div>' +
            '<button onClick="p=this.parentNode;p.insertBefore(p.firstElementChild.cloneNode(true),this);return false">Еще</button>' +
            '</label>' +
            '<label class="_move"> Номенкулатура' +
            '<div class="numenculatureList"><input placeholder="наименование"><input placeholder="откуда"><input placeholder="куда"><input placeholder="количество"><button onClick="p = this.parentNode;p.parentNode.removeChild(p);return false;"> - </button></div>' +
            '<button onClick="p=this.parentNode;p.insertBefore(p.firstElementChild.cloneNode(true),this);return false">Еще</button>' +
            '</label>' +
            '</form>',
        focusConfirm: false,
        preConfirm: () => {
            let type = document.querySelector('#addBid select').value
            let entity, inputs, obj={nomenculature:[],details:{type:''}}, nom;
            switch(type){
                case "inset":
                    entity = document.querySelectorAll('#addBid label._inset div')
                    break;
                case "outset":
                    entity = document.querySelectorAll('#addBid label._outset div')
                    break;
                case "move":
                    entity = document.querySelectorAll('#addBid label._move div')
                    break;
            }
            obj.details.type = type
            for(let i=0;i<entity.length;i++){
                inputs = entity[i].querySelectorAll('input')
                nom = {};
                inputs.forEach((el)=>{
                    switch(el.placeholder){
                        case "наименование":
                            nom.nomenculature = el.value
                            break;
                        case "склад":
                            nom.store = el.value
                            break;
                        case "отуда":
                            nom.from = el.value
                            break;
                        case "куда":
                            nom.to = el.value
                            break;
                        case "количество":
                            nom.quantity = el.value
                            break;
                    }
                })
                obj.nomenculature[i] = nom
            }
            return obj;
        }
        }).then(r=>{
            this.addNewObject(r.value,'Bids')
        })
    }

    render()
    {

        const {store,products,nomenculature,bids,operations,nCols,bCols,oCols} = this.state;
//         console.log(store);
        return(
            <>
                <Tabs defaultActiveKey="state" id="storeParts" className="mb-3">
                    <Tab eventKey="state" title="Хранилище">
                        <StoreState tree={store} products={products}/>
                    </Tab>
                    <Tab eventKey="nomenculature" title="Номенкулатура">
                        <div className="text-right"><Button size="md" onClick={this.addDetail}>Добавить</Button></div>
                        <BootstrapTable keyField='id' data={ nomenculature } columns={ nCols } noDataIndication={ () => <NoDataIndication /> }/>
                    </Tab>
                    <Tab eventKey="bids" title="Заявки">
                        <div className="text-right"><Button size="md" onClick={this.showBidModal}>Добавить</Button></div>
                        <BootstrapTable keyField='id' data={ bids } columns={ bCols } noDataIndication={ () => <NoDataIndication /> }/>
                    </Tab>
                    <Tab eventKey="operations" title="Движения">
                        <BootstrapTable keyField='id' data={ operations } columns={ oCols } noDataIndication={ () => <NoDataIndication /> }/>
                    </Tab>
                </Tabs>
                <BidModal show={false}/>
            </>
            )
    }
};

const StoreState = ({tree, products}) =>
{

const columns = [{
  dataField: 'id',
  text: 'ID'
}, {
  dataField: 'name',
  text: 'Наименование'
}, {
  dataField: 'barcode',
  text: 'Код'
}, {
  dataField: 'quantity',
  text: 'Количество'
}];
        return(
            <Row>
                <Col md={4}>
                    <TreeView color='#005000' enableLinks={true} showBorder={false} showTags={true} selectable={false} data={tree} allowNew={true} removable={true} />
                </Col>
                <Col md={8}>
                    <BootstrapTable keyField='id' data={ products } columns={ columns } noDataIndication={ () => <NoDataIndication /> }/>
                </Col>
            </Row>

            )
};

const NoDataIndication = () => (
  <div>
  No result found
  </div>
);

export default Repository;
