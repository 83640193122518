import React, {Component, useState} from 'react';
import { Container, Tab, Tabs, Table, Spinner, Button, Modal, Form, Nav } from 'react-bootstrap';
import {Link} from "react-router-dom";
import Select from './../Components/SelectDictionary';
import Calendar from './../Components/Calendar/Calendar';
import { LinkContainer } from 'react-router-bootstrap';
import api from '../Api';
import Swal from 'sweetalert2';
import store from '../Store';

class Ord extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {isLoad:false,
        Entities:{},
        filter:'all',
        OrdModalParam:{show:false}};
        
    }
    LoadData = () =>{
        this.setState({isLoad:false});
        api("GET", "orders", "get-orders", {filter:this.state.filter, token: store.User.token})
        .then(Result=>{this.setState({Entities:Result, isLoad:true})});
        console.log(this.state.Entities)
    }
    componentDidMount()
    {
        this.LoadData();
    }

    render()
    {
        const {isLoad, Entities, OrdModalParam} = this.state;
        // console.log(this.state.Entities);
        return(<div>
            <OrdModal show={OrdModalParam.show}
                onHide={()=>{
                    this.setState({OrdModalParam:{show:false}})
                }}
                LoadData={this.LoadData}
                    param={OrdModalParam}
            />
            <Form>
                <Form.Check type='switch' name="filter" id="orders-switch" label="Отображать только мои заказы"
                        	onClick={(e)=>{
                                e.target.value = e.target.checked ? 'one' : 'all';
                                this.setState({filter: e.target.value}, () => {this.LoadData()});
                                }}/>
                </Form>
            {isLoad?(<Table striped bordered hover size="sm">
            <thead>
                <tr> 
                    <th>Поставщик</th>
                    <th>Заказчик</th>
                    <th>Тип заказа</th>
                    <th>Аппарат</th>
                    <th>Комплектация</th>
                    <th>Дата создания</th>
                    <th>Сумма договора</th>
                    <th>Планируемая дата отгрузки</th>
                    <th>Оплачено</th>
                    <th>Статус готовности</th>
                    <th>Дата отправки</th>
                    <th style={{width:'30px'}}>
                        <Button size="sm"
                        onClick={()=>{this.setState({OrdModalParam:{show:true}})}}>
                            Добавить
                        </Button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {Entities.map((Row, key)=>{return(<tr key={key}>
                <td>{Row.org_name_provider}</td>
                <td>{Row.org_name_customer}</td>
                <td>{Row.order_type}</td>
                <td>
                {Row.device.map((dev, key) =>
                    <tr>{dev.name}</tr>)}
                </td>
                <td>
                {Row.equipment.map((eq, key) =>
                    <tr>{eq.name}</tr>)}
                </td>
                <td>{Row.date_start}</td>
                <td>{Row.summ}</td>
                <td>{Row.date_plan}</td>
                <td>{Row.paid}</td>
                <td>{Row.status}</td>
                <td>{Row.date_fact}</td>
                <td>
                    <Button size="sm"
                        onClick={()=>{this.setState({OrdModalParam:{show:true,
                            id:Row.id, author:Row.author}})}}>
                        Редактировать
                    </Button>
                </td>
                </tr>)})}
            </tbody>
        </Table>):(<Spinner animation="border"/>)}</div>);
    }
};

class OrdModal extends Component
{
    data={
        id:this.props.param.id,
        org_name_provider:'',
        org_name_customer:'',
        city:'',
        order_type:'',
        device:'',
        number:0,
        equipment:'',
        summ:0,
        date_start:'',
        date_plan:'',
        paid:0,
        contact:'',
        telephone:'',
        status:'',
        date_fact:'',
        document:'',
        final_document:'',
        notes:'',
        same:'',
        name:'',
        author:'',
        products:[],
        complectation:[]
        };
    constructor(props)
    {
        super(props)

        this.state={
            isLoading:false,
            data:this.data,
            comdata:[],
            prods:[],
            complects:[],
        };
        this.AddData = this.AddData.bind(this);
        this.AddPosition = this.AddPosition.bind(this);
        this.AddComplect = this.AddComplect.bind(this);

        this.swalBehavior = [
			{
				title: 'Вы хотите удалить заказ?',
				icon: 'warning',
				confirmButtonColor: true,
				showCancelButton: true,
				confirmButtonText: 'Да',
				cancelButtonText: 'Отмена',
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33'
			},
			{
				icon: 'success',
				title: 'Заказ удалён',
				showConfirmButton: false,
				timer: 750
			}
		];
    }

    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "orders", "get-orders-by-id",{id:this.props.param.id})
            .then(result=>this.setState({data:result[0], prods:result[1], complects:result[2], comdata:result[3], isLoading:true}, () => console.log(this.state.data)))
            
        }
        
    }

    providerChange(e) {

        this.setState({data:{...this.state.data, org_name_provider:e.target.value}});
    }

    customerChange(e) {
        
        var Attrbt1 = [];
        Attrbt1.location = e.target.attributes['location'];
        var Attrbt2 = [];
        Attrbt2.telephone = e.target.attributes['telephone'];
        this.setState({data:{...this.state.data, org_name_customer:e.target.value, city:Attrbt1, telephone:Attrbt2}}, ()=> console.log(this.state.data));
        // this.state.data.city.location = e.target.attributes['location'];
        // this.state.data.telephone.telephone = e.target.attributes['telephone'];
    }

    productChange(e) {
        // console.log(e.target.value)
        this.setState({products:e.target.value});
    }

    complectChange(e) {
        // console.log(e.target.value)
        this.setState({complectation:e.target.value});
    }

    Save = () =>
    {
        api("GET", "orders", "set-orders-data",{id:this.state.data.id?this.state.data.id:0,
            data:this.state.data, token:store.User.token})
            .then(()=>{
				this.props.LoadData(0,this.state.data.id_counterparty);
                this.Close();
            });
    }

    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data, isLoading:false});
    }

    Delete = () =>
    {
        api("GET", "orders", "delete-orders",{id:this.state.data.id})
           .then(()=>{
            this.props.LoadData();   
            this.Close()
        });   
    }

    AddData()
	{
        if (this.state.data.org_name_customer=="" || this.state.data.org_name_provider=="")
        {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Заполните поля с контрагентами',
                showConfirmButton: false,
                timer: 2000
              })
        }
        else
		api("GET","orders","add-orders-data",{data:this.state.data, token:store.User.token})
        .then(()=>{
            this.props.LoadData();   
            this.Close()
        });
    }

    AddPosition()
    {
        let prod = this.state.data.products
        if (this.state.data.number<0 || this.state.data.number==0 || this.state.data.number=="" || this.state.data.unit=="")
        {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Проверьте правильность заполнения данных',
                showConfirmButton: false,
                timer: 2000
              })
        }
        else 
        prod.push({unit:this.state.data.unit, number:this.state.data.number})
        this.setState({data:{...this.state.data, products:prod}})
        // console.log(this.state.data.products)
    }

    AddComplect()
    {
        let complect = this.state.data.complectation
        if (this.state.data.count<0 || this.state.data.count==0 || this.state.data.count=="" || this.state.data.detail=="")
        {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Проверьте правильность заполнения данных',
                showConfirmButton: false,
                timer: 2000
              })
        }
        else 
        complect.push({detail:this.state.data.detail, count:this.state.data.count})
        this.setState({data:{...this.state.data, complectation:complect}})
        // console.log(this.state.data.complectation)
    }

    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

    changeValueProd = (e) => this.setState({data:{...this.state.data, [e.target.name]:{id:e.target.value, label:e.target.label}}});

    changeValueComplect = (e) => this.setState({data:{...this.state.data, [e.target.name]:{id:e.target.value, label:e.target.label}}});
    

    delProd = (e, key) =>
    {
        e.preventDefault();
        let prod = this.state.data.products;
        
        delete prod[key];

        this.setState({products:prod})
    }

    delComplect = (e, key) =>
    {
        e.preventDefault();
        let complect = this.state.data.complectation;
        
        delete complect[key];

        this.setState({complectation:complect})
    }

    render()
    {
        const {data} = this.state;
        return(<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
              <Form>
                <Modal.Header style={{display:"flex", paddingBottom:"8px"}}>
                    <Modal.Title>
                      {this.props.param.id?'Редактировать заказ':'Добавить заказ'}
                      <br/>
                      {this.props.param.id && (
							<Button style={{height:"20%"}} size="sm" variant="danger" type="submit" onClick={(e)=>{e.preventDefault();
								Swal.fire(this.swalBehavior[0]).then(
									(result) => {
										if (result.isConfirmed) {
											Swal.fire(this.swalBehavior[1])
											this.Delete()
										}
										else {
											e.preventDefault()
										}
								})
							}}>Удалить
						</Button>)}
                   </Modal.Title>
                   <Form.Group style={{display:"flex", flexDirection:"column", width:"35%", margin:"0"}}>	
						<div style={{height:"30%", textAlign:"right", fontWeight:"bold", fontSize:"1.4rem"}}>
                        <Link style={{fontWeight:"bold"}} to={"/user/"+data.author}>{this.props.param.author}</Link>
						</div>

						<div style={{textAlign:"right", fontWeight:"bold", marginBottom:"18px"}}>{data.date_start}</div>

						
				</Form.Group>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Организация-поставщик</Form.Label>
                        <Select placeholder={data.same.name}
                                dictionary="counterparties" 
                                name="org_name_provider"
                                onChange={(e)=>{this.providerChange(e)}}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Организация-покупатель</Form.Label>
                        <Select placeholder={data.name.name}
                                dictionary="counterparties" 
                                name="org_name_customer"
                                onChange={(e)=>{this.customerChange(e)}}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Местоположение</Form.Label>
                        <Form.Control type="text" name="city" size="sm" disabled='true'
                            value={data.city.location ? data.city.location : '-'} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Тип заказа</Form.Label>
                        <select className="form-control" name="order_type" value={data.order_type}
                        onChange={this.changeValue}>
						<option selected value="Тендер">Тендер</option>
						<option value="Дилер">Дилер</option>
                        <option value="Розница">Розница</option>
					</select>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Аппарат</Form.Label>
                        <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th width="80%"><Select placeholder="Поиск аппарата"
                                dictionary="apparat" 
                                name="unit"
                                onChange={this.changeValueProd}/></th>
                                <tr width="20%" colspan="2">Серийный номер<Form.Control type="number" name="number" size="sm"
                            value={this.state.data.number} placeholder="Серийный номер" onChange={this.changeValue}/></tr>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.products && this.state.data.products.map((product, key)=>
                            <tr key={key}>
                                <td width="80%">{product.unit.label}</td>
                                <td width="20%">{product.number}</td>
                                <td><button type="button" class="btn btn-danger" onClick={(e)=>this.delProd(e, key)}>X</button></td>
                            </tr>)}
                        </tbody>
                        </table>
                        <table class="table table-bordered">
                        <tbody>
                            {this.state.prods.map((prod, key)=>
                            <tr key={key}>
                                {this.state.data.status=="Отправлен"?
                                (<td width="80%">
                                    <LinkContainer to={"/model/"+prod.unit}><Nav.Link><h5 style={{ color: 'green' }}>{prod.unit}</h5></Nav.Link></LinkContainer>
                                </td>)
                                :
                                (<LinkContainer to={"/model/"+prod.unit}><Nav.Link><h5 style={{ color: 'blue' }}>{prod.unit}</h5></Nav.Link></LinkContainer>)}
                                <td width="20%">{prod.number}</td>
                            </tr>)}
                        </tbody>
                        </table>
                    <Button onClick={this.AddPosition}>Добавить позицию</Button>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Комплектация</Form.Label>
                        <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th width="80%"><Select placeholder="Поиск детали"
                                dictionary="details" 
                                name="detail"
                                onChange={this.changeValueComplect}/></th>
                                <tr width="20%" colspan="2"><Form.Control type="number" name="count" size="sm"
                            value={this.state.data.count} placeholder="Кол-во" onChange={this.changeValue}/></tr>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.complectation && this.state.data.complectation.map((complect, key)=>
                            <tr key={key}>
                                <td width="80%">{complect.detail.label}</td>
                                <td width="20%">{complect.count}</td>
                                <td><button type="button" class="btn btn-danger" onClick={(e)=>this.delComplect(e, key)}>X</button></td>
                            </tr>)}
                        </tbody>
                        </table>
                        <table class="table table-bordered">
                        <tbody>
                            {this.state.complects.map((comp, key)=>
                            <tr key={key}>
                                {this.state.data.status=="Отправлен"?
                                (<td width="80%">
                                    <LinkContainer to={"/model/"+comp.detail}><Nav.Link><h5 style={{ color: 'green' }}>{comp.detail}</h5></Nav.Link></LinkContainer>
                                </td>)
                                :
                                (<LinkContainer to={"/model/"+comp.detail}><Nav.Link><h5 style={{ color: 'blue' }}>{comp.detail}</h5></Nav.Link></LinkContainer>)}
                                <td width="20%">{comp.count} шт.</td>
                            </tr>)}
                        </tbody>
                        </table>
                        <Button onClick={this.AddComplect}>Добавить позицию</Button>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Сумма договора</Form.Label>
                        <Form.Control type="number" name="summ" size="sm"
                            value={data.summ} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Дата создания заказа</Form.Label>

                        {/* <table class="table table-bordered">
                        {this.state.data.date_start}
                        </table> */}

                        <Form.Control type="date" name="date_start" size="sm"
                            value={data.date_start} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Планируемая дата отгрузки</Form.Label>
                        <Form.Control type="date" name="date_plan" size="sm"
                            value={data.date_plan} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Оплачено</Form.Label>
                        <Form.Control type="number" name="paid" size="sm"
                            value={data.paid} onChange={this.changeValue}/>
                    </Form.Group>
                    {/* <Form.Group controlId="NameControlGroup">
                        <Form.Label>Контакт(ФИО)</Form.Label>
                        <Form.Control type="text" name="contact" size="sm"
                            value={data.contact} onChange={this.changeValue}/>
                    </Form.Group> */}
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Телефон</Form.Label>
                        <Form.Control type="text" name="telephone" size="sm" disabled='true'
                            value={data.telephone.telephone ? data.telephone.telephone : '-'} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Статус готовности</Form.Label>
                        <select className="form-control" name="status" value={data.status}
                        onChange={this.changeValue}>
						<option value="Сборка">Сборка</option>
						<option value="Комплектация">Комплектация</option>
                        <option value="Готов">Готов</option>
                        <option value="Отправлен">Отправлен</option>
					</select>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Фактическая дата отгрузки</Form.Label>
                        <Form.Control type="date" name="date_fact" size="sm"
                            value={data.date_fact} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Документооборот</Form.Label>
                        <Form.Control type="text" name="document" size="sm"
                            value={data.document} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Завершающие документы</Form.Label>
                        <Form.Control type="text" name="final_document" size="sm"
                            value={data.final_document} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Примечания</Form.Label>
                        <Form.Control type="text" name="notes" size="sm"
                            value={data.notes} onChange={this.changeValue}/>
                    </Form.Group>
                    {/* <table class="table table-hover">
                    <tbody>
                            {this.state.comdata.map((comment, key)=>
                            <tr key={key}>
                                <td>{comment.comment}</td>
                                <td>{comment.datetime}</td>
                            </tr>)}
                        </tbody>
                    </table> */}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.param.id?(<Button onClick={this.Save}>Сохранить</Button>):
                    (<Button onClick={this.AddData}>Добавить</Button>)}
                    <Button onClick={this.Close}>Отмена</Button>
                </Modal.Footer>
              </Form>
          </Modal>);

    };
};

    class Commercial extends Component
    {
        constructor(props)
        {
            super(props);
            this.state = {isLoad:false,
                id:0,
            Entities:{},
            filter:'all',
            CommercialModalParam:{show:false}};
            this.Send = this.Send.bind(this);
        }

        LoadData = () =>{
            this.setState({isLoad:false});
            api("GET", "orders-commercial", "get-orders-commercial", {filter:this.state.filter, token: store.User.token})
            .then(Result=>{this.setState({Entities:Result, isLoad:true})});
        }
        componentDidMount()
        {
            this.LoadData();
        }
        Send (id){
            this.setState({isLoad:false});
            api("GET", "orders-commercial", "send-commercial", id)
            .then(this.LoadData)
        }
        render()
        {
            const {isLoad, Entities, CommercialModalParam} = this.state;
            return(<div>
                <CommercialModal show={CommercialModalParam.show}
                    onHide={()=>{
                        this.setState({CommercialModalParam:{show:false}})
                    }}
                    LoadData={this.LoadData}
                    param={CommercialModalParam}
                />
                <Form>
                <Form.Check type='switch' name="filter" id="com-switch" label="Отображать только мои коммерческие предложения" 
                        	onClick={(e)=>{
                                e.target.value = e.target.checked ? 'one' : 'all';
                                this.setState({filter: e.target.value}, () => {this.LoadData()});
                                }}/>
                </Form>
                {isLoad?(<Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Автор</th>
                        <th>Дата создания</th>
                        <th>Организация</th>
                        <th>Сайт</th>
                        <th>Тип заказа</th>
                        <th>Аппарат</th>
                        <th>Комплектация</th>
                        <th>Оферта</th>
                        <th>Статус КП</th>
                        <th>Ссылка на КП</th>
                        <th>Примечания</th>
                        <th style={{width:'30px'}}>
                            <Button size="sm"
                            onClick={()=>{this.setState({CommercialModalParam:{show:true}})}}>
                                Добавить
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Entities.map((Row, key)=>{return(<tr key={key}>
                    <td>{Row.author}</td>
                    <td>{Row.datetime}</td>
                    <td>{Row.counterparty}</td>
                    <td>{Row.site}</td>
                    <td>{Row.order_type}</td>
                    <td>{Row.device.map((dev) =>
                    <tr>{dev.name}</tr>)}
                </td>
                <td>
                {Row.equipment.map((eq) =>
                    <tr>{eq.name}</tr>)}
                </td>
                    <td>{Row.summa}</td>
                    <td>{Row.status}</td>
                    <td>{Row.link}</td>
                    <td>{Row.comment}</td>
                    <td>
                        <Button size="sm"
                            onClick={()=>{this.setState({CommercialModalParam:{show:true,
                                id:Row.id, author:Row.author}})}}>
                            Редактировать
                        </Button>
                    </td>
                    <td>
                        <Button size="sm"
                            onClick={ ()=> {this.Send({id:Row.id})} }>
                            Отправить в заказы
                        </Button>
                    </td>
                    </tr>)})}
                </tbody>
            </Table>):(<Spinner animation="border"/>)}</div>);
        }
    };

    class CommercialModal extends Component
    {

        data={
            id:this.props.param.id,
            author:'',
            city:'',
            order_type:'',
            device:'',
            number:0,
            equipment:'',
            summ:0,
            counterparty:'',
            link:'',
            paid:0,
            contact:'',
            telephone:'',
            summa:0,
            status:'',
            datetime:'',
            site:'',
            name:'',
            author:'',
            comment:'',
            products:[],
            complectation:[]
            };

        constructor(props)
    {
        super(props)

        this.state={
            isLoading:false,
            data:this.data,
            comdata:[],
            prods:[],
            complects:[],
        };
        this.AddData = this.AddData.bind(this);
        this.AddPosition = this.AddPosition.bind(this);
        this.AddComplect = this.AddComplect.bind(this);

        this.swalBehavior = [
			{
				title: 'Вы хотите удалить предложение?',
				icon: 'warning',
				confirmButtonColor: true,
				showCancelButton: true,
				confirmButtonText: 'Да',
				cancelButtonText: 'Отмена',
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33'
			},
			{
				icon: 'success',
				title: 'Предложение удалено',
				showConfirmButton: false,
				timer: 750
			}
		];
    }
    
    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "orders-commercial", "get-orders-commercial-by-id",{id:this.props.param.id})
            .then(result=>this.setState({data:result[0], prods:result[1], complects:result[2], comdata:result[3], isLoading:true}))
            
        }
        
    }

    customerChange(e) {
        var Attrbt1 = [];
        Attrbt1.location = e.target.attributes['location'];
        var Attrbt2 = [];
        Attrbt2.telephone = e.target.attributes['telephone'];
        this.setState({data:{...this.state.data, counterparty:e.target.value, city:Attrbt1, telephone:Attrbt2}}, ()=> console.log(this.state.data));
        // this.setState({data:{...this.state.data, counterparty:e.target.value}});
    }

    productChange(e) {
        // console.log(e.target.value)
        this.setState({products:e.target.value});
    }

    complectChange(e) {
        // console.log(e.target.value)
        this.setState({complectation:e.target.value});
    }

    Save = () =>
    {
        api("GET", "orders-commercial", "set-orders-commercial-data",{id:this.state.data.id?this.state.data.id:0,
            data:this.state.data, token:store.User.token})
            .then(()=>{
				this.props.LoadData(0,this.state.data.id_counterparty);
                this.Close();
            });
    }

    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data, isLoading:false});
    }

    Delete = () =>
    {
        api("GET", "orders-commercial", "delete-orders-commercial",{id:this.state.data.id})
           .then(()=>{
            this.props.LoadData();   
            this.Close()
        });   
    }

    AddData()
	{
		api("GET","orders-commercial","add-orders-commercial-data",{data:this.state.data, token:store.User.token})
        .then(()=>{
            this.props.LoadData();   
            this.Close()
        });
    }

    AddPosition()
    {
        let prod = this.state.data.products
        if (this.state.data.number<0||this.state.data.number==0)
        {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Введите корректный серийный номер.',
                showConfirmButton: false,
                timer: 2000
              })
        }
        else 
        prod.push({unit:this.state.data.unit, number:this.state.data.number})
        this.setState({data:{...this.state.data, products:prod}})
        // console.log(this.state.data.products)
    }

    AddComplect()
    {
        let complect = this.state.data.complectation
        if (this.state.data.count<0||this.state.data.count==0)
        {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: 'Количество может быть только положительным.',
                showConfirmButton: false,
                timer: 2000
              })
        }
        else 
        complect.push({detail:this.state.data.detail, count:this.state.data.count})
        this.setState({data:{...this.state.data, complectation:complect}})
        // console.log(this.state.data.complectation)
    }

    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

    changeValueProd = (e) => this.setState({data:{...this.state.data, [e.target.name]:{id:e.target.value, label:e.target.label}}});

    changeValueComplect = (e) => this.setState({data:{...this.state.data, [e.target.name]:{id:e.target.value, label:e.target.label}}});
    

    delProd = (e, key) =>
    {
        e.preventDefault();
        let prod = this.state.data.products;
        
        delete prod[key];

        this.setState({products:prod})
    }

    delComplect = (e, key) =>
    {
        e.preventDefault();
        let complect = this.state.data.complectation;
        
        delete complect[key];

        this.setState({complectation:complect})
    }

    render()
    {
        const {data} = this.state;
        return(<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
              <Form>
                <Modal.Header>
                    <Modal.Title>
                      {this.props.param.id ? 'Редактировать КП':'Добавить КП'}
                      {/* {this.props.param.id && <Button onClick={this.Delete}>Удалить</Button>} */}
                      <br/>
                      {this.props.param.id && (
							<Button style={{height:"20%"}} size="sm" variant="danger" type="submit" onClick={(e)=>{e.preventDefault();
								Swal.fire(this.swalBehavior[0]).then(
									(result) => {
										if (result.isConfirmed) {
											Swal.fire(this.swalBehavior[1])
											this.Delete()
										}
										else {
											e.preventDefault()
										}
								})
							}}>Удалить
						</Button>)}
                   </Modal.Title>
                   <Form.Group style={{display:"flex", flexDirection:"column", width:"35%", margin:"0"}}>	
						<div style={{height:"30%", textAlign:"right", fontWeight:"bold", fontSize:"1.4rem"}}>
                        <Link style={{fontWeight:"bold"}} to={"/user/"+data.author}>{this.props.param.author}</Link>
						</div>
						<div style={{textAlign:"right", fontWeight:"bold", marginBottom:"18px"}}>{data.datetime}</div>
				</Form.Group>
                </Modal.Header>
                <Modal.Body>
                    {/* <Form.Group controlId="NameControlGroup">
                    {this.props.param.id &&  <Form.Label>Автор КП</Form.Label>}
                    {this.props.param.id &&<Form.Control plaintext readOnly defaultValue={data.author}/>}
                    {this.props.param.id &&
                    <select  name="author" value={data.author} onChange={this.changeValue}>
                    {this.state.author.map((user, key)=>
                    <option key={key} >{user.Name} {user.Surname}</option>)}
                    </select>}
                    </Form.Group> */}
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Дата создания</Form.Label>
                        <Form.Control type="date" name="datetime" size="sm"
                            value={data.datetime} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Организация</Form.Label>
                        <Select placeholder={data.name.name}
                                dictionary="counterparties" 
                                name="counterparty"
                                onChange={(e)=>{this.customerChange(e)}}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Местоположение</Form.Label>
                        <Form.Control type="text" name="city" size="sm" disabled='true'
                            value={data.city.location ? data.city.location : '-'} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                    <Form.Label>Сайт</Form.Label>
                        <Form.Control type="text" name="site" size="sm" 
                            value={data.site} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Тип заказа</Form.Label>
                        <select className="form-control" name="order_type" value={data.order_type}
                        onChange={this.changeValue}>
						<option selected value="Дилер">Дилер</option>
                        <option value="Розница">Розница</option>
					</select>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Аппарат</Form.Label>
                        <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th width="80%"><Select placeholder="Поиск аппарата"
                                dictionary="apparat" 
                                name="unit"
                                onChange={this.changeValueProd}/></th>
                                <tr width="20%" colspan="2">Серийный номер<Form.Control type="number" name="number" size="sm"
                            value={this.state.data.number} placeholder="Серийный номер" onChange={this.changeValue}/></tr>
                            </tr>
                        </thead>
                        <tbody>
                         {/* class={
						(Row.color==1)?
						"table-warning":
						(Row.color==2)?
						"table-success":
						'table-danger'
						} 
                        {this.state.data.status=="Согласование"?
                        */}
                            {this.state.data.products && this.state.data.products.map((product, key)=>
                            <tr key={key}>
                                <td width="80%">{product.unit.label}</td>
                                <td width="20%">{product.number}</td>
                                <td><button type="button" class="btn btn-danger" onClick={(e)=>this.delProd(e, key)}>X</button></td>
                            </tr>)}
                        </tbody>
                        </table>
                        <table class="table table-bordered">
                        <tbody>
                            {this.state.prods.map((prod, key)=>
                            <tr key={key}> 
                                <td width="80%">
                                    <LinkContainer to={"/model/"+prod.unit}><Nav.Link><h5 class={
                                        (this.state.data.status=="Согласование"||this.state.data.status=="Отправлен")?
                                        "text-primary":(this.state.data.status=="Отклонено")? "text-danger":"text-success"}>{prod.unit}</h5></Nav.Link></LinkContainer>
                                </td>
                                <td width="20%">{prod.number}</td>
                                </tr>)}
                        </tbody>
                        </table>
                    <Button onClick={this.AddPosition}>Добавить позицию</Button>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Комплектация</Form.Label>
                        <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th width="80%"><Select placeholder="Поиск детали"
                                dictionary="details" 
                                name="detail"
                                onChange={this.changeValueComplect}/></th>
                                <tr width="20%" colspan="2"><Form.Control type="number" name="count" size="sm"
                            value={this.state.data.count} placeholder="Кол-во" onChange={this.changeValue}/></tr>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.complectation && this.state.data.complectation.map((complect, key)=>
                            <tr key={key}>
                                <td width="80%">{complect.detail.label}</td>
                                <td width="20%">{complect.count}</td>
                                <td><button type="button" class="btn btn-danger" onClick={(e)=>this.delComplect(e, key)}>X</button></td>
                            </tr>)}
                        </tbody>
                        </table>
                        <table class="table table-bordered">
                        <tbody>
                        {this.state.complects.map((prod, key)=>
                            <tr key={key}>
                                <td width="80%">
                                    <LinkContainer to={"/model/"+prod.detail}><Nav.Link><h5 class={
                                        (this.state.data.status=="Согласование"||this.state.data.status=="Отправлен")?
                                        "text-primary":(this.state.data.status=="Отклонено")? "text-danger":"text-success"}>{prod.detail}</h5></Nav.Link></LinkContainer>
                                </td>
                                <td width="20%">{prod.count} шт.</td>
                            </tr>)}
                        </tbody>
                        </table>
                        <Button onClick={this.AddComplect}>Добавить позицию</Button>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Оферта</Form.Label>
                        <Form.Control type="number" name="summa" size="sm"
                            value={data.summa} onChange={this.changeValue}/>
                    </Form.Group>
                    {/* <Form.Group controlId="NameControlGroup">
                        <Form.Label>Контакт(ФИО)</Form.Label>
                        <Form.Control type="text" name="contact" size="sm"
                            value={data.contact} onChange={this.changeValue}/>
                    </Form.Group> */}
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Телефон</Form.Label>
                        <Form.Control type="text" name="telephone" size="sm" disabled='true'
                            value={data.telephone.telephone ? data.telephone.telephone : '-'} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Статус готовности</Form.Label>
                        <select className="form-control" name="status" value={data.status}
                        onChange={this.changeValue}>
						<option value="Согласование">Согласование</option>
						<option value="Отклонено">Отклонено</option>
                        <option value="Одобрено">Одобрено</option>
                        <option value="Отправлен">Отправлен</option>
					</select>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Ссылка на КП</Form.Label>
                        <Form.Control type="text" name="link" size="sm"
                            value={data.link} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Примечания</Form.Label>
                        <Form.Control type="text" name="comment" size="sm"
                            value={data.comment} onChange={this.changeValue}/>
                    </Form.Group>
                    {/* <table class="table table-hover">
                    <tbody>
                            {this.state.comdata.map((comment, key)=>
                            <tr key={key}>
                                <td>{comment.comment}</td>
                                <td>{comment.datetime}</td>
                            </tr>)}
                        </tbody>
                    </table> */}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.param.id?(<Button onClick={this.Save}>Сохранить</Button>):
                    (<Button onClick={this.AddData}>Добавить</Button>)}
                    <Button onClick={this.Close}>Отмена</Button>
                </Modal.Footer>
              </Form>
          </Modal>);

    };
    };

    class Task extends Component
    {
        constructor(props)
        {
            super(props);
            this.state = {isLoad:false,
            Entities:{},
            TaskModalParam:{show:false},
            Color:[],
            filter:'one'
        };
        }

        LoadData = () =>{
            this.setState({isLoad:false});
            api("GET", "orders-task", "get-orders-task", {filter:this.state.filter, token: store.User.token})
            .then(Result=>{this.setState({Entities:Result, isLoad:true})});
        }
        componentDidMount()
        {
            this.LoadData();
        }
        render()
        {
            const {isLoad, Entities, TaskModalParam, Color} = this.state;
            return(<div>
                <TaskModal show={TaskModalParam.show}
                    onHide={()=>{
                        this.setState({TaskModalParam:{show:false}})
                    }}
                    LoadData={this.LoadData}
                    param={TaskModalParam}
                />
                <Form>
                <Form.Check type='switch' name="filter" id="task-switch" label="Отображать только мои задачи" defaultChecked
                        	onClick={(e)=>{
                                e.target.value = e.target.checked ? 'one' : 'all';
                                this.setState({filter: e.target.value}, () => {this.LoadData()});
                                }}/>
                </Form>
                {isLoad?(<Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Задача</th>
                        <th>Подробности</th>
                        <th>Планируемая дата исполнения </th>
                        <th>Дата выполнения </th>
                        <th>Ответственный</th>
                        <th style={{width:'30px'}}>
                            <Button size="sm"
                            onClick={()=>{this.setState({TaskModalParam:{show:true}})}}>
                                Добавить
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Entities.map((Row, key)=>{return(<tr key={key} 
                    class={
						(Row.color==1)?
						"table-warning":
						(Row.color==2)?
						"table-success":
						'table-danger'
						}>
                    <td style={{'max-width': '300px'}}>{Row.task}</td>
                    <td style={{'max-width': '400px'}}>{Row.comment}</td>
                    <td>{Row.workdate}</td>
                    <td>{Row.finishdate}</td>
                    <td>{Row.worker}</td>
                    <td>
                        <Button size="sm"
                            onClick={()=>{this.setState({TaskModalParam:{show:true,
                                id:Row.id}})}}>
                            Редактировать
                        </Button>
                     { (Row.color != 2 && Row.color != 1) &&  <Button size="sm" variant="warning" 
                            onClick={(e)=>{api("GET", "orders-task", "have-color", {id:Row.id, author:store.User.token})
                            .then((result)=>{if(result.Result==='done'){this.LoadData()}})}}>
                            Принять работу
                        </Button>}
                       {Row.color !=2 && <Button size="sm" variant="success"
                            onClick={(e)=>{api("GET", "orders-task", "ready-color", {id:Row.id, author:store.User.token})
                            .then((result)=>{if(result.Result==='done'){this.LoadData()}})}}>
                            Завершил работу
                        </Button>}
                    </td>
                    </tr>)})}
                </tbody>
            </Table>):(<Spinner animation="border"/>)}</div>);
        }
    };

    class TaskModal extends Component
    {
        data={
            id:0,
            task:'',
            comment:'',
            workdate:'',
            finishdate:'',
            worker:''};

        constructor(props)
        {
            super(props)

            this.state={
                isLoading:false,
                isComLoad:false,
                data:this.data,
                users:[],
                comdata:[],
            };
            this.state.data.id=this.props.param.id;
            this.AddData = this.AddData.bind(this);
        }

        handleChange(e) {
            this.setState({data:{...this.state.data, counterparty:e.target.label}});
        }

        componentDidUpdate()
        {
            if(this.props.param.id && this.state.isLoading===false)
            { 
                api("GET", "orders-task", "get-orders-task-by-id",{id:this.props.param.id})
                .then(result=>this.setState({data:{...result}, name:{...result}, isLoading:true}))
                .then(() =>{
                    api("GET", "orders-task", "get-task-comments",{idp:this.props.param.id})
                    .then(result=>{
                        this.setState({comdata:result, isComLoad:true});
                        console.log(this.state.comdata)})
                    });
                    
        }
    }
    componentDidMount()
    {
        api("GET","orders-task","get-users-task")
                    .then(result=>{this.setState({users:result}); 
                    console.log(this.state.users)}) 
    }

        Save = () =>
        {   
            api("GET", "orders-task", "set-orders-task-data",{data:this.state.data, token:store.User.token})
                .then(()=>{
                    this.props.LoadData();
                    this.Close();
                    });
        }

        Close = () =>
        {
            this.props.onHide();
            this.setState({data:this.data,  isLoading:false, isComLoad:false});
        }

        Delete = () =>
        {
            api("GET", "orders-task", "delete-orders-task",{id:this.state.data.id})
            .then(()=>{
                this.props.LoadData();   
                this.Close()
            });   
        }

        AddData()
	    {
            api("GET","orders-task","add-orders-task-data",{data:this.state.data, token:store.User.token})
            .then(()=>{
                this.props.LoadData();   
                this.Close()
            });
        }


        changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

        render()
        {
            const {data, isLoading, isComLoad} = this.state;

            return(<Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Form>
                    <Modal.Header>
                        <Modal.Title>
                        {this.props.param.id?'Редактировать задачу':'Добавить задачу'}
                        {this.props.param.id && <Button onClick={this.Delete}>Удалить</Button>}
                    </Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    {/* {isLoading?( */}
                    <div>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Задача</Form.Label>
                        <Form.Control type="text" name="task" size="sm" 
                            value={data.task} onChange={this.changeValue}/>
                        </Form.Group>
                        <Form.Group controlId="NameControlGroup">
                        <Form.Label>Подробности</Form.Label>
                        <Form.Control type="text" name="comment" size="sm"
                            value={data.comment} onChange={this.changeValue}/>
                        </Form.Group>
                        <Form.Group controlId="NameControlGroup">
                            <Form.Label>Планируемая дата исполнения</Form.Label>
                            <Form.Control type="date" name="workdate" size="sm"
                            value={data.workdate} onChange={this.changeValue}/>
                        </Form.Group>
                        <Form.Group controlId="NameControlGroup">
                            <Form.Label>Дата</Form.Label>
                            <Form.Control type="date" name="finishdate" size="sm"
                                value={data.finishdate} onChange={this.changeValue}/>
                        </Form.Group>
                        <Form.Group controlId="NameControlGroup">
                            <Form.Label>Ответственный  :</Form.Label>

                        <select  name="worker" value={data.worker} onChange={this.changeValue}>
                                    {console.log(this.state)}
                                    {this.state.users.map((user, key)=>
                                    <option key={user.ID} value={user.ID}>{user.Name} {user.Surname} </option>)}
                                </select>
                        </Form.Group>
                    </div>
                    {/* ):(<Spinner animation="border"/>)} */}
                        {this.props.param.id &&(<table class="table table-hover">
                        {isComLoad?(
                        <tbody>
                            {this.state.comdata.map((comment, key)=>
                            <tr key={key}>
                                <td>{comment.comment}</td>
                                <td>{comment.datetime}</td>
                            </tr>)}
                        </tbody>):(<Spinner animation="border"/>)}
                    </table>)}
                    </Modal.Body>
                    <Modal.Footer>
                    {this.props.param.id?(<Button onClick={this.AddData}>Сохранить</Button>):
                        (<Button onClick={this.Save}>Добавить</Button>)}
                        <Button onClick={this.Close}>Отмена</Button>
                    </Modal.Footer>
                </Form>
            </Modal>);

        };
    };

function Orders (){
    const [tab, setTab] = useState('ord'); 
    return (
        <Container>
            <Calendar/>
            <Tabs id="controlled-tab-example" activeKey={tab} onSelect={k => setTab(k)}>
                <Tab eventKey="task" title="Задачи">
                    <Task/>
                </Tab>
                <Tab eventKey="ord" title="Заказы">
                    <Ord/>
                </Tab>
                <Tab eventKey="commercial" title="Коммерческие предложения">
                    <Commercial/>
                </Tab>
            </Tabs>
        </Container>       
    );
}
export default Orders;
