import React, {Component, useEffect, useState, useRef} from 'react';
import { Container, Form, Table, Button } from 'react-bootstrap';
import { setTokenSourceMapRange } from 'typescript';
import Select from '../Components/SelectDictionary'
import { ControllerTest, controllerFinanceEnumPeriodicPayment, controllerEnumPaymentStatus, controllerPayment } from '../Common/controllers';

import { makeAutoObservable, toJS } from "mobx"
import { observer } from "mobx-react"

// 1. Подключить контроллер
//  <Select dictionary = "wh_detail"/>
// В используемой контроллером базе данных обязательны поля name:string, id:intger
// Вызывает контроллер - wh_detail/views
//      https://yii-back.ru/web/wh_detail/views

// 2. Передача данных из <Select/>
//      Передать OnChange функцию, у которой есть доступ к параметру e
//      e.label     - Отображаемое значение 
//      e.value     - порядковый
//      e.name      - имя текущего Select для удобства отображения

const dbTables = `
| app_js                            |
| counterparties                    |
| counterparties__entity            |
| counterparties__entity_comments   |
| counterparties__individual        |
| current_table                     |
| device__fix                       |
| device__fixstatus                 |
| device__history                   |
| events                            |
| events_statuses                   |
| finance__enum__payment_status     |
| finance__enum__periodic_payment   |
| finance__payments                 |
| finance__periodic_payment         |
| ia__balance                       |
| ia__int_accounts                  |
| ia__int_accounts_history          |
| ia__int_accounts_history_category |
| ia__int_accounts_history_purpose  |
| ia__statement                     |
| manufacture__compound             |
| manufacture__device               |
| message_list                      |
| minichat_messages                 |
| models__models_details            |
| models__models_operations         |
| models__models_units              |
| models__operation_annotation      |
| models__operations_list           |
| models__unit_units                |
| models__units_details             |
| models__units_operations          |
| modules                           |
| notes                             |
| notes_categories                  |
| notes_comments                    |
| orders                            |
| orders__com_complectatio          |
| orders__com_products              |
| orders__comments                  |
| orders__commercial                |
| orders__commercial_comments       |
| orders__complectatio              |
| orders__ord                       |
| orders__payments                  |
| orders__products                  |
| orders__task                      |
| orders__task_comments             |
| pass_categories                   |
| pass_man                          |
| pma__bookmark                     |
| pma__central_columns              |
| pma__column_info                  |
| pma__designer_settings            |
| pma__export_templates             |
| pma__favorite                     |
| pma__history                      |
| pma__navigationhiding             |
| pma__pdf_pages                    |
| pma__recent                       |
| pma__relation                     |
| pma__savedsearches                |
| pma__table_coords                 |
| pma__table_info                   |
| pma__table_uiprefs                |
| pma__tracking                     |
| pma__userconfig                   |
| pma__usergroups                   |
| pma__users                        |
| products                          |
| reg__contract                     |
| reg__history                      |
| reg__status                       |
| reg__type                         |
| system_config                     |
| tab__history                      |
| tab__status                       |
| tab__statusstatus                 |
| task_categories                   |
| task_comments                     |
| task_manager                      |
| tasks                             |
| users                             |
| users_affination                  |
| users_dep                         |
| users_status_list                 |
| users_tokens                      |
| wh__category_details              |
| wh__details                       |
| wh__details_counterparties        |
`


/* Regular expr
    1. В JS используется свой литерал для регулярных выражений заключенный в / /
    2. У любой строки в JS есть методы: match, matchAll и т.д  принимающие регулярные выражения
*/
const dbTableNames = function(){
    const arrOfName = dbTables.split("\n")

    const out = arrOfName.map(str => {    
        const arrOfString = str.match(/\w+/)
        if(!arrOfString)
            return ''
        return arrOfString[0]
    })

    return out
}()

function SelectTest(){

    const [data, setDetail] = useState({
        name: '',
        value: '',
        label: '',
    });


    return(
        <Container> 
                <div style={{"border-style":"solid", "border-color":"gray", "padding":"0.2rem", "margin-bottom":"8px"}}>{data.label}</div>     
                <Select
                    dictionary = "wh_detail"
                    name="detail"
                    onChange={(e)=>{
                        setDetail({
                            name: e.target.name,
                            value: e.target.value,
                            label: e.target.label
                        });
                        console.log("This's detail", data)                // dev
                    }}
                />
        </Container>    
    )
}




// Работает для английского
const isUpperCase = (char) => {
    const code = char.charCodeAt(0)
    return code >= 'A'.charCodeAt(0) && code <= 'Z'.charCodeAt(0)
}

// const domain = 'http://erp-back-yii.ru/web'


function convertClassToURL(class_name){
    let ind = 0
    let url = ''
    for(let i = 0; i < class_name.length; i++){
        if(isUpperCase(class_name[i])){
            url += class_name.slice(ind, i) + '-'
            ind = i
        }

        if(i === class_name.length - 1){
            url += class_name.slice(ind)
        }
    }

    return url.slice('action'.length + 1).toLowerCase()
}



const convertUpperCaseToDash = (upperCaseWord) => {
    if(upperCaseWord === null || upperCaseWord === undefined)
        return ''

    let ind = 0
    let url = ''
    for(let i = 0; i < upperCaseWord.length; i++){
        if(isUpperCase(upperCaseWord[i])){
            url += upperCaseWord.slice(ind, i) + '-'
            ind = i
        }

        if(i === upperCaseWord.length - 1){
            url += upperCaseWord.slice(ind)
        }
    }

    if(url[0] === '-')
        url = url.slice(1)

    return url.toLowerCase()
}


/**
 * @param {*} param0 
 * @returns 
 */
function getControllerURL({controllerName, className, domain, moduleName}){
    let url = domain + '/'
    if(typeof(moduleName) === 'string'){
        if(moduleName.length > 0)
            url += moduleName + '/'
    }

    url += convertUpperCaseToDash(controllerName) + '/' + convertClassToURL(className)

    return url
}





// console.log(getControllerURL('model', 'actionIntegrityModelsDetails', 'http://erp-back-yii.ru/web'));


const CurrentSelect = ({
    optionsArray,
    onChange,

    style
}) => {

    const selectStyle = {
        width: '30%',
        ...style,
    }

    return(
        <select style={selectStyle} onChange={onChange}>
            {optionsArray.map((name, i) => <option value={i}>{name}</option>)}
        </select>
    )
}


const SelectInput = ({
    optionsArray,
    onChange

}) => {
    const [inputMode, setInputMode] = useState(true)

    const divStyle = {
        display: 'flex',
        width: '540px'
    }

    return(
        <>  
            
            {inputMode
                ?<div style={divStyle}>
                    <Button
                        onClick={() => {setInputMode(false)}}
                        children='INPUT'
                    />
                    <Form.Control
                        onChange={onChange}
                        
                    />
                </div>
                
                
                :<div style={divStyle}>
                    <Button
                        onClick={() => {setInputMode(true)}}
                        children='SELECT'
                        variant='secondary'
                    />

                    <CurrentSelect
                        optionsArray={optionsArray}
                        onChange={onChange}
                        style={{width: '100%'}}
                    />
                </div>
               
                

            }
        </>
    )
}

// TODO добавить добавление параметров
const PhpConvertClassToURL = () => {
    const domains = ['http://erp-back-yii.ru/web', 'https://bitrix.aria-invertor.ru/api/v1/web']
    const controllers = ['finance', 'model', 'warehouse', 'PeriodicPayment']
    const modulesNames = ['', 'finances']

    // const [domain, setDomain] = useState(domains[0])
    // const [moduleName, setModuleName] = useState(modulesNames[0])
    // const [controllerName, setControllerName] = useState(controllers[0])
    // const [className, setClassName] = useState('')
    const componentData = useRef({
        domain: domains[0],
        moduleName: modulesNames[0],
        controllerName: controllers[0],
        className: ''
    })


    const [output, setOutput] = useState('')
    
    const data = {
        domain: domains[0],
        moduleName: modulesNames[0],
        controllerName: controllers[0],
        className: ''
    }

    const updateURL = () => {
        // console.log(data);
        const outputURL = getControllerURL(componentData.current)
        setOutput(outputURL)
    }


    const onChange = {
        domain(e){
            data.domain = domains[e.target.value]
            
            componentData.current.domain = domains[e.target.value]
            updateURL()
        },

        module(e){
            data.moduleName = modulesNames[e.target.value]
            
            componentData.current.moduleName = modulesNames[e.target.value]
            updateURL() 
         },

        controller(e){ 
            const type = e.target.nodeName
            
            if(type === 'SELECT'){
                data.controllerName = controllers[e.target.value]
                componentData.current.controllerName = controllers[e.target.value]
            }
                
            else if(type === 'INPUT'){
                data.controllerName = e.target.value
                componentData.current.controllerName = e.target.value
            }
                


            updateURL()
         },

        className(e){
            data.className = e.target.value

            componentData.current.className = e.target.value
            updateURL()
        }

    }
    // useEffect(() => {
    //     const outputURL = getControllerURL({
    //         controllerName: controllerName,
    //         className: className,
    //         domain: domain,
    //         moduleName: moduleName
    //     })

    //     console.log({ controllerName: controllerName,
    //         className: className,
    //         domain: domain,
    //         moduleName: moduleName});

    //     setOutput(outputURL)
    // }, [className, domain, controllerName, moduleName])



    return(
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div>Домен</div>    
            <CurrentSelect optionsArray={domains} onChange={onChange.domain}/>

            <div>Модуль</div>
            <CurrentSelect optionsArray={modulesNames} onChange={onChange.module}/>

            <div>Контроллер</div>
            {/* <CurrentSelect optionsArray={controllers} onChange={controllerChange}/> */}
            <SelectInput optionsArray={controllers} onChange={onChange.controller}></SelectInput>


            <div>Название класа в PHP</div>
            <input style={{width: '30%'}} placeholder='Название контроллера' onChange={onChange.className}></input>

            <div style={{fontSize: '23px'}}>{output}</div>

            
        </div>
    )
}




/**
 * @type {string}
 * @param {string} string
 * @param {string} end_prefix_symbol
 * @return {string} 
 * @example slicePrefix('finance__enum_payment_status', '__'): 'finance' 
 */
const slicePrefix = (string, end_prefix_symbol) => {
    const ind = string.indexOf(end_prefix_symbol)
    const prefix_length = end_prefix_symbol.length
    return string.slice(ind + prefix_length)
}

function converFirstCharToUpperCase(string){
    return string[0].toUpperCase() + string.slice(1, string.length)
}

/*  
    FinanceController: controller_name = main_prefix + 'Controller'
    
    finance__enum_payment_status: table_name =  main_prefix + '__' + 
    ActionName: 'action' + operation_name +  
    table_name = main_prefix + 

*/
class StringBuilder{
    #table_name
    #main_prefix
    constructor(table_name){
        this.#table_name = table_name
        this.#main_prefix = slicePrefix(table_name, '__')

    }

    /**
     * 
     * @param {strinf} operation 
     * @example generateAction('Add')
     */
    generateAction(operation, params=''){ 
        let arr_of_names = this.#table_name.split('_')
        arr_of_names = arr_of_names.slice(2, arr_of_names.length)

        let actionName = 'action' + converFirstCharToUpperCase(operation) 
        for(const str of arr_of_names)
            actionName += converFirstCharToUpperCase(str)
        
        return `public function ${actionName}(){\n}`
    }


}

// const instStringBuilder = new StringBuilder('finance__enum_payment_status')

// console.log(instStringBuilder.generateAction('Add'));
// console.log(instStringBuilder.generateAction('Read'));
// console.log(instStringBuilder.generateAction('Update'));
// console.log(instStringBuilder.generateAction('Delete'));


const ActionBuilder = () => {
    const [tableName, setTableName] = useState('')

    useEffect(() => {
        console.log(tableName);
    }, [tableName])

    const selectTableNameChange = (el) => { setTableName(el.target.value) }

    return(
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div>Название контроллера</div>    
            <select style={{width: '30%'}} onChange={selectTableNameChange}>
                {dbTableNames.map((name, i) => <option value={i}>{name}</option>)}
            </select>
        </div>

    )
    
}



const ControllerEnumPaymentStatusTest = () => {
    
    // const [idStatus, setIdStatus] = useState(undefined)

    const readLastRow = async() => {
        let result = await controllerEnumPaymentStatus.read()
        return result[result.length - 1]
    }

    const clearLog = () => {
        outputRef.current.value = ''
    }

    const addLog = (text, newLine = true) => {
        outputRef.current.value += text
        if(newLine)
            outputRef.current.value += '\n'
    }

    const logStatus = (obj) => {
        for(const key in obj){
            if(obj[key] === true)
                addLog(`🟢 ${key}`)
            else if(obj[key] === false)
                addLog(`🔴 ${key}`)
            else if(obj[key] === undefined)
                addLog(`🟤 ${key}`)
        }
    }

    const controllerEnumPaymentStatusTest_1 = async () => {
        const controllerStatus = {
            CREATE: undefined,
            READ: undefined,
            UPDATE: undefined,
            DELETE: undefined,
        }
        
        clearLog()
        addLog('1. Добавление строки Testing')
        // outputRef.current.value = '1. Добавление строки Testing\n'
        await controllerEnumPaymentStatus.add('Testing')

        addLog('2. Получение последней строки')
        let lastRow = await readLastRow()
        const id_status = lastRow[0]
        const description = lastRow[1]
       
        addLog(`{id_status: ${id_status},\n descripton: ${description}\n}`)

        // 1.
        if(description === 'Testing'){
            controllerStatus.READ = true
            controllerStatus.CREATE = true
        }
        else{
            logStatus(controllerStatus)
            return
        }
            

        addLog('3. Обновление данных')
        await controllerEnumPaymentStatus.update(id_status, 'Testing Update')
        lastRow = await readLastRow()

        // 2.
        if(lastRow[1] === 'Testing Update')
            controllerStatus.UPDATE = true
        else{
            logStatus(controllerStatus)
            return
        }
        
        await controllerEnumPaymentStatus.delete(id_status)
        lastRow = await readLastRow()

        // 3.
        if(id_status > lastRow[0])
            controllerStatus.DELETE = true
        else{
            logStatus(controllerStatus)
            return
        }
        


        logStatus(controllerStatus)
    }

    const controllerClick = async () => {

        const passAutoIncrement = await ControllerTest.autoInrecment(controllerFinanceEnumPeriodicPayment, ['Test Value'], 'id_periodic')

        let res = await controllerFinanceEnumPeriodicPayment.read()
        const last_ind = res[res.length - 1].id_periodic
        console.log(res);
        console.log(last_ind);

        await controllerFinanceEnumPeriodicPayment.add('Test Value')
        res = await controllerFinanceEnumPeriodicPayment.read()

        // Autoincrement test
        console.log(last_ind,);
        // const passAutoIncrement = (last_ind === res[res.length - 1].id_periodic - 1)
        console.log('Autoincrement test', passAutoIncrement);

        // Add value test
        const passAddValue = (res[res.length - 1].periodic === 'Test Value')
        console.log('Add value test', passAddValue);


        // console.log(controller);
        clearLog(res)
        addLog(controllerFinanceEnumPeriodicPayment)
    }

    const deleteClick = async () => {
        console.log(outputRef);    
    }

    const updateClick = async () => {
        console.log(outputRef);    
    }
    
    const outputRef = useRef(null)

    return (
        <>
            <Form.Control as='textarea' style={{fontSize: '14px'}} rows={10} ref={outputRef} placeholder='Выходные данные' width='max'></Form.Control>
            <Table>
                <thead>
                    <tr>
                        <td>Название контроллера</td>
                        <td>Описание</td>
                        <td>Статус</td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <Button onClick={controllerEnumPaymentStatusTest_1}>financeEnumPaymentStatus</Button>
                        </td>
                        <td>
                            <div>
                            Проверяет CRUD по сценарию.
                            1. Добавление последней строки "Testing"
                            2. Её прочтение
                            3. Изменение последней строки на "Testing Update" и её прочтение.
                            4. Удаление последней строки и проверка, что не она уже последняя
                            </div>
                        </td>
                        <td>
                            <Button variant='danger'></Button>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Button onClick={controllerClick}>Read</Button>
                        </td>
                        <td>
                            <Form.Control placeholder='id_status'></Form.Control>
                        </td>
                        <td>
                            <Button variant='danger'></Button>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Button onClick={deleteClick}>Delete</Button>
                        </td>
                        <td>
                            <Form.Control placeholder='id_status'></Form.Control>
                        </td>
                        <td>
                            <Button variant='danger'></Button>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Button onClick={updateClick}>Update</Button>
                        </td>
                        <td>
                            <Form.Control placeholder='id_status'></Form.Control>
                        </td>
                        <td>
                            <Button variant='danger'></Button>
                        </td>
                    </tr>
                    

                </tbody>
                
            </Table>

            
        </>
    )
}



const observerStore = makeAutoObservable({
    this_is: 1
})

setInterval(() => {
    observerStore.this_is++

}, 1000)



const crudStore = makeAutoObservable({
    data: [],
    crud: controllerPayment
})


const MobxTest = observer(({store}) => {

    console.log('rerender');

    const readHandler = async () => {
        store.data = await store.crud.read()
        console.log(toJS(store.data))

        
    }


    return (
        <>
            <div>Hello mobx</div>

            <button onClick = {() => {}}>Create</button>
            <button onClick={readHandler}>Read</button>
            <button onClick = {() => {}}>Update</button>
            <button onClick = {() => {}}>Delete</button>

            {store.data.map(obj => 
                <div style={{display: 'flex'}}>

                    <div>{obj.id} </div>
                    <div>{obj.description} </div>
                    <div>{obj.amount} </div>
                </div>
            )}
         
        </>
    )
})




function Test(){
    const styleTitle = {
        "margin": "10%, 0",
        "padding": "1rem",
        "width": "50%",
        "font-size": "18px",
        
    }

    
    return(
        <>
            <div style={styleTitle}>1. Использование Select</div>
            <SelectTest/>
            <div style={styleTitle}>2. Php Helper</div>
            <PhpConvertClassToURL/>
            <div style={styleTitle}>3. Yii2 Action Builder</div>
            <ActionBuilder/>
            {/* <ControllerEnumPaymentStatusTest/> */}

            <MobxTest store={crudStore}/>


        </>
    )
}


export default Test;