// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errored {border-color:red;}\n.numenculatureAttrs * {display:inline-block;}\n.numenculatureAttrs input {-webkit-box-sizing: border-box;box-sizing: border-box;\n    width: 45%;\n    border: 1px solid #d9d9d9;\n    border-radius: .1875em;\n    background: inherit;\n    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 6%);\n            box-shadow: inset 0 1px 1px rgb(0 0 0 / 6%);\n    color: inherit;\n    font-size: 1.125em;\n    height: 2.625em;\n    padding: 0 .75em;\n}\n._outset, ._move {display:none}\n", "",{"version":3,"sources":["webpack://./src/Pages/glyphicon.css"],"names":[],"mappings":"AAEA,UAAU,gBAAgB,CAAC;AAC3B,uBAAuB,oBAAoB,CAAC;AAC5C,2BAA2B,8BAAsB,CAAtB,sBAAsB;IAC7C,UAAU;IACV,yBAAyB;IACzB,sBAAsB;IACtB,mBAAmB;IACnB,mDAA2C;YAA3C,2CAA2C;IAC3C,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;AACA,kBAAkB,YAAY","sourcesContent":["@import url(\"https://netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css\");\n\n.errored {border-color:red;}\n.numenculatureAttrs * {display:inline-block;}\n.numenculatureAttrs input {box-sizing: border-box;\n    width: 45%;\n    border: 1px solid #d9d9d9;\n    border-radius: .1875em;\n    background: inherit;\n    box-shadow: inset 0 1px 1px rgb(0 0 0 / 6%);\n    color: inherit;\n    font-size: 1.125em;\n    height: 2.625em;\n    padding: 0 .75em;\n}\n._outset, ._move {display:none}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
