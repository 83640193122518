import React, { Component, useEffect, useState, useRef, useMemo } from "react"
import {Link} from "react-router-dom";
import store from '../Store';
import { Table, Button, Spinner, Nav, NavItem, Form, Container, Modal, Row, FormControl } from "react-bootstrap"

import {default as ReactSelect} from 'react-select';

import Select from "../Components/SelectDictionary";
import api from "../Api.js";
import {
     DescriptionTD, NumberTD, DateTD, UrlTD, TD, SelectTD,
      TableAddButton, SelectWithController, SelectDictionaryTD, ReactSelectTD, ReactSelectWithController }
     from "../Components/Table";
import {
    controllerFinanceEnumPeriodicPayment,
    controllerPeriodicPayment,
    selectControllerEnumPeriodicPayment,
    controllerPayment,
    controllerEnumPaymentStatus,
    FinanceController
    } from "../Common/controllers";
import { objectAdapter as replaceKeys, getOrderInSiblings, sortNumber, sortDate, sortString } from "../Common/commot_ts";

import { makeAutoObservable, toJS } from "mobx"
import { observer } from "mobx-react"



class SingleComponentStore{
    register(name, state_or_handler){
        this[name] = state_or_handler
    }

    registerHandler(handler_name, handler){
        this.register(handler_name, handler)
    }

    registerState(state_name, state){
        this.register(state_name, state)
    }
    
}

class ComponentsStore{
    addComponent(id){
        this[id] = new SingleComponentStore()
    }
}

const PeriodicSelectors = new ComponentsStore()

function useStore(componentsStore, id, states, handlers){
    useEffect(() => {
        componentsStore.addComponent(id)
        for(const name in states)
            componentsStore[id].registerState(name, states[name])
        
        for(const name in handlers)
            componentsStore[id].registerHandler(name, handlers[name])
        
    }, [])

    useEffect(() => {
        for(const name in states)
            componentsStore[id][name] = states[name]
    }, Object.values(states))
}





const Data = [
    [1, 'УФНС', '10000', 'Налог 1', '01.09.22', '+', 'Одобрено', 'Пользователь 1', 'Тык'],
    [2, 'Собственик помещения', '70000', 'Аренда помещания', '01.09.22', '+', 'Одобрено', 'Пользователь 2', 'Тык'],
    [3, 'УФНС', '10000', 'Налог 1', '01.09.22', '+', 'Одобрено', 'Пользователь 1', 'Тык'],
    
]


// finance__enum__payment_status
// http://erp-back-yii.ru/web/finance/read-enum-payment-status

// Считает порядковый номер в родительском узле
const calcSiblingOrder = (el) => {
    let order = 0
    let prev_el = el.previousElementSibling

    while(prev_el != null){
        order++
        prev_el = prev_el.previousElementSibling
    }

    return order
    
}



// // Если поле в режиме редактирования, то заменяется строка на инпут
// const DescriptionTD = ({
//     children,
//     id,                         // number: порядковый номер в таблице
//     selectId,                   // number: выбранный id, если id===selectId, то поле переходит в режим редактирования
//     selectClick,                // handler: меняет состояния selectId в одном из родительских элементов
//     readInput,                  // handler: считывает поле input, каждый раз когда оно изменяется
//     }) => {

//         return(
//             <>
//                 {
//                     id===selectId
//                         ?<td>
//                             <Form.Control onChange={readInput} placeholder={children}/>
//                         </td>

//                         :<td onClick={selectClick}>{children}</td>
//                 }
//             </>
//         )
// }




const EditButtonsTD = ({
    children,
    y,                          // number: порядковый номер в строки
    selectedY,                   // number: выбранная строка в таблице 
    selectClick,                // handler: меняет состояния selectId в одном из родительских элементов

    readInput,                  // handler: считывает поле input, каждый раз когда оно изменяется
    
    saveClick,
    cancleClick,
    deleteClick

    }) => {
    

    return(
        y === selectedY
        ?<td>  
            <Button onClick={saveClick} size='sm'>Сохранить</Button>
            <Button onClick={cancleClick} variant="secondary" size='sm'>Отменить</Button>
        </td>

        :<td>
            <Button onClick={deleteClick} variant="danger" size='sm'>Удалить</Button>
        </td>
    )}

/**
 * @param {Date} date 
 */
const DateFormatTable = (date) => {
    let year = date.getFullYear().toString().slice(2)
    
    let month = date.getMonth()
    if(month < 10)
        month = '0' + month

    let day = date.getDay()
    if(day < 10)
        day = '0' + day

    return `${day}.${month}.${year}`
}


/** Справочник постоянных платежей */
const PeriodicPaymentTable = () => {
    const [selectedY, setSelectedY] = useState(undefined)

    const data = {
        id: [
            1, 2, 3
        ],
        description: [
            '1', '2', '3'
        ],
        amount: [
            1000, 2000, 3000
        ],
        date: [
            new Date(2022, 8, 28),
            new Date(2022, 9, 28),
            new Date(2022, 10, 28),
        ],
        url: [
            'abc',
            'def',
            'qwerty'
        ]
        
    }

    const Row = ({
        y,
        // description,
        // amount,
        // date,
        // url,
        data                // Данные, obj = {string: id, string}
    }) => {

        const selectProps = {
            y: y,
            selectedY: selectedY,
            selectClick: () => setSelectedY(y)
        }

        // Данные 
        let dataWarehouse = [data.description, data.amount, data.date, null]

        const cancleClick = () => {
            setSelectedY(undefined)
            dataWarehouse = [data.description, data.amount, data.date, null]
            console.log(dataWarehouse);
        }

        const saveClick = () => {
            console.log(dataWarehouse)
        }

        const Reader = {
            description: (e) => {
                console.log(`1 Description: ${e.target.value}`)
                dataWarehouse[0] = e.target.value
            },

            cost: (e) => {
                console.log(`2 Cost: ${e.target.value}`)
                dataWarehouse[1] = e.target.value
            },

            date: (e) => {
                const row_date = e.target.value
                const year = row_date.slice(0, 4)
                const month = row_date.slice(5, 7)
                const day = row_date.slice(8, 10)
                
                console.log(`3 Date: ${day}.${month}.${year}`);
                dataWarehouse[2] = `${day}.${month}.${year}`
            },

            url: (e) => {
                console.log(null);
            }
        }

        return(
            <tr>
                <td>{data.id}</td>
                <DescriptionTD
                    {...selectProps}
                    onChange={Reader.description}
                    
                    >{data.description}
                </DescriptionTD>
                

                <EditButtonsTD
                    {...selectProps}
                    cancleClick={cancleClick}
                    saveClick={saveClick}
                    >
                </EditButtonsTD>


                {/* <td>{data.url}</td> */}
            </tr>
        )
    }

    const TableData = () => {
        const View = []

        for(let i = 0; i < data.id.length; i++){
            const dataRow = {
                id: data.id[i],
                description: data.description[i],
                amount: data.amount[i],
                date: DateFormatTable(data.date[i]),
                url: data.url[i],
            }

            View.push(
                <Row data={dataRow} y={i}/>
            )
        }

        return <>{View}</>
        
    }

    return(
        <Table striped bordered hover>
            <thead>
                <tr>
                    <td>ID</td>
                    <td>Описание</td>
                    <td>Стоимость</td>
                    <td>Дата платежа</td>
                    <td>Ссылка</td> 
                </tr>
            </thead>

            <tbody> 
                <TableData/>
            </tbody>
        </Table>
    )
}




/** Справочник периодичности платежей */
const EnumTable = ({
    /** CRUD контроллер */
    controller          
}) => {
    const [selectedY, setSelectedY] = useState(undefined)
    const [data, setData] = useState([])
    
    const loadData = async () => {
        const res = await controller.read()
        setData(res)
    }

    useEffect(() => {
        loadData()  
        .catch(console.error)
    }, [])

    const [addNewRowState, setAddNewRowState] = useState(false)

    
    const addModule = {
        data: {
            description: undefined
        },

        click: {
            async add(){
                await controller.add(addModule.data.description)
                addModule.data.description = undefined
                loadData()
                setAddNewRowState(false)
            }
        },

        onChange: {
            description(e){
                addModule.data.description = e.target.value
            }
        }
    }


    const Row = ({
        y,
        data                // Данные, obj = {string: id, string}
    }) => {

        const selectProps = {
            y: y,
            selectedY: selectedY,
            selectClick: () => setSelectedY(y)
        }

        const input = {
            description: {
                data: data.id,
                onChange(e){
                    input.description.data = e.target.value
                }
            }
        }
    
        const click = {
            async save(){
                await controller.update(data.id, input.description.data)
                setSelectedY(undefined)
                loadData()
            },
    
            cancel(){
                setSelectedY(undefined)
            },  
    
            async delete(){
                await controller.delete(data.id)
                setSelectedY(undefined)
                loadData()
            }
        }


        return(
            <tr>
                <TD type='default'
                    children={data.id}
                />
                <TD type='description'
                    {...selectProps}
                    onChange={input.description.onChange}
                    children={data.description}
                />
                
                <EditButtonsTD
                    {...selectProps}
                    cancleClick={click.cancel}
                    saveClick={click.save}
                    deleteClick={click.delete} 
                    >
                </EditButtonsTD>
            </tr>
        )
    }

    return(
        <Table striped bordered hover>
            <thead>
                <tr>
                    <td>ID</td>
                    <td>Описание</td>
                    <td>
                        <TableAddButton
                            children='Добавить строку'
                            onClick={() => setAddNewRowState(true)}
                        />
                    </td>

                </tr>
            </thead>

            <tbody>
                {addNewRowState
                    ?<tr>
                        <td>
                            *
                        </td>
                        <td>
                            <Form.Control onChange={addModule.onChange.description}></Form.Control>
                        </td>
                        <td>
                            <Button onClick={addModule.click.add} size='sm'>Добавить строку</Button>
                            <Button onClick={() => setAddNewRowState(false)} variant='secondary' size='sm'>Отменить</Button>
                        </td>
                    </tr>
                    :<></>
                }
                {data.map((val, i) => <Row data={val} y={i}></Row>)}
            </tbody>
        </Table>
    )
}




/** Справочник периодичности платежей */
const PeriodicPayment = ({
    /** CRUD контроллер */
    controller,
    selectController          
}) => {
    const [selectedY, setSelectedY] = useState(undefined)
    const [data, setData] = useState([])
    
    const loadData = async () => {
        const res = await controller.read()
        
        res.sort((a, b) => a.id - b.id)
        setData(res)
    }

    useEffect(() => {
        loadData()  
        .catch(console.error)
    }, [])


    
    
    const selectStore = {
        currentSelectedY: undefined,

        select_1: {
            options: []
        },

        rowsDefault: {},

        // Механизм который переводит предыдущую строку в состояния отображения
        currentSelectedCallBack: undefined


    }


    const Row = ({
        y,
        data                // Данные, obj = {string: id, string}
    }) => {


        /** Используется для ререндера компонента и подтягивании данных из selectStore */
        const [editMode, setEditMode] = useState(false)

    

        const selectProps = {
            y: y,
            selectedY: selectStore.currentSelectedY,
            selectClick: () => {
                selectStore.currentSelectedY = y

                setEditMode(true)
                
                if(selectStore.currentSelectedCallBack){
                    selectStore.currentSelectedCallBack()
                }

                selectStore.currentSelectedCallBack = () => {
                    setEditMode(false)
                }

            }
        }

        // console.log(data)

        /** Модуль для изменения строки */
        const update = {
            description: {
                data: data.description,
                onChange(e){
                    update.description.data = e.target.value
                }
            },

            amount: {
                data: data.amount,
                onChange(e){
                    update.amount.data = e.target.value
                }
            },

            date: {
                data: data.date,
                onChange(e){
                    update.date.data = e.target.value
                }
            },

            id_periodic: {
                data: data.id_periodic,
                onChange(e){                    
                    update.id_periodic.data = e.target.value
                },
            },

            id_counterparty: {
                data: data.id_counterparty,
                onChange(e){
                    update.id_counterparty.data = e.target.value
                }
            }
            
        }
    
        const click = {
            async save(){
                const param = {
                    description: update.description.data,
                    amount: update.amount.data,
                    date: update.date.data,
                    id_periodic: update.id_periodic.data,
                    id_counterparty: update.id_counterparty.data
                }
                
                await controller.update(data.id, param)

                setSelectedY(undefined)

                loadData()
            },
    
            cancel(){
                selectStore.currentSelectedY = undefined
                
                setEditMode(false)
            },  
    
            async delete(){
                await controller.delete(data.id)

                selectStore.currentSelectedY = undefined
                setEditMode(false)

                loadData()
            }
        }


        return(
            <tr>
                <TD type='default'
                    children={data.id}
                />
                <TD type='description'
                    {...selectProps}
                    onChange={update.description.onChange}
                    children={data.description}
                />

                <NumberTD
                    {...selectProps}

                    onChange={update.amount.onChange}
                    children={data.amount}
                />

                <DateTD
                    {...selectProps}
                    
                    onChange={update.date.onChange}
                    children={data.date}
                />  
                
                <SelectTD
                    {...selectProps}
                    
                    onChange={update.id_periodic.onChange}
                    children={data.periodic}
                    selected={data.id_periodic}
                    selectController={selectController.read}
                    
                />


                
                <SelectDictionaryTD
                    {...selectProps}
                    onChange={update.id_counterparty.onChange}
                    children={data.counterparty}

                    dictionary='counterparties' 
                    name='org_name_provider'
                    placeholder={data.counterparty}
                />

                <EditButtonsTD
                    {...selectProps}
                    cancleClick={click.cancel}
                    saveClick={click.save}
                    deleteClick={click.delete} 
                    >
                </EditButtonsTD>
            </tr>
        )
    }


    const addRowStore = {
        clickChangeMode(){
            addRowStore.changeMode()
        },
        changeMode: undefined
    }


    /** Модуль добавление новой строки */
    const AddNewRow = ({}) => {
        const [editMode, setEditMode] = useState(false)

        addRowStore.changeMode = () => {
            setEditMode(true)
        }

        const data = {}
        const defaultData = () => {
            data.description = undefined
            data.amount = undefined
            data.date = undefined
            data.id_counterparty = undefined
            // data.id_periodic = undefined

            FinanceController.enumPeriodicSelect().then(res => data.id_periodic = res?.[0]?.id)
        }

        defaultData()
        
        const click = {
            async add(){
                await controller.add(data)

                defaultData()
                
                loadData()
                setEditMode(false)
            },

            cancle(){
                setEditMode(false)
            }
        }

        const onChange = {
            description(e){
                data.description = e.target.value
            },
            amount(e){
                data.amount = e.target.value
            },
            date(e){
                data.date = e.target.value
            },
            periodic(e){
                data.id_periodic = e.target.value
            },
            id_counterparty(e){
                data.id_counterparty = e.target.value
            }
        }
    
        return(
            <>
            {editMode
                ?<tr>
                    <td>
                        *
                    </td>
                    <td><Form.Control onChange={onChange.description}/></td>
                    
                    <td><Form.Control type='number' onChange={onChange.amount}/></td>
                    
                    <td><Form.Control type='date' onChange={onChange.date}/></td>
                    
                    <td><SelectWithController onChange={onChange.periodic} selectController={FinanceController.enumPeriodicSelect}/></td>
                    
                    <td>
                        <Select
                            onChange={onChange.id_counterparty}
                            
                            dictionary='counterparties' 
                            name='org_name_provider'
                            placeholder={'Название организации'}
                        />
                    </td>

                    <td>
                        <Button onClick={click.add} size='sm'>Добавить строку</Button>
                        <Button onClick={click.cancle} size='sm' variant='secondary' >Отменить</Button>
                    </td>
                        
                   
                </tr>
                :<></>
            }
            </>

        )
    }

    const generateRows = () => {
        // console.log('generate')
        return(data.map((val, i) => <Row data={val} y={i}></Row>))
    }

    return( 
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Описание</th>
                    <th>Стоимость</th>
                    <th>Дата</th>
                    <th>Периодичность платежа</th>
                    <th>Контрагент</th>
                    <th>
                        <Button variant='success' size='sm' onClick={addRowStore.clickChangeMode}>Добавить строку</Button>
                        
                    </th>

                </tr>
            </thead>

            <tbody>
                <AddNewRow/>
                {generateRows()}
            </tbody>
        </Table>
    )
}




/*  
    @example

    type: 
    controller_field: Название поля пришедшего с бекэенда

    create prop: [
        {
            type: 'id',
            controller_field: 'id',
            tHeaderName: 'id'
        },
        {
            type: 'number', 
            controller_field: 'amount',
            tHeaderName: 'Сумма'
        }
    ]

*/

// class DataContainer{
//     constructor()(
//         this.data 
//     )
// }


const InputFactory = ({type, onChange, selectDictionaryProps, selectController}) => {
    let reactHtml = undefined

    if(type === 'number')
        reactHtml = <Form.Control type='number' onChange={onChange}/>
    
    else if(type === 'date')
        reactHtml = <Form.Control type='date' onChange={onChange}/>
    

    else if(type === 'text')
        reactHtml = <Form.Control onChange={onChange}/>
    

    if(type === 'selectDictionary'){
        reactHtml = 
            <Select
                onChange={onChange}
                dictionary={selectDictionaryProps.dictionary}
                name={selectDictionaryProps.name}
            />
    }

    if(type === 'select'){
        reactHtml = 
            <SelectWithController
                onChange={onChange}
                selectController={selectController}
            />   
    }

    if(type === 'reactSelect'){
        const adapterCallback = (e) => {
            const event = {
                target: {
                    value: e.value
                }
            }
            onChange(event)
        }
        
        reactHtml = 
            <ReactSelectWithController
                onChange={adapterCallback}
                selectController={selectController}
            />
    }

    return <td>{reactHtml}</td>            
}


const crudStore = makeAutoObservable({
    data: [],
    crud: controllerPayment,
    async read(){
        crudStore.data = await controllerPayment.read()
    },

    is_init: false,
    async init(){
        await crudStore.read()
        crudStore.is_init = true
    },
    
})



const Graph = observer(({crudStore}) => {


    useEffect(() => {
        if(!crudStore.is_init)
            crudStore.read()
    }, [])

    const addHandler = async () => {
        await crudStore.crud.add({
            amount: 1122,
            id_counterparty: 6,
            description: 'Asdasd',
        })
        await crudStore.read()
    }

    return(
        <>
            <Button
                onClick={addHandler}
                children='Add'
            />
            <div>{crudStore.data.length}</div>
        </>
    )
})

/** Справочник периодичности платежей */
const Payment = observer(({
    /** CRUD контроллер */
    controller,          
    crudStore
}) => {
    const [selectedY, setSelectedY] = useState(undefined)
    // const [data, setData] = useState([])
    

    const [sortColumn, setSortColumn] = useState(0)
    const [sortType, setSortType] = useState('increase')         // 1: 

    const loadData = crudStore.read

    function sortTable(){
        

        
        
        const keys = [
            'id',
            'counterparty',
            'amount',
            'description',      // Описание
            'date',      // Дата оплаты
            'status',      // Статус
            'periodic_payment',      // Периодический платеж 
            'priority',      // Приоритет
            'login',      // Пользователь
        ]

        const types = [
            'number',
            'string',
            'number',
            'string',      // Описание
            'date',      // Дата оплаты
            'string',      // Статус
            'string',      // Периодический платеж 
            'number',      // Приоритет
            'string',      // Пользователь
        ]
      
        let key = keys[sortColumn]
        let type = types[sortColumn]

        if(key === undefined){      // Заглушка
            key = 'id'
            type = 'number'
        }

        let arr = [...toJS(crudStore.data)]
        console.log(arr);
        const isIncrease = sortType === 'increase'

        if(type === 'number'){
            sortNumber(arr, key, isIncrease)
        }

        else if(type === 'date'){
            arr.forEach(obj => obj.date = new Date(obj.date))
            sortDate(arr, key, isIncrease)
        }

        else if(type === 'string'){
            
            sortString(arr, key, isIncrease)
        }
        
        return arr
    }

    let viewData = sortTable()

    useEffect(() => {
        if(!crudStore.is_init)
            loadData()
    }, [])


    const selectStore = {
        currentSelectedY: undefined,

        select_1: {
            options: []
        },

        rowsDefault: {},

        // Механизм который переводит предыдущую строку в состояния отображения
        currentSelectedCallBack: undefined
    }

       
    const fields = [
        'id_counterparty', 'id_user', 'id_status',
        'date', 'amount', 'description', 'priority', 'url', 'id_periodic_payment'
    ]

    class addOrUpdateContainer{
        constructor(fields){
            this.data = {}
            this.onChanges = {}

            fields.forEach(key => {
                this.data[key] = undefined
                this.onChanges[key] = undefined
                
                this.onChanges[key] = (e) => {
                    this.data[key] = e.target.value
                    // console.log(this.data);
                }
            })
        }
    }



    const Row = ({
        y,
        data: row_data                // Данные, obj = {string: id, string}
    }) => {


        /** Используется для ререндера компонента и подтягивании данных из selectStore */
        const [editMode, setEditMode] = useState(false)


        const selectProps = {
            y: y,
            selectedY: selectStore.currentSelectedY,
            selectClick: () => {
                selectStore.currentSelectedY = y

                setEditMode(true)
                
                if(selectStore.currentSelectedCallBack){
                    selectStore.currentSelectedCallBack()
                }

                selectStore.currentSelectedCallBack = () => {
                    setEditMode(false)
                }

            }
        }


     

        const updateContainerInstance = new addOrUpdateContainer(fields)



        const click = {
            async save(){
                await controller.update(row_data.id, updateContainerInstance.data)
                setSelectedY(undefined)
                loadData()
            },
    
            cancel(){
                selectStore.currentSelectedY = undefined
                setEditMode(false)
            },  
    
            async delete(){
                await controller.delete(row_data.id)
                selectStore.currentSelectedY = undefined
                setEditMode(false)
                loadData()
            }
        }

        // const inputObj = [
        //     {
        //         type: 'default',
        //         field: 'id'
        //     },
        //     {
        //         type: 'selectDictionary',
        //         field: 'counterparty',
        //         selectField: 'id_counterparty',
                
        //         selectDictionaryProps: {
        //             dictionary: 'counterparties',
        //             name: 'org_name_provider'
        //         }
        //     },

            
        // ]

        return(
            <tr>
                <TD type='default'
                    children={row_data.id}
                />

                {/* Контрагент: SelectDictTD */}
                <SelectDictionaryTD
                    {...selectProps}
                    // onChange={update.id_counterparty.onChange}
                    onChange={updateContainerInstance.onChanges.id_counterparty}
                    children={row_data.counterparty}

                    dictionary='counterparties' 
                    name='org_name_provider'
                    placeholder={row_data.counterparty}
                />

                {/*   Сумма: amount */}
                <NumberTD
                    {...selectProps}
                    // onChange={update.amount.onChange}
                    onChange={updateContainerInstance.onChanges.amount}
                    children={row_data.amount}
                />

                 {/*   Описание: description */}
                 <DescriptionTD
                    {...selectProps}
                    // onChange={update.description.onChange}
                    onChange={updateContainerInstance.onChanges.description}
                    children={row_data.description}
                />

                {/*   Дата оплаты: DateTD */}
                <DateTD
                    {...selectProps}
                    // onChange={update.date.onChange}
                    onChange={updateContainerInstance.onChanges.date}
                    children={row_data.date}
                />

                {/*   Статус: SelectTD */}
                <ReactSelectTD
                    {...selectProps}
                    // onChange={update.id_status.onChange}
                    onChange={updateContainerInstance.onChanges.id_status}
                    
                    selectController={FinanceController.statusPaymentSelect}
                    children={row_data.status}
                />

                {/* Периодический платеж: ???  */}
                <ReactSelectTD
                    {...selectProps}
                    // onChange={update.id_periodic_payment.onChange}
                    onChange={updateContainerInstance.onChanges.id_periodic_payment}
                    
                    selectController={FinanceController.periodicPaymentSelect}
                    children={row_data.periodic_payment}
                />

                {/*  Приоритет: priority */}
                <DescriptionTD
                    {...selectProps}
                    // onChange={update.priority.onChange}
                    onChange={updateContainerInstance.onChanges.priority}
                    children={row_data.priority}
                />

                {/* Пользователь: id_user  */}
                <ReactSelectTD
                    {...selectProps}
                    // onChange={update.id_user.onChange}
                    onChange={updateContainerInstance.onChanges.id_user}
                    selectController={FinanceController.userSelect}
                    children={row_data.login}

                    // // dictionary={''}
                    // name={'name'}
                />
                
     

                {/*  Ссылка: URL */}
                <UrlTD
                    {...selectProps}
                    children={row_data.url}
                    // onChange={update.url.onChange}
                    onChange={updateContainerInstance.onChanges.url}
                />

                <EditButtonsTD
                    {...selectProps}
                    cancleClick={click.cancel}
                    saveClick={click.save}
                    deleteClick={click.delete} 
                    >
                </EditButtonsTD>
            </tr>
        )
    }


    const addRowStore = {
        clickChangeToAddRowMode(){
            addRowStore.changeToAddRowMode()
        },
        changeToAddRowMode: undefined,
        clickChangeToAddPeriodicPayment(){
            addRowStore.changeToAddPeriodicPayment()
        },
        changeToAddPeriodicPayment: undefined,
    }


    /** Модуль добавление новой строки */
    const AddNewRow = ({}) => {
        /* editModeEnum: 
                0. Выключен
                1. Режим добавление новой строки
                2. Режим добавления периодического платежа
        */
        const [editMode, setEditMode] = useState(0)

        // addRowStore.changeMode = setEditMode
            
        addRowStore.changeToAddRowMode = () => {
            setEditMode(1)
        }

        addRowStore.changeToAddPeriodicPayment = () => {
            setEditMode(2)
        }
        
        const click = {
            async add(){
                console.log(createContainerInst.data);

                await controller.add(createContainerInst.data)
                // defaultData()
                loadData()
                setEditMode(0)
            },

            async addPeriodicPayment(){
                let data = await api('GET', 'finances/periodic-payment', 'read')

                data = data.find(obj => obj.id === createContainerInst.data.id_periodic_payment)
                data = replaceKeys(data, {'id': 'id_periodic_payment'})

                await controller.add(data)
                loadData()
                setEditMode(0)
            },

            cancle(){
                setEditMode(0)
            }
        }

        const createContainerInst = new addOrUpdateContainer(fields)

        


    
        return(
            <>
            {editMode === 1
                ?<tr>
                    <td>
                        *
                    </td>
                    {/* <td><Form.Control onChange={onChange.description}/></td>
                     */}
                     {/* Контрагент */}
                     <InputFactory
                        type='selectDictionary'
                        onChange={createContainerInst.onChanges.id_counterparty}   
                        selectDictionaryProps={{
                        dictionary: 'counterparties',
                        name: 'org_name_provider'
                        }}
                    />
                    {/* Сумма */}
                    <InputFactory type='number' onChange={createContainerInst.onChanges.amount}/>
                    {/* Описание */}
                    <InputFactory type='text' onChange={createContainerInst.onChanges.description}/>
                    {/* Дата оплаты */}
                    <InputFactory type='date' onChange={createContainerInst.onChanges.date}/>
                    {/* Статус */}
                    <InputFactory 
                        type='reactSelect'
                        onChange={createContainerInst.onChanges.id_status}
                        selectController={FinanceController.statusPaymentSelect}
                    />
                    {/* Периодический платеж */}
                    
                    
                    {/* Периодические платеж select */}
                    <InputFactory
                        type='reactSelect'
                        onChange={createContainerInst.onChanges.id_periodic_payment}
                        selectController={FinanceController.periodicPaymentSelect}
                    />

                    {/* Приоритет */}
                    <InputFactory type='number' onChange={createContainerInst.onChanges.priority}/>

                    {/* Пользователь */}
                    
                    <InputFactory
                        type='reactSelect'
                        onChange={createContainerInst.onChanges.id_user}
                        selectController={FinanceController.userSelect}
                    />
                    

                    {/* Ссылка */}

                    <InputFactory
                        type='text'
                        onChange={createContainerInst.onChanges.url}
                    />

                    <td>
                        <Button onClick={click.add} size='sm'>Добавить строку</Button>
                        <Button onClick={click.cancle} size='sm' variant='secondary' >Отменить</Button>

                    </td>
                        
                   
                </tr>
                :<></>
            }
            

            {editMode === 2?
                <>
                    
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    {/* Периодические платеж select */}
                    <InputFactory
                        type='reactSelect'
                        onChange={createContainerInst.onChanges.id_periodic_payment}
                        selectController={FinanceController.periodicPaymentSelect}
                    />
                    <td/>
                    <td/>
                    <td/>

                    <td>
                        <Button onClick={click.addPeriodicPayment} size='sm'>Добавить строку</Button>
                        <Button onClick={click.cancle} size='sm' variant='secondary' >Отменить</Button>
                    </td>
                </>
                :<></>
            }
            </>
        )
    }

    
    const Rows = () => viewData.map((val, i) => <Row data={val} y={i}></Row>);

    const Header = ({}) => {
        const names = [
            'ID',
            'Контрагент',
            'Сумма',
            'Описание',
            'Дата оплаты',
            'Статус',
            'Периодический платеж',
            'Приоритет',
            'Пользователь',
            'Ссылка',
        ];

        // const columns = [
        //     { name: 'ID', type: 'Number' },
        //     { name: 'Контрагент', type: 'Number' },
        //     { name: 'Сумма', type: 'Number' },
        //     { name: 'Описание', type: 'Number' },
        //     { name: 'Дата оплаты', type: 'Number' },
        //     { name: 'Статус', type: 'Number' },
        //     { name: 'Периодический платеж', type: 'Number' },
        //     { name: 'Приоритет', type: 'Number' },
        //     { name: 'Пользователь', type: 'Number' },
        //     {
        //         name: 'Ссылка',
        //         type: 'Number',
        //     },
        // ];

        const changeOrderNode = (e) => {
            const order = getOrderInSiblings(e)

            if(sortColumn === order){
                if(sortType === 'increase')
                    setSortType('decrease')
                else
                    setSortType('increase')
            }            
                
            setSortColumn(order)
        }

        return (
            <thead>
                <tr>
                    {names.map((name, i) => {
                        if(i === sortColumn && sortType === 'decrease')
                            return(<th onClick={changeOrderNode} style={{background: 'red'}}>{name}</th>)

                        if(i === sortColumn && sortType === 'increase')
                            return(<th onClick={changeOrderNode} style={{background: 'green'}}>{name}</th>)

                        return(<th onClick={changeOrderNode}>{name}</th>)
                        }
                    )}
                    <th>
                        <Button
                            variant="success"
                            size="sm"
                            onClick={addRowStore.clickChangeToAddRowMode}
                        >
                            Добавить строку
                        </Button>
                        <Button
                            size="sm"
                            onClick={
                                addRowStore.clickChangeToAddPeriodicPayment
                            }
                        >
                            Добавить периодический платеж
                        </Button>
                    </th>
                </tr>
            </thead>
        );
    };

    return (
        <Table striped bordered hover>
            <Header />

            <tbody>
                <AddNewRow />
                <Rows />
            </tbody>
        </Table>
    );
})


const Finance = () => {
    const [showEnumPaymentStatusTable, setShowEnumPaymentStatusTable] = useState(false);
    const [showEnumPeriodicPayment, setShowEnumPeriodicPayment] = useState(false);
    const [showPeriodicPayment, setShowPeriodicPayment] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
        

    const ButtonsStyle = {
        marginRight: '40px',
        width: '200px'
    }

    const ButtonsProps = {
        style: ButtonsStyle,
        variant: 'dark'
    }

    return(
        <>  
            <Modal show={showEnumPaymentStatusTable} onHide={() => setShowEnumPaymentStatusTable(false)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title style={{'fontWeight': 'bold'}}>Справочник статусов платежей</Modal.Title>
                    
                </Modal.Header>
                <EnumTable controller={controllerEnumPaymentStatus}/>
            </Modal>

            <Modal show={showEnumPeriodicPayment} onHide={() => setShowEnumPeriodicPayment(false)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title style={{'fontWeight': 'bold'}}>Справочник периодичности платежей </Modal.Title>
                </Modal.Header>
                <EnumTable controller={controllerFinanceEnumPeriodicPayment}/>
            </Modal>

            <Modal show={showPeriodicPayment} onHide={() => setShowPeriodicPayment(false)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title style={{'fontWeight': 'bold'}}>Периодические платежи</Modal.Title>
                </Modal.Header>

                <PeriodicPayment
                    controller={controllerPeriodicPayment}
                    selectController={selectControllerEnumPeriodicPayment}
                />

            </Modal>
            
            <Container style={{marginBottom: '20px'}}>
                
                <Row>
                    <Button onClick={() => setShowEnumPaymentStatusTable(true)} {...ButtonsProps}>
                        Справочник статусов платежей
                    </Button>

                    <Button onClick={() => setShowEnumPeriodicPayment(true)} {...ButtonsProps}>
                        Справочник периодичности платежей
                    </Button>

                    <Button onClick={() => setShowPeriodicPayment(true)} {...ButtonsProps}>
                        Периодические платежи
                    </Button>

                    <Button onClick={() => setShowUsers(true)} {...ButtonsProps}>
                        Пользователи
                    </Button>
                </Row>
            </Container>
            
            {/* <FinanceTable/>
            <PeriodicPaymentTable/> */}


            <Graph 
                crudStore={crudStore}
            />

            <Payment
                controller={controllerPayment}
                crudStore={crudStore}
            />


            {/* <EnumTable controller={controllerFinanceEnumPeriodicPayment}/> */}
            {/* <EnumTable controller={controllerEnumPaymentStatus}/> */}
            {/* <PeriodicSelector component_id={'1'}/> */}
            {/* <PeriodicSelector component_id={'2'}/> */}
            {/* <RegularPayment /> */}
            {/* <Payments/> */}

            
                     
            
        </>
    )
}


export default Finance;

