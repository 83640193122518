import React from 'react';
import classnames from 'classnames';
import * as CalUtilities from './CalUtilities';
import api from '../../Api';
import './Calendar.css';
import Swal from 'sweetalert2';
import { Modal, Button, Table, Spinner } from 'react-bootstrap';
import Orders from '../../Pages/Orders';

export default class Calendar extends React.Component {
    static defaultProps = {
        date: new Date(),
        years: [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekDayNames: ['Пн', 'Вт', 'Ср', 'Чт' , 'Пт', 'Сб', 'Вс'],
        onChange: Function.prototype
        
    };

    state = {
        date: this.props.date,
        currentDate: new Date(),
        selectedDate: null,
        CalendarModalParam:{show:false},
        getByDate:[], //нужно для запроса в разграничении модалки и swal-уведомления
        datesWithOrders:[],
    };

    get year() {
        return this.state.date.getFullYear();
    }

    get month() {
        return this.state.date.getMonth();
    }

    get nextMonth() {
        return this.state.date.getMonth()+1;
    }

    get plusTwoMonth() {
        return (this.state.date.getMonth()+2)%12;
    }

    get plusThreeMonth() {
        return (this.state.date.getMonth()+3)%12;
    }

    get day() {
        return this.state.date.getDate()-1;
    }

    handlePrevMonthButtonClick = () => {
        const date = new Date(this.year, this.month - 1);
        
        this.setState({ date });
    };

    handleNextMonthButtonClick = () => {
        const date = new Date(this.year, this.month + 1);
        
        this.setState({ date });
    };

    handleSelectChange = () => {
        const year = this.yearSelect.value;
        const month = this.monthSelect.value;

        const date = new Date(year, month);

        this.setState({ date });
    };

    handleNextMonthChange = () => {
        const month = this.nextMonthSelect.value;

        const date = new Date(month);

        this.setState({ date });
    };

    handlePlusTwoMonthChange = () => {
        const plus_two_month = this.plusTwoMonthSelect.value;

        const date = new Date(plus_two_month);

        this.setState({ date });
    };

    handlePlusThreeMonthChange = () => {
        const plus_three_month = this.plusThreeMonthSelect.value;

        const date = new Date(plus_three_month);

        this.setState({ date });
    };

    getBackForToday = () => {
        const date = new Date;
        
        this.setState({ date });
    };

    handleDayClick = date => {
        this.setState({ selectedDate: date }, () =>
        {
        api("GET", "orders", "get-orders-by-date", {date:this.state.selectedDate})
        .then(Result=>{this.setState({getByDate:Result, isLoad:true}, ()=>{

            if(this.state.getByDate.length==0)
            {
                Swal.fire({
                    icon: 'info',
                    title: 'Нет заказов на выбранную дату',
                    showConfirmButton: false,
                 })
            }
        else {
            
        this.setState({ CalendarModalParam:{show:true} }); }
        // console.log('show modal');Ф
        // console.log(this.state.CalendarModalParam);
        this.props.onChange(date);
        // console.log(date.toISOString().substring(10,-10));
        // console.log(this.state.getByDate);
        }
    )})
    });
    };

    componentDidMount()
        {
            api("GET", "orders", "get-dates")
            .then(Result=>{this.setState({datesWithOrders:Result})});
            // console.log(this.state.datesWithOrders);
        }

    render() {
        const { years, monthNames, weekDayNames } = this.props;
        const {  datesWithOrders, currentDate, selectedDate, CalendarModalParam } = this.state;
        const monthData = CalUtilities.getMonthData(this.year, this.month);
        const nextMonthData = CalUtilities.getNextMonthData(this.year, this.month);
        const plusTwoMonthData = CalUtilities.getPlusTwoMonthData(this.year, this.month);
        const plusThreeMonthData = CalUtilities.getPlusThreeMonthData(this.year, this.month);
        return (<div>
            <CalendarModal show={CalendarModalParam.show}
                onHide={()=>{
                    this.setState({CalendarModalParam:{show:false}})
                }}
                // handleDayClick={this.handleDayClick}
                selectedDate={this.state.selectedDate}
                Entities={this.state.getByDate}
                param={CalendarModalParam}
            />
            <div className="year">
            <select
                ref={element => this.yearSelect = element}
                value={this.year}
                onChange={this.handleSelectChange}
            >
                {years.map(year =>
            <option key={year} value={year}>{year}</option> 
                )}
            </select>
            </div>
            <div className="hat">
            <header>
                <table>
                    <td width="97.53%"><button type="button" class="btn btn-info" onClick={this.handlePrevMonthButtonClick}>{'<'}</button></td>
                    <td><button type="button" class="btn btn-info" onClick={this.handleNextMonthButtonClick}>{'>'}</button></td>
                </table>
            </header>
            </div>
            <div className="months">
            <select
                ref={element => this.monthSelect = element}
                value={this.month}
                onChange={this.handleSelectChange}
            >
                {monthNames.map((name, index) =>
                <option key={name} value={index}>{name}</option>
                )}
            </select>
            <select
                ref={element => this.nextMonthSelect = element}
                value={this.nextMonth}
            >
                {monthNames.map((name, index) =>
                <option key={name} value={index}>{name}</option>
                )}
            </select>
            <select
                ref={element => this.plusTwoMonthSelect = element}
                value={this.plusTwoMonth}
            >
                {monthNames.map((name, index) =>
                <option key={name} value={index}>{name}</option>
                )}
            </select>
            <select
                ref={element => this.plusThreeMonthSelect = element}
                value={this.plusThreeMonth}
            >
                {monthNames.map((name, index) =>
                <option key={name} value={index}>{name}</option>
                )}
            </select>
            </div>
            
{/* ТЕКУЩИЙ МЕСЯЦ */}
            <div className="calendar">
                <table>
                    <thead>
                        <tr>
                            {weekDayNames.map(name =>
                                <th key={name}>{name}</th>    
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {monthData.map((week, index) =>
                            <tr key={index} className="week">
                                {week.map((date, index) => date ?
                                    <td
                                        key={index}
                                        className={classnames('day', {
                                            'today': CalUtilities.areEqual(date, currentDate),
                                            'selected': CalUtilities.areEqual(date, selectedDate),
                                            'orderExists': this.state.getByDate!=0,
                                        }
                                        )}
                                        onClick={() => this.handleDayClick(date)}
                                    >{date.getDate()}</td>
                                    :
                                    <td key={index} />
                                )}
                            </tr> 
                        )}
                    </tbody>
                </table>
        </div>
{/* СЛЕДУЮЩИЙ МЕСЯЦ */}
        <div className="calendar-next">
                    <table>
                    <thead>
                        <tr>
                            {weekDayNames.map(name =>
                                <th key={name}>{name}</th>    
                            )}
                        </tr>
                    </thead>
                    <tbody> 
                        {nextMonthData.map((week, index) =>
                            <tr key={index} className="week">
                                {week.map((date, index) => date ?
                                    <td
                                        key={index}
                                        className={classnames('day', {
                                            'today': CalUtilities.areEqual(date, currentDate),
                                            'selected': CalUtilities.areEqual(date, selectedDate),
                                            'orderExists': this.state.getByDate!=0,
                                        }
                                        )}
                                        onClick={() => this.handleDayClick(date)}
                                    >{date.getDate()}</td>
                                    :
                                    <td key={index} />
                                )}
                            </tr> 
                        )}
                    </tbody>
                </table>
        </div>
{/* МЕСЯЦ + 2 */}
            <div className="calendar-plus-two">
                <table>
                    <thead>
                        <tr>
                            {weekDayNames.map(name =>
                                <th key={name}>{name}</th>    
                            )}
                        </tr>
                    </thead>
                    <tbody> 
                        {plusTwoMonthData.map((week, index) =>
                            <tr key={index} className="week">
                                {week.map((date, index) => date ?
                                    <td
                                        key={index}
                                        className={classnames('day', {
                                            'today': CalUtilities.areEqual(date, currentDate),
                                            'selected': CalUtilities.areEqual(date, selectedDate),
                                            'orderExists': this.state.getByDate!=0,
                                        }
                                        )}
                                        onClick={() => this.handleDayClick(date)}
                                    >{date.getDate()}</td>
                                    :
                                    <td key={index} />
                                )}
                            </tr> 
                        )}
                    </tbody>
                </table>
            </div>
{/* МЕСЯЦ + 3 */}
        <div className="calendar-plus-three">
                <table>
                    <thead>
                        <tr>
                            {weekDayNames.map(name =>
                                <th key={name}>{name}</th>    
                            )}
                        </tr>
                    </thead>
                    <tbody> 
                        {plusThreeMonthData.map((week, index) =>
                            <tr key={index} className="week">
                                {week.map((date, index) => date ?
                                    <td
                                        key={index}
                                        className={classnames('day', {
                                            'today': CalUtilities.areEqual(date, currentDate),
                                            'selected': CalUtilities.areEqual(date, selectedDate),
                                            'orderExists': this.state.getByDate!=0,
                                        }
                                        )}
                                        onClick={() => this.handleDayClick(date)}
                                    >{date.getDate()}</td>
                                    :
                                    <td key={index} />
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <button type="button" class="btn btn-success" onClick={this.getBackForToday}>Вернуться к текущей дате</button>
        </div>
        );
    }
}

class CalendarModal extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            ismLoad:false,
            Entities: [],
            date: this.props.selectedDate,
            list:[]
        }
    }

    // componentDidUpdate()
    // {
        
    //         if(this.props.selectedDate && this.state.ismLoad===false)
    //     {
    //         // this.setState({Entities: this.props.Entities, ismLoad:true}, ()=>{console.log(this.props.selectedDate + ' and ' + this.state.ismLoad)});
    //         this.state.Entities = this.props.Entities;
    //     }
       
    // }

    Close = () =>
    {
        this.props.onHide();
        this.setState({ismLoad:false, Entities:[]});
    }

    render()
        {
            const {ismLoad} = this.state;
            this.state.Entities = this.props.Entities;
            return(<Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                {/* {ismLoad?( */}
                <Table striped bordered hover size="sm">
                <thead>
                <tr> 
                    <th>Организация-поставщик</th>
                    <th>Организация-покупатель</th>
                    <th>Сумма контракта</th>
                    <th>Дата создания заказа</th>
                    <th>Планируемая дата отгрузки</th>
                    <th>Фактическая дата отгрузки</th>
                    <th>Статус заказа</th>
                    <th>Статус оплаты</th>
                </tr>
                </thead>
                    <tbody>
                        {this.state.Entities.map((ord, key)=>
                        
                        <tr key={key}>
                            <td>{ord.org_name_provider}</td>
                            <td>{ord.org_name_customer}</td>
                            <td>{ord.summ}</td>
                            <td>{ord.date_start}</td>
                            <td>{ord.date_plan}</td>
                            <td>{ord.date_fact}</td>
                            <td>{ord.order_status}</td>
                            <td>{ord.status}</td>
                        </tr>)}
                    </tbody>
                    </Table>
                    {/* ):(<Spinner animation="border"/>)} */}
                {/* <Table striped bordered hover size="sm">
                <thead>
                <tr> 
                    <th>Состав заказа</th>
                    <th>Количество деталей</th>
                </tr>
                </thead>
                <tbody>
                    {this.state.Entities.map((prod, key)=>
                    <td key={key} width="50%">
                        { prod.list.map(prod2 =>
                        <tr>{prod2.id_product}</tr>)} 
                    </td>)}
                    {this.state.Entities.map((prod, key)=>
                        <td key={key} width="100%">
                     { prod.list.map(prod3 =>          
                        <tr>{prod3.count} шт.</tr>)}
                        </td>)}
                </tbody>
                </Table> */}
                <Button onClick={this.Close}>Закрыть</Button>
            </Modal>
            );
        }
}