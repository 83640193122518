import React, {Component, useState} from 'react';
import { Container, Spinner, Row, Col, Button, Modal, Table, Form, Tabs, Tab } from 'react-bootstrap';
import api from '../Api'
import store from '../Store'
import BootstrapTable from 'react-bootstrap-table-next';
import Select from './../Components/AutoSelect';
import SimpleSelect from './../Components/SimpleSelect';
import Swal from 'sweetalert2';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

class Events extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            prods:[],
            serial:'',
            Cols:[{
                    dataField: 'serial',
                    text: 'Серийный номер'
                    }, {
                    dataField: 'name',
                    text: 'Марка'
                    }, {
                    dataField: 'created_date',
                    text: 'Дата выпуска'
                    }, {
                    dataField: 'status',
                    text: 'Статус'
                    }],
            addModal: false,
            evsModal: false,
            addEvent: false
        };

        this.LoadProds = this.LoadProds.bind(this);
        this.AddNewEvent = this.AddNewEvent.bind(this);
        this.showAddModal = this.showAddModal.bind(this);
    }

    componentDidMount()
    {
        this.LoadProds()
    }

    AddNewEvent(){
        this.setState({addEvent:true,evsModal:false});
    }

    showAddModal () {
        this.setState({addModal:true});
    }

    LoadProds(){
        api('GET','objects','find',{model:'Products'}).then(r =>r.success? this.setState({prods:r.result}):console.log(r))
    }

    render()
    {

        const {prods,Cols,addModal,evsModal,serial,addEvent} = this.state;

        const rowEvents = {
            onDoubleClick: (e, row, rowIndex) => {
                this.setState({serial:row.serial,evsModal:true})
            }
            };
//         console.log(store);
        return(
    <>
        <div className="text-right"><Button size="md" onClick={this.showAddModal}>Добавить</Button></div>

        <BootstrapTable keyField='serial' data={ prods } columns={ Cols }             rowEvents={ rowEvents } noDataIndication={ () => <NoDataIndication /> }/>

        <AddProdModal show={addModal}
            onHide={()=>{
                this.setState({addModal:false});
            }}
            onUpdate={this.LoadProds}/>
        <EventsModal show={evsModal}
            onHide={()=>{
                this.setState({evsModal:false});
            }}
            serial={serial}
            addNewEvent={this.AddNewEvent}
            />
        <AddEventModal show={addEvent}
            onHide={()=>{
                this.setState({addEvent:false,evsModal:true});
                this.LoadProds();
            }}
            serial={serial}
            />
    </>
            )
    }
};

const NoDataIndication = () => (
  <div>
  No result found
  </div>
);


class AddProdModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={serial:'', name: '', created_date: '', isLoading:false};
        this.Add = this.Add.bind(this);
        this.Close = this.Close.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentWillUnmount()
    {
        this.resetState();
    }

	resetState(){
        this.setState({serial:'', name: '', created_date: '', isLoading:false});
    }

	Add()
    {
        const {serial,name,created_date} = this.state
        api("POST", "objects", "new?model=Products",{serial:serial,name:name,created_date:created_date,status:'Добавлен'})
            .then(r => {
            if(r.success){
                this.props.onUpdate();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка сохранения',
                    text: 'При выполнении операци произошла ошибка: '+r.error,
                    });
            }
            this.Close();
        });
    }

    Close()
    {
		this.props.onHide();
		this.resetState();
    }

    render()
    {
        const {onUpdate, ...otherProps} = this.props
        return(
			<Modal
            {...otherProps}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          	>
              <Form>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Добавить аппарат
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Введите серйный номер</Form.Label>
            <Form.Control type="text" name="serial" size="sm"
                value={this.state.serial}
                onChange={(e)=>{this.setState({serial:e.target.value})}}/>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Введите модель</Form.Label>
            <Form.Control type="text" name="name" size="sm"
                value={this.state.name}
                onChange={(e)=>{this.setState({name:e.target.value})}}/>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Дата выпуска</Form.Label>
            <Form.Control type="date" name="created_date" size="sm"
                value={this.state.created_date}
                onChange={(e)=>{this.setState({created_date:e.target.value})}}/>
            </Form.Group>
            </Modal.Body>
            <Modal.Footer>
			<Button variant="primary" type="submit" onClick={(e)=>{e.preventDefault(); this.Add();}}>Добавить</Button>
            <Button onClick={this.Close}>Отмена</Button>
            </Modal.Footer>
            </Form>
          </Modal>
        );
    }
}

class EventsModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={
            isLoading:false,
            evs:[],
            Cols:[{
                    dataField: 'created_at',
                    text: 'Дата'
                    }, {
                    dataField: 'statusname',
                    text: 'Событие'
                    }, {
                    dataField: 'ownername',
                    text: 'Участник'
                    }, {
                    dataField: 'username',
                    text: 'Пользователь'
                    }, {
                    dataField: 'comment',
                    text: 'Комментарий'
                    }]
        };
        this.LoadEvents = this.LoadEvents.bind(this);
        this.Close = this.Close.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentWillUnmount()
    {
        this.resetState();
    }

    LoadEvents(){
        api('GET','events','fromserial',{serial:this.props.serial}).then(r =>r.success? this.setState({evs:r.result}):console.log(r))
//         console.log(store.User.ID)
    }

	resetState(){
        this.setState({evs:[],isLoading:false});
    }

    Close()
    {
		this.props.onHide();
		this.resetState();
    }

    render()
    {
        const {evs,Cols} = this.state;
        const {addNewEvent, ...otherProps} = this.props
        return(
			<Modal
            {...otherProps}
            onShow = {this.LoadEvents}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          	>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Перечень событий аппарата {this.props.serial}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BootstrapTable keyField='created_at' data={ evs } columns={ Cols } noDataIndication={ () => <NoDataIndication /> }/>
            </Modal.Body>
            <Modal.Footer>
			<Button variant="primary" type="submit" onClick={(e)=>{e.preventDefault(); addNewEvent();}}>Добавить</Button>
            <Button onClick={this.Close}>Отмена</Button>
            </Modal.Footer>
          </Modal>
        );
    }
}

class AddEventModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={author:'', ev: '', comment: '', isLoading:false};
        this.Add = this.Add.bind(this);
        this.Close = this.Close.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentWillUnmount()
    {
        this.resetState();
    }

	resetState(){
        this.setState({author:'', event: '', comment: '', isLoading:false});
    }

	Add()
    {
        const {comment,author,ev} = this.state
        api("POST", "objects", "new?model=Events",{serial:this.props.serial,user_id:store.User.ID,comment:comment,owner:author,event:ev})
            .then(r => {
            if(r.success){
                api("GET", "events", "setstatus",{serial:this.props.serial}).then(r => {})
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка сохранения',
                    text: 'При выполнении операци произошла ошибка: '+r.error,
                    });
            }
            this.Close();
        });
    }

    Close()
    {
		this.props.onHide();
		this.resetState();
    }

    render()
    {
        return(
			<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          	>
              <Form>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Добавить событие для аппарата {this.props.serial}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Событие (добавьте знак + что бы событие меняло статус)</Form.Label>
            <Select placeholder="Введите.."
                    model="EventsStatuses"
                    onChange={(e)=>{this.setState({ev:e.target.value})}}/>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Автор</Form.Label>
            <SimpleSelect placeholder="Введите.."
                    model="User"
                    onChange={(e)=>{this.setState({author:e.target.value})}}/>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Комментарий</Form.Label>
            <Form.Control as="textarea" name="created_date" size="lg"
                value={this.state.comment}
                onChange={(e)=>{this.setState({comment:e.target.value})}}/>
            </Form.Group>
            </Modal.Body>
            <Modal.Footer>
			<Button variant="primary" type="submit" onClick={(e)=>{e.preventDefault(); this.Add();}}>Добавить</Button>
            <Button onClick={this.Close}>Отмена</Button>
            </Modal.Footer>
            </Form>
          </Modal>
        );
    }
}


export default Events;
