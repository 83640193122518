import React, {Component} from 'react';
import store from '../Store';

class User extends Component{

    render()
    {
        return(<div>{store.token}</div>);
    }
};

export default User;