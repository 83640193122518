import React, {Component} from 'react';
import {Route,  Switch} from "react-router-dom";
import { observer } from 'mobx-react'
import store, { Check, Logout } from './Store';
import User from './Components/User';
import MiniChat from './Components/MiniChat';
import AuthPage from './AuthPage';
import RegForm from './RegForm';
import RestoreForm from './RestoreForm';
import Manufacture from './Pages/Manufacture'
import Supply from './Pages/Supply'
import Models from './Pages/Models';
import Model from './Pages/Model';
import Orders from './Pages/Orders';
import Warehouse from './Pages/Warehouse';
import Counterparties from './Pages/Counterparties';
import Messagelist from './Pages/Messagelist';
import TaskManager from './Pages/TaskManager';
import PassMan from './Pages/PassMan';
import Notes from './Pages/Notes';
import Repository from './Pages/Repository';
import IntAccount from './Pages/IntAccount';
import IntAccounts from './Pages/IntAccounts';
import Profile from './Pages/Profile';
import TabStatus from './Pages/TabStatus';
import DeviceFix from './Pages/DeviceFix';
import RegContract from './Pages/RegContract';
import Events from './Pages/Events';
import Finance from './Pages/Finance';
import Test from './Pages/Test';

import { Container, Row, Col, Button, Navbar, Nav, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap';
import './style.css';
import * as Icon from 'react-bootstrap-icons';
import api from './Api'

const App = observer(()=>{
  if(store.User.token && !store.User.ID)
    Check(store.User.token);
    return (
    <div className="App bg-secondary" style={{marginLeft:'-15px', paddingRight:'-15px', overflowX:'hidden', overflowY:'hidden'}}>
        {store.User.token?(
          <Container fluid>
            <Row noGutters>
              <Col className="col-md-auto" style={{marginRight:'15px'}}>
				        <LeftMenu />
              </Col>
              <Col>
                <Container fluid style={{marginLeft:'-30px', width:'calc(100% + 60px)'}}>
                    <Navbar expand="lg" dark bg="dark">
						<Nav mr="auto">
							<Nav.Item><Nav.Link href="#" onClick={()=>{Logout()}}><Icon.DoorOpen /></Nav.Link></Nav.Item>
						</Nav>
					</Navbar>
                </Container>
                <Switch>
                <div className="card bg-light mt-2 p-2">
                  <Route exact path="/" component={MiniChat}/>
                  <Route path="/FS">FS</Route>
                  <Route path="/manufacture/:id"    component={Manufacture}/>     
                  <Route path="/manufacture"        component={Manufacture}/>
                  <Route path="/MF/:id"             component={MF}/>
                  <Route path="/MF"                 component={MF}/>
                  <Route path="/supply"             component={Supply}/>
                  <Route path="/models"             component={Models}/>
                  <Route path="/model/:id"          component={Model}/>
                  <Route path="/orders"             component={Orders}/>
                  <Route path="/warehouse"          component={Warehouse}/>
                  <Route path="/counterparties"     component={Counterparties}/>
                  <Route path="/messagelist"        component={Messagelist}/>
                  <Route path="/notes"              component={Notes}/>
				          <Route path="/taskmanager"        component={TaskManager}/>
				          <Route path="/passman"            component={PassMan}/>
                  <Route path="/IntAccount/:id"     component={IntAccount}/>
				          <Route path="/IntAccounts"        component={IntAccounts}/>
                  <Route path="/profile"            component={Profile}/>
                  <Route path="/TabStatus"          component={TabStatus}/>
                  <Route path="/DeviceFix"          component={DeviceFix}/>
                  <Route path="/RegContract"        component={RegContract}/>
                  <Route path="/events"             component={Events}/>
                  <Route path="/repository"         component={Repository}/>
                  <Route path="/finance"            component={Finance}/>
                  <Route path="/test"               component={Test}/>
                  
                </div>
                </Switch>
              </Col>
            </Row>
          </Container>):
          <Switch>
          <Route path="/reg" component={RegForm}/>
          <Route path="/restore" component={RestoreForm}/>
          (<AuthPage/>)
         </Switch>
          }
      <User/>
    </div>
  );
});

class MF extends Component{
  constructor(props)
  {
    super(props);
    console.log(this.props);
  }
  
  
  render()
  {
    return(<div>
          <Switch>

            <Route path="*/edit">11
              {this.props.match.params.id}
            </Route>
            <Route path="*/:id">2</Route>
            <Route exact path="*/">
              home
            </Route>
            
          </Switch>
          </div>);
  }
}

class LeftMenu extends Component{

  constructor(props)
  {
    super(props);
	this.state={
		menu:[],
		btnmn:[],
		ttlmn:'',
		colla:'collapse',
		isLoad:false,
		activekey:'',
		menubutt:['btn-group-vertical align-self-bottom','btn-group align-self-bottom']};
	api("GET","lf","menu").then((result) => {this.setState({menu:result[2], btnmn:result[1], ttlmn:result[0]})});
  }

  render()
  { 
  
  return (<div style ={{height:'100vh', marginRight:'64px'}}>
  <Navbar className="flex-column" bg="dark" variant="dark" style={{height:'100vh', 'position': 'fixed', 'zIndex':'999', 'overflow-y':'auto'}}>
    <Nav className="flex-column align-self-start" activeKey={this.state.activekey} style={{height:'91vh'}}>
		<IndexLinkContainer to="#" active><Nav.Link className="nav-link active" onClick={()=>{ if(this.state.colla === "collapse")
		{
			this.setState({colla:'collapse show', marg:'', isLoad:true});
		} else {
			this.setState({colla:'collapse', marg:'', isLoad:true});
		}; }}>
			{Icon['List'].render({color:'currentColor', size:'1em'})} <a className={this.state.colla}> Aria-ERP</a>
		</Nav.Link></IndexLinkContainer>
		<hr style={{borderStyle: 'solid none', borderWidth: '1px 0', margin: '8px -16px 8px -16px', borderColor:'#fff', opacity:'0.1'}} />
		{this.state.menu.map((paragraph) => <OverlayTrigger key={paragraph.id} placement="right"
				overlay={(this.state.colla === 'collapse')?(
					<Tooltip id={paragraph.id}>
						{paragraph.description}
					</Tooltip>
				):(
					<Tooltip id={paragraph.id} hidden>
						{paragraph.description}
					</Tooltip>
				)}>
				<LinkContainer activeClassName={this.state.activekey} to={paragraph.location}>
					<Nav.Link eventKey={paragraph.location} onClick={()=>(this.setState({activekey:paragraph.location}))}>
						{Icon[paragraph.svg].render({color:'currentColor', size:'1em'})}
						<a className={this.state.colla}> {paragraph.description}</a>
					</Nav.Link>
				</LinkContainer>
			</OverlayTrigger>)}
    </Nav>
  </Navbar></div>);
  }
}

export default App;
