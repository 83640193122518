import React, { Component } from "react";
import { Container} from "react-bootstrap";

class RestoreForm extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
     return (
      <Container>
      </Container>
    );
  }
}

export default (RestoreForm);
