import React, { Component } from 'react';

import Creatable from 'react-select/creatable';
import api from "../Api"

export default class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      options: [],
      value: undefined,
	  label:'',
    };
	this.ReloadSpis = this.ReloadSpis.bind(this);
  }

  componentWillMount()
  {
    api('GET', 'objects', 'list',{model:this.props.model})
    .then((result)=>{
        console.log("Raw obj", result)
        const res = result.success?result.result:[];
        var arr = Object.keys(res).map(function (key) { return {value:res[key]['id'], label:res[key]['name']}; });
        this.setState({options:arr});
        console.log("Select dict out: ",arr)
    });
  }

  ReloadSpis()
  {
	return api('GET', 'objects', 'list',{model:this.props.model})
    .then((result)=>{
        const res = result.success?result.result:[];
        var arr = Object.keys(result.result).map(function (key) { return {value:res[key]['id'], label:res[key]['name']}; });
        this.setState({options:arr});
    })
  }

  onChange = (newValue) => {
    if(!newValue) newValue={value:'', label:''};
    console.log(newValue);
    this.setState({ value: newValue , label: newValue.label});
    const e = {
      target:{
        name: this.props.name,
        type: 'custom-select',
        value: newValue.value,
        label: newValue.label
      }
    };
    this.props.onChange(e);
  }

  onCreateOption = (inputValue) => {
    this.setState({ isLoading: true });
    const obj = {state: /\+/.test(inputValue)?1:0,name:inputValue.replace(/[^а-яА-ЯЁёa-zA-Z_ ]/g, '')}
    api('POST', 'objects', 'new?model='+this.props.model,obj)
    .then((result)=>{
//         console.log(result);
      this.ReloadSpis().then(()=>{
        this.onChange({value:result.model.id, label:result.model.name})
        this.setState({ isLoading: false });
      });

    });
  }
    render() {
      const { isLoading, options, value} = this.state;
      return (
        <Creatable
          name={this.props.name}
          placeholder={this.props.placeholder}
          isClearable
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={this.onChange}
          onCreateOption={this.onCreateOption}
          options={options}
          value={value}
        />
      );
    }
  }
