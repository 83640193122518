
/** 
 * Заменяет ключи у объекта 
 * @example 
 * // Заменяет id -> value и name -> label
 * objectAdapter(obj, {id: 'value', name: 'label'}))
 * 
*/
export function objectAdapter
    (input: any, keysObj: any)
    :any {
        const new_obj: any = {}
        
        const newKeys = Object.keys(keysObj)
        for(let [key, value] of Object.entries(input)){
            if(newKeys.includes(key))
                key = keysObj[key]
            new_obj[key] = value
        }

        return new_obj
}



export function sortNumber(arr: any[], key: string, inreaseOrder: boolean = true){
    if(inreaseOrder)
        arr.sort((a, b) => a[key] - b[key])   
    else
        arr.sort((a, b) => b[key] - a[key])   
}


export function sortDate(arr: any[], key: string, inreaseOrder: boolean = true){
    if(inreaseOrder)
        arr.sort((a, b) => a[key].valueOf() - b[key].valueOf())   
    else
        arr.sort((a, b) => b[key].valueOf() - a[key].valueOf())   
}


function switchParameters(cb: any){
    return (...args: any) => {
        return cb(args[1], args[0])
    }    
}

function clearWhiteSpaceInStart(s: string){
    let i = 0
    while(s[i] === ' '){
        i++
    }
    return s.slice(i)
}

/**
 * @param arr Массив из объектов с ключом [key]
 * @param key Ключ по которому сортируется объект
 * @param inreaseOrder inreaseOrder? по возрастанию: по убыванию
 */
export function sortString(arr: any[], key: string, inreaseOrder: boolean = true){
    const compareFn = (a: any, b: any) => {
        const [s1, s2] = [String(a[key]), String(b[key])]
        let i = 0
        while(i < s1.length && i < s2.length){
            const res = s1.charCodeAt(i) - s2.charCodeAt(i)

            if(res != 0)
                return res
            i++
        }

        if(s1.length === 0 && s2.length > 0)
            return -1
        else if(s2.length === 0 && s1.length > 0)
            return 1

        return 0
    }

    if(inreaseOrder)
        arr.sort(compareFn)   
    else
        arr.sort(switchParameters(compareFn))
}


/**
 * 
 * Возвращает порядок среди соседних узлов, начиная с 0
 */
export const getOrderInSiblings = (e: {target: HTMLElement}) => {
    const currentNode = e.target;

    const childNodes = e.target.parentNode?.childNodes
    if(childNodes === undefined)
        return undefined

    let i = 0;
    for (const node of childNodes) {
        if (currentNode === node) return i;
        i++;
    }

    return undefined;
};  