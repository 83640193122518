import React, { Component } from "react"
import {Link} from "react-router-dom";
import store from '../Store';
import { Table, Button, Spinner, Nav, NavItem } from "react-bootstrap"

import api from "../Api.js";

class IntAccounts extends Component
{
    constructor(props)
    {
        super(props);
        this.state={isLoad:false,
			finance:[],
            Accounts:{},
			name:'',
			id_user:0,
			isButLoad:true,
			categories:[],
          	cat_id:1252324,
			isCatLoad:false,
			isAccLoad:false,
			total:0};
		this.loadUser = this.loadUser.bind(this);	
		this.refreshBank = this.refreshBank.bind(this);
		this.addUser = this.addUser.bind(this);
		this.LoadNotes = this.LoadNotes.bind(this);
		this.LoadCategories = this.LoadCategories.bind(this);
		// this.loadBank = this.loadBank.bind(this);
		this.loadUser = this.loadUser.bind(this);
    }
	
	async componentDidMount()
    {
		const response = await api('GET', 'int_accounts', 'userid', {token:store.User.token});
		const json = await response.id_user;
		this.setState({id_user: json});
        this.loadUser();
		this.LoadNotes(0);
		this.LoadCategories();
	}
	
	refreshBank=()=>
	{
		this.setState({isButLoad:false});
        api("GET","int_accounts","getf")
        .then((result)=>
		this.setState({isButLoad:true}));
		this.SelectCategory(this.state.cat_id);
	}
	
	LoadCategories()
	{
		this.setState({isCatLoad:false});
		api("GET", "int_accounts", "getaccp",{})
		.then((result)=>
		{ 
			this.setState(
				{categories:result}, ()=>{this.setState({isCatLoad:true})}
				)
				this.SelectCategory(this.state.cat_id);
		})
	}

	
	LoadNotes(cat_id)
	{
		this.setState({isAccLoad:false});
		api("GET", "int_accounts", "getfinance-by-acc",{accp:cat_id})
		.then((result)=>
		{ 
			this.setState({
				finance:result,
				isAccLoad:true
				})
		})
	}

	SelectCategory(id)
	{
		let categories = this.state.categories;
		
		function select(categories)
		{
			categories.map((category)=>
			{
				if(category.accp == id) {
					category.selected = true;
					// console.log(category.accp);
				}
				else {
					category.selected = false;
					// console.log(category.accp);
				}
					return 1;
			})
  
		}
		
		select(categories);
		this.setState({cat_id:Number(id),
			categories:categories});
			console.log(this.state.cat_id)
		this.LoadNotes(id);
	}

	
	loadUser()
	{
		if(this.state.id_user===23||this.state.id_user===43)
		{
			api("GET", "int_accounts", "views")
				.then((result)=>{   
				console.log(result);
				let total = 0;
				result.map((res)=>total+=res.balance);
				this.setState({Accounts:result,
					isLoad:true,
				total:total});
			});
		}
		else
		{
			console.log(this.state.id_user);
			api("GET", "int_accounts", "viewshort", {id:this.state.id_user})
				.then((result)=>{   
				console.log(result);
				let total = Number(0);
				result.map((res)=>total+=Number(res.balance));
				this.setState({Accounts:result,
					isLoad:true,
				total:total});
			});
		}
	}
	
	addUser(e)
	{
		api("GET","int_accounts","add",{name:this.state.name})
			.then((result)=>{
                if(result.Result==='ok')
                {
					this.loadUser();
                }
                else
                    console.log(result);                        
            });		
	}

    render()
    {
        const{isLoad, Accounts, total, id_user, finance, isButLoad, categories, isCatLoad} = this.state;
        return(
            <div>   
                <Table striped bordered hover size="sm">
					<thead>
                        <tr>
                            <th>Название</th>
                            <th>Баланс: {total}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {isLoad&&
                        (
                            Accounts.map((account, key)=>{
                                return (
                                    <tr key={key}>
                                        <td><Link to={'/IntAccount/'+account.id_user}>{account.name}</Link></td>
                                        <td>{account.balance}</td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
				<div>
				{isButLoad?(<Button type="submit" onClick={(e)=>{this.refreshBank(e)}}>Сделать запрос в банк</Button>):(<Spinner animation="border"/>)}
				{isCatLoad?(<div className="navbar navbar-expand-lg navbar-light " style={{'width':'100%'}}>
				<Nav id="navbarSupportedContent" className="navbar-nav mr-auto bg-dark" style={{paddingLeft:8, paddingRight:8, borderRadius:4}}>
            	{categories.map((Name, key)=>{return(
                <NavItem className="nav-item" key={key}>
							  <strong><a className={Name.selected?'nav-link text-dark bg-light':'nav-link text-light bg-dark'} href="#"
                                onClick={()=>{this.setState({cat_id:Name.accp}, ()=>{this.SelectCategory(this.state.cat_id)});
                                    }}>
								{Name.account} <font style={{'color':'red'}}> [{(Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(Name.balance))} ]</font>
							</a></strong></NavItem>

							
              	)})}
              	</Nav>
				  </div>):(<div> </div>)}
				<Table bordered hover size="sm">
				<thead>
                        <tr>
							<th>Дата транзакции</th>
							<th>Отправитель</th>
							<th>Получатель</th>
							<th>Сумма</th>
							<th>Описание</th>
							<th>Название счёта</th>
							<th>Назначение счёта</th>
                        </tr>
                    </thead>
				<tbody>
            	{
                finance.map((row, key)=>(
                    <tr class={
						(row.tin==row.tin_payer)?
						"table-light":
						(row.tin=='3128148768')?
						"table-success":
						'table-warning'
						} key={row.id}>
						<td>{row.document_date}</td>
						<td>{row.payer_name}</td>
						<td>{row.beneficiary_name}</td>
						<td>{row.amount}</td>
						<td>{row.description}</td>
						<td>{row.account}</td>
						<td>{row.account_kt}</td>
                    </tr>
                ))
            	}
           		</tbody>
				</Table></div>
                {(id_user===23||id_user===43)&&<div className="container">
					<div className="row">
						<div className="col-sm"><input className="form-control" name="name" value={this.state.name}
							onChange={(e)=>{this.setState({name:e.target.value})}}/></div>
						<div className="col-sm"><Button type="submit" onClick={(e)=>{this.addUser(e)}}>Добавить контрагента</Button></div>
					</div>
				</div>}
            </div>
        );
    }
}

export default IntAccounts;