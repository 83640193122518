import React, { Component } from "react"
import { Table, Button, Form } from "react-bootstrap"

import api from "../Api.js";
import Select from './../Components/SelectDictionary'

class IntAccount extends Component
{
    constructor(props)
    {
        super(props);
        this.state={isLoad:false,
			date:{month:new Date().getMonth()+1,
            year:new Date().getFullYear()},
            Account:{id:this.props.match.params.id},
            history:{},
                id_account:this.props.match.params.id,
                category:'',
                purpose:'',
                comment:'',
                summ:0,
				balance:0,
        };
        
        this.handleAdd = this.handleAdd.bind(this);
		this.loadHistory = this.loadHistory.bind(this);
    }
	componentDidMount()
    {
        this.loadHistory(this.state.date.month, this.state.date.year);
    }
	
	loadHistory = (month, year)=>
	{
		api('GET', 'int_accounts', 'get-account-by-id',{id:this.state.Account.id})
        .then((account)=>{
            api('GET', 'int_accounts', 'get-history-by-id',{id:this.state.Account.id, month:month, year:year})
            .then((history)=>{  
                console.log(history);
				let total = Number(0);
				history.map((res)=>total+=Number(res.summ));				
                this.setState({Account:account,
					history:history,
					isLoad:true,
					date:{...this.state.date, month:month, year:year},
					balance:total});
            });
        });
	}
	
	nextMonth=()=>
    {
        if(this.state.date.month===12)
            this.loadHistory(1,this.state.date.year+1);
        else
            this.loadHistory(this.state.date.month+1,this.state.date.year);
    }
    previousMonth=()=>
    {
        if(this.state.date.month===1)
            this.loadHistory(12,this.state.date.year-1);
        else
            this.loadHistory(this.state.date.month-1,this.state.date.year);
    }
    nextYear=()=>
    {
            this.loadHistory(this.state.date.month,this.state.date.year+1);
    }
    previousYear=()=>
    {
            this.loadHistory(this.state.date.month,this.state.date.year-1);
	}

    handleChange(e) {
        if(e.target.name==="summ" && e.target.value === 0)
            return 0;

        if(e.target.type=='checkbox')
            this.setState({ [e.target.id]: e.target.checked });
        else
            this.setState({ [e.target.name]: e.target.value });
    }

    handleAdd()
    {
        api('POST', 'int_accounts', 'add-history',{
            id_account:this.state.Account.id,
            category:this.state.category,
            purpose:this.state.purpose,
            comment:this.state.comment,
            summ:this.state.summ})
            .then((response)=>{   
                console.log(response);

                this.setState({isLoad:false});

                this.loadHistory(this.state.date.month, this.state.date.year);
            });
    }

    render()
    {
        const{isLoad, history, date, balance} = this.state;
		var months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", 
            "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
			
        return(
            <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <td colSpan = '2'>
                                    <Button onClick={this.handleAdd}>Добавить</Button>
                                </td>
                                <td>
                                    <Select placeholder="Категория"
                                        dictionary="int_accounts_history_category" 
                                        name="category"
                                        onChange={(e)=>{this.handleChange(e)}}/>
                                </td>
                                <td>
                                    <Select placeholder="Назначение"
                                        dictionary="int_accounts_history_purpose" 
                                        name="purpose"
                                        onChange={(e)=>{this.handleChange(e)}}/>
                                </td>
                                <td><Form.Control type="number" name="summ" 
                                    value={this.state.summ} size="sm"
                                    onChange={(e)=>{this.handleChange(e)}}/>
                                </td>
                                <td><Form.Control type="text" name="comment" 
                                    value={this.state.comment} size="sm"
                                    onChange={(e)=>{this.handleChange(e)}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><Button onClick={this.previousYear} size="sm">&lt;</Button>
									&nbsp;&nbsp;{date.year}&nbsp;&nbsp;
								<Button onClick={this.nextYear} size="sm">&gt;</Button>
								&nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={this.previousMonth} size="sm">&lt;</Button>
									&nbsp;&nbsp;{months[date.month-1]}&nbsp;&nbsp;
								<Button onClick={this.nextMonth} size="sm">&gt;</Button></td>
								<td colSpan={2}></td>
                                <th>Итого: {balance}</th>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Дата</th>
                                <th>Пользователь</th>
                                <th>Категория</th>
                                <th>Назначение</th>
                                <th>Сумма</th>
                                <th>Комментарий</th>
                            </tr>
                        </thead>
                        {isLoad&&(
                        <tbody>

                            {
                                history.map((row, key)=>(
                                    <tr key={key}>
                                        <td>{row.date}</td>
                                        <td>{row.id_user}</td>
                                        <td>{row.id_category}</td>
                                        <td>{row.id_purpose}</td>
                                        <td>{row.summ}</td>
                                        <td>{row.comment}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    )}
                    </Table>

            </div>
        );
    }
}

export default IntAccount;