import React, { Component } from "react";
import { Container, Row, Col, Alert, Form, Button } from "react-bootstrap";
import {Link} from "react-router-dom";

import api from './Api.js'

class RegForm extends Component {
  constructor(props) {
    super(props);
    this.state={
      login:'1',
      pass:'',
      doublepass:'',
      email:'',
      family:'',
      name:'',
      patronymic:'',
      birthday:'',
      error:'',
    };
    this.ChangeValue = this.ChangeValue.bind(this);
    this.RegisterSubmit = this.RegisterSubmit.bind(this);
  }

  ChangeValue(e)
  {
    this.setState({[e.target.name]:e.target.value});
  }

  RegisterSubmit()
  {
    if(this.state.pass!==this.state.doublepass)
    {
      this.setState({error:'Повтор пароля не совпадает с паролем'});
    }
    else
    {
      let data={
        login:this.state.login,
        pass:this.state.pass,
        email:this.state.email,
        family:this.state.family,
        name:this.state.name,
        patronymic:this.state.patronymic,
        birthday:this.state.birthday,
      };
      var crypto = require('crypto-browserify');
      data.pass = crypto.createHash('md5').update(data.pass).digest('hex');
      var fio = '' + data.family + ' ' + data.name + ' ' + data.patronymic;
      console.log(fio);
      api("POST", "auth", "register", data)
      .then((response)=>{
        if(response.Result==='Ok')
          this.props.history.push('/');
        else
          this.setState({error:response.error});
      });
    }
  }

  render() {
     return (
      <Container>
          <Row>
              <Col>
                Регистрация
              </Col>
          </Row>
          <Row>
            <Col>
                <Form>
                  <Form.Group controlId="formLogin">
                    <Form.Label>Логин:</Form.Label>
                    <Form.Control type="text" placeholder="Введите логин"
                       name="login" onChange={this.ChangeValue}/>
                  </Form.Group>

                  <Form.Group controlId="formPass">
                    <Form.Label>Пароль:</Form.Label>
                    <Form.Control type="password" placeholder="Введите пароль"
                      name="pass" onChange={this.ChangeValue} />
                  </Form.Group>

                  <Form.Group controlId="formDoublePass">
                    <Form.Label>Повторите пароль:</Form.Label>
                    <Form.Control type="password" placeholder="Повторите пароль" 
                      name="doublepass" onChange={this.ChangeValue}/>
                  </Form.Group>

                  <Form.Group controlId="formEmail">
                    <Form.Label>Введите ваш E-mail:</Form.Label>
                    <Form.Control type="email" placeholder="e-mail" 
                      name="email" onChange={this.ChangeValue}/>
                  </Form.Group>

                  <Form.Group controlId="formFamily">
                    <Form.Label>Ваша фамилия:</Form.Label>
                    <Form.Control type="text" placeholder="Фамилия" 
                      name="family" onChange={this.ChangeValue}/>
                  </Form.Group>

                  <Form.Group controlId="formName">
                    <Form.Label>Ваше имя:</Form.Label>
                    <Form.Control type="text" placeholder="Имя" 
                      name="name" onChange={this.ChangeValue}/>
                  </Form.Group>

                  <Form.Group controlId="formPatronymic">
                    <Form.Label>Ваше отчество:</Form.Label>
                    <Form.Control type="text" placeholder="Отчество" 
                      name="patronymic" onChange={this.ChangeValue}/>
                  </Form.Group>

                  <Form.Group controlId="formBirthday">
                    <Form.Label>Дата вашего рождения:</Form.Label>
                    <Form.Control type="date" placeholder="Дата рождения" 
                      name="birthday" onChange={this.ChangeValue}/>
                  </Form.Group>
                </Form>
                {this.state.error&&
                  <Alert variant="danger">
                    {this.state.error}
                  </Alert>}
                <Button variant="primary" type="submit" onClick={this.RegisterSubmit}>
                  Зарегистрироваться
                </Button>
                <Link to="/" className="btn btn-primary" style={{marginLeft: .5 + 'em'}}>Назад</Link>
            </Col>
          </Row>
      </Container>
    );
  }
}

export default (RegForm);
