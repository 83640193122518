import React, {Component} from 'react';
import { Modal, Spinner, Form, Nav, NavItem, Button} from 'react-bootstrap';
import api from '../Api'
import {Link} from "react-router-dom";
import store from '../Store';
import * as Icon from 'react-bootstrap-icons';
import Swal from 'sweetalert2'

class Notes extends Component {
    constructor(props)
    {
        super(props);
        this.AddNotes = this.AddNotes.bind(this);
        this.LoadNotes = this.LoadNotes.bind(this);
        this.LoadCategories = this.LoadCategories.bind(this);
        this.SelectCategory = this.SelectCategory.bind(this);
        this.state={
          title:'Без заголовка',
          description:'',
          notes:{},
          categories:[],
          cat_id:0,
          time: new Date().toLocaleString(),
          EntityModalParam:{show:false},
          AddCategoryModalParam:{show:false},
          isLoad:false,
          isCatLoad:false
        };
    }

    componentDidMount()
    {
        this.LoadCategories();
        this.LoadNotes(0);
        this.intervalID = setInterval(() => this.tick(), 1000);
    }

  AddNotes()
	{
		  api("GET","notes","add-notes",{
            title:this.state.title,
            description:this.state.description,
            category:this.state.cat_id, token:store.User.token})
                .then((result)=>{
                    if(result.Result==='ok')
                    {
                      this.setState({title:'Без заголовка', description:''});
					          	this.LoadNotes(this.state.cat_id);
                    }
                    else
                        console.log(result);                        
                });
  }

  LoadCategories()
  {
        this.setState({isCatLoad:false});
      api("GET", "notes", "get-categories",{})
      .then((result)=>
      { 
          this.setState(
              {categories:result}, ()=>{this.setState({isCatLoad:true})}
              )
              this.SelectCategory(this.state.cat_id);
      })
  }

  LoadNotes(cat_id)
  {
      this.setState({isLoad:false});
      api("GET", "notes", "get-notes-by-category",{category:cat_id})
      .then((result)=>
      { 
          this.setState({
              notes:result,
              isLoad:true
              })
      })
  }

  SelectCategory(id)
  {
      let categories = this.state.categories;
      
      function select(categories)
      {
          categories.map((category)=>
          {
              if(category.id == id) 
                  category.selected = true;
              else 
                  category.selected = false; 
              
                  return 1;
          })

      }
      
      select(categories);
      this.setState({cat_id:Number(id),
          categories:categories});
      this.LoadNotes(id);
  }


  componentWillUnmount() {
  clearInterval(this.intervalID);
}
  tick() {
    this.setState({
      time: new Date().toLocaleString(),
   });
}

    render()
    {
        const {notes, isLoad, EntityModalParam, AddCategoryModalParam, categories, isCatLoad} = this.state;
        return(
          <div className='card mt-3 bg-light' >
             <EntityModal show={EntityModalParam.show}
            onHide={()=>{
                this.setState({EntityModalParam:{show:false}})
            }}
            LoadNotes={this.LoadNotes}
            SelectCategory={this.SelectCategory}
            param={EntityModalParam}/>
            <AddCategoryModal show={AddCategoryModalParam.show}
            onHide={()=>{
                this.setState({AddCategoryModalParam:{show:false}});
            }}
            LoadCategories={this.LoadCategories}
            SelectCategory={this.SelectCategory}
            param={AddCategoryModalParam}/>
            {isCatLoad?(<div className="navbar navbar-expand-lg navbar-light " style={{'width':'100%'}}>
            <Icon.CardText width="24" height="24" style={{marginRight:'5px'}} /><a className="navbar-brand mb-0 h1">Заметки</a>
            <Nav id="navbarSupportedContent" className="navbar-nav mr-auto bg-dark" style={{paddingLeft:8, paddingRight:8, borderRadius:4}}>
            <strong><a className={(this.state.cat_id===0)?'nav-link text-dark bg-light':'nav-link text-light bg-dark'} href="#"
                                onClick={()=>{this.setState({cat_id:0}, ()=>{this.SelectCategory(this.state.cat_id)});
                                    }}>
								Без категории
							</a></strong>
            {categories.map((Name, key)=>{return(
                <NavItem className="nav-item" key={key}>
							  <strong><a className={Name.selected?'nav-link text-dark bg-light':'nav-link text-light bg-dark'} href="#"
                                onClick={()=>{this.setState({cat_id:Name.id}, ()=>{this.SelectCategory(this.state.cat_id)});
                                    }}>
								{Name.name}
							</a></strong></NavItem>
              )})}
              </Nav>
              <div className="form-inline my-2 my-lg-0">
						{this.state.cat_id!=0 && (<button className="btn btn-sm btn-dark mr-2"  onClick={()=>{this.setState({AddCategoryModalParam:{show:true,id:this.state.cat_id}
										})}}>Редактировать категорию</button>)}
                                        <button className="btn btn-sm btn-dark" onClick={()=>{this.setState({AddCategoryModalParam:{show:true}
										})}}>Добавить категорию</button>
				</div>
              </div>):(<div> </div>)}
          {isLoad?(<div>
            <div className="card-deck">
            <div className='m-4 text-center' style={{width: 250}}>
            <div className="card" style={{width: 250}}>
              <div className="card-header">
                <input className="card-textarea" maxLength="25" placeholder="Заголовок"
						    onChange={(e)=>{this.setState({title:e.target.value})}}></input>
              </div>
              <div className="card-body" style={{height: 230}}>
                <textarea className="card-textarea" placeholder="Описание"
						onChange={(e)=>{this.setState({description:e.target.value})}}></textarea>
              </div>
              <div className="card-footer">
                  <div style={{'width':'50%', 'float': 'left', textAlign:'left'}}>
                  {store.User.Name}</div>
                  <div style={{textAlign:'right'}}>{this.state.time}</div>
                </div>
                <Button className="my-n2" size="sm" type="submit" onClick={(e)=>{this.AddNotes()}}>Добавить</Button>
            </div>
            </div>
                {notes.map((Row, key)=>{return(<div className='m-4 text-center' style={{width: 250}} key={key}>
                <div className="card" style={{width: 250}}>
                <div className="card-header">{Row.title}</div>
                <p className="card-text" style={{height: 230, 'overflow': 'auto', whiteSpace:'pre-wrap'}}>{Row.description}</p>
                <div className="card-footer">
                  <div style={{'width':'50%', 'float': 'left',  textAlign:'left'}}>
                <Link to={"/user/"+Row.id_user}>{Row.name_user}</Link></div>
                  <div style={{textAlign:'right'}}>{Row.date_time}</div>
                </div>
                <Button size="sm"
                        onClick={()=>{this.setState({EntityModalParam:{show:true,
                            id:Row.id}})}}>
                        Открыть
                    </Button>
                </div>
                </div>)})}
        </div>
        </div>):(<Spinner animation="border"/>)}</div>
        )
    } 
}

class EntityModal extends Component
{
    data={
        id:0,
        title:'',
        description:'',
        category:0
      };

    constructor(props)
    {
        super(props)
        this.state={
            isLoading:false,
            isComLoad:false,
            data:this.data,
            comments:{},
			commentar:'',
			count:'',
            cat_id:0,
            categories:[],
            ...this.props.Com
        };
		this.AddCommentar = this.AddCommentar.bind(this);
		this.LoadComments = this.LoadComments.bind(this);
        this.state.data.id=this.props.param.id;
        this.state.cat_id=this.props.param.cat_id;
    }

    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {   api("GET", "notes", "get-categories",{})
            .then((result)=>
            {this.setState({categories:result})});
            api("GET", "notes", "get-note",{id:this.props.param.id})
            .then(result=>this.setState({data:{...result[0]},comments:[...result[1]], count:[...result[2]],  isLoading:true, isComLoad:true}));
        }
    }

    AddCommentar(e)
	{
		e.preventDefault();
		this.setState({isComLoad:false});
		api("GET","notes","addcom",{idp:this.props.param.id,comment:this.state.commentar, token:store.User.token})
				.then((result)=>{
                    if(result.Result==='done')
                    {
						this.LoadComments();
						// this.state.commentar='';
						this.setState({commentar:''});
                    }
                    else
                        console.log(result);                        
                })
	}
	
	LoadComments()
	{
		this.setState({isComLoad:false});
		api("GET", "notes", "get-note",{id:this.props.param.id})
			.then(result=>{
				this.setState({comments:[...result[1]], count:[...result[2]], isLoading:true, isComLoad:true});
			})
	}

    Save = () =>
    {
        api("GET", "notes", "edit-notes",{id:this.state.data.id?this.state.data.id:0,
            ...this.state.data})
            .then(()=>{
                this.props.SelectCategory(this.state.data.category);
                this.Close();
            });
    }


    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data,  isLoading:false});
    }

    Delete = () =>
    {
		api("GET","notes","del-notes",{id:this.state.data.id})
                .then((result)=>{
                    this.props.LoadNotes(this.state.data.category);
                    this.Close()
                    if(result.Result==='ok')
                    {
						console.log("Строка №:"+this.state.data.id);
						
                    }
                    else
						console.log("Строка №:"+this.state.data.id);
                        console.log(result);                        
                });
    }

    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

    render()
    {
        const {data, categories, comments, isComLoad} = this.state;
        
        return(<Modal
            {...this.props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            >
              <Form>
                <Modal.Header>
                    <Modal.Title>
                        Редактировать
                      {this.props.param.id && <Button onClick={this.Delete}>Удалить</Button>}
                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Заголовок</Form.Label>
                        <Form.Control type="text" name="title" size="sm"
                            value={data.title} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Описание</Form.Label>
                        <textarea className="form-control" type="text" name="description" style={{"height":"230px", "resize":"none"}}
                            value={data.description} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Категория</Form.Label>
						<select className="form-control" name="category" value={data.category}
                        onChange={this.changeValue}>
                        <option value='0'>Без категории</option>
						{categories.map((category, key)=>{return(
                        <option value={category.id}>{category.name}</option>
                        )})}
					    </select>
                    </Form.Group>
                    {this.props.param.id && <Form.Group controlId="NameControlGroup">
					{isComLoad?(<div>
        			<h3 className="title-comments">Комментарии ({this.state.count})</h3>
					<textarea className="form-control" style={{'resize':'none'}} name="commentar" size="sm" placeholder="Комментарий"
                            onChange={(e)=>{this.setState({commentar:e.target.value})}}/>
					<Button className="mt-1" size="sm" type="submit" onClick={(e)=>{this.state.commentar?(this.AddCommentar(e)):(this.LoadComments())
						}}>Добавить</Button>
				    {comments.map((Row, key)=> <Comments key={key} Row={Row}
					LoadComments={this.LoadComments}/>)}
					</div>):(<Spinner animation="border"/>)}
					</Form.Group>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.Save}>Сохранить</Button>
                    <Button onClick={this.Close}>Отмена</Button>
                </Modal.Footer>
              </Form>
          </Modal>);
    };
};

class Comments extends Component{
	constructor(props)
	{
		super(props);
		this.state={...this.props.Row,
		isFocus: false};
		this.editRow = this.editRow.bind(this);
	}
	
	editRow(e)
    {
		e.preventDefault();
		api("GET","notes","editcom",{...this.state})
                .then((result)=>{
                    if(result.Result==='done')
                    {
						this.props.LoadComments();
                    }
                    else
                        console.log(result);                        
                });
    }
	
	deleteRow(e)
    {
		e.preventDefault();
		api("GET","notes","delcom",{...this.state})
                .then((result)=>{
                    if(result.Result==='done')
                    {
						this.props.LoadComments();
                    }
                    else
                        console.log(result);                        
                });
    }
	render()
	{
		return(
               <div className="container">
				<div className="card mt-2 mr-1">
	    		<div className="card-body">
	        	<div>
				<div style={{'width':'50%', 'float': 'left',  textAlign:'left'}}>
                	<Link to={"/user/"+this.state.autor}>{this.state.name_user}</Link></div>
                	<div style={{textAlign:'right'}}>{this.state.date_time}</div>
        		</div>
				<div>
					<textarea style={{'border':'none'}} rows='3' className="cursor-edit card-textarea ml-2" name="comment" value={this.state.comment}
					onChange={(e)=>{this.setState({comment:e.target.value, isFocus:true})}}>{this.state.comment}</textarea>
				</div>
					<div className='text-right mt-1'>
					{this.state.isFocus?
				(<Button size="sm" variant="info" type="submit" onClick={(e)=>{this.editRow(e)}}>Изменить</Button>):
				(<Button size="sm" variant="danger" type="submit" onClick={(e)=>{this.deleteRow(e)}}>Удалить</Button>)}
					</div>
	   			</div>
				</div>
				</div>);
	}
}

class AddCategoryModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={description:'', id:0, isLoading:false};
		this.Save = this.Save.bind(this);
        this.Add = this.Add.bind(this);
        this.Delete = this.Delete.bind(this);
        this.Close = this.Close.bind(this);
    }
    
    componentWillUnmount()
    {
        this.setState({description:'', id:0, isLoading:false});
    }

	componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "notes", "get-category-by-id",{id:this.props.param.id})
            .then(result=>this.setState({description:result.name, id:this.props.param.id, isLoading:true}))
        }
    }
	
    Save()
    {
        
        api("GET", "notes", "editcat",{id:this.props.param.id, name:this.state.description})
            .then(()=>{this.Close();
                this.props.LoadCategories(0);
                });
                
    }
    
    Delete()
    {
        api("GET", "notes", "delete-category",{id:this.props.param.id})
           .then(()=>{this.Close();
            this.props.LoadCategories();
            this.props.SelectCategory(0);});
           
    }
	
	Add()
    {
        api("GET", "notes", "addcat",{des:this.state.description})
            .then(()=>{this.Close();
                this.props.LoadCategories();});
    }

    Close()
    {
		this.props.onHide();
		this.setState({description:'', id:0, isLoading:false});
    }

    render()
    {
        return(
			<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          	>
              <Form>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.param.id?(<div>Редактировать категорию
                    <Button onClick={(e)=>{e.preventDefault();
										Swal.fire({
											title: 'Все записи перенесутся в раздел "Без категории". Вы уверены?',
											icon: 'warning',
											confirmButtonColor: true,
											showCancelButton: true,
											confirmButtonText: 'Да',
											cancelButtonText: 'Отмена',
											confirmButtonColor: '#3085d6',
											cancelButtonColor: '#d33',
										}).then((result) => {
											if (result.isConfirmed) {
												Swal.fire({
													icon: 'success',
													title: 'Категория удалена',
													showConfirmButton: false,
													timer: 750
												})
												this.Delete()
											}
											else {
												e.preventDefault()
											}
										})}}>
                    Удалить
                </Button>
                </div>
                ):'Создать категорию'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Введите название категории</Form.Label>
                <Form.Control type="text" name="name" size="sm"
                            value={this.state.description}
                            onChange={(e)=>{this.setState({description:e.target.value})}}/>
                            </Form.Group>
            </Modal.Body>
            <Modal.Footer>
			{this.props.param.id?
				(<Button variant="primary" type="submit" onClick={(e)=>{
                    e.preventDefault();
                    this.Save();
                    }}>Сохранить</Button>):
                (<Button variant="primary" type="submit" onClick={(e)=>{
                    e.preventDefault();
                    this.Add();
                    }}>Добавить</Button>)
            }
              <Button onClick={this.Close}>Отмена</Button>
            </Modal.Footer>
            </Form>
          </Modal>  
        );
    }
}

export default Notes;