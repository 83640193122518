import React, {Component, useState} from 'react';
import { Container, Tab, Tabs, Table, Spinner, Button, Modal, Form } from 'react-bootstrap';
import Select from './../Components/SelectDictionary';
import api from '../Api';
import store from '../Store';

class Entity extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {isLoad:false,
        Entities:{},
        EntityModalParam:{show:false}};
    }
    LoadData = () =>{
        this.setState({isLoad:false});
        api("GET", "counterparties", "get-counterparties",{type:'Entity'})
        .then(Result=>{this.setState({Entities:Result, isLoad:true})});
    }
    componentDidMount()
    {
        this.LoadData();
    }
    render()
    {
        //const [EntityModalParam, setEntityModalParam] = React.useState({show:false});
        const {isLoad, Entities, EntityModalParam} = this.state;
        return(<div>
            <EntityModal show={EntityModalParam.show}
                onHide={()=>{
                    this.setState({EntityModalParam:{show:false}})
                }}
                LoadData={this.LoadData}
                param={EntityModalParam}
            />
            {isLoad?(<Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Название организации</th>
                    <th>ИНН</th>
                    <th>Расположение</th>
                    <th>Вид деятельности</th>
                    <th>Тип контрагента</th>
                    <th>Комментарий</th>
                    <th>Почта</th>
                    <th>Телефон</th>
                    <th style={{width:'30px'}}>
                        <Button size="sm"
                        onClick={()=>{this.setState({EntityModalParam:{show:true}})}}>
                            Добавить
                        </Button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {Entities.map((Row, key)=>{return(<tr key={key}>
                <td>{Row.name}</td>
                <td>{Row.tin}</td>
                <td>{Row.location}</td>
                <td>{Row.activity}</td>
                <td>{Row.counterparty_type}</td>
                <td>{Row.comment}</td>
                <td>{Row.mail}</td>
                <td>{Row.telephone}</td>
                <td>
                    <Button size="sm"
                        onClick={()=>{this.setState({EntityModalParam:{show:true,
                            id:Row.id}})}}>
                        Редактировать
                    </Button>
                </td>
                </tr>)})}
            </tbody>
        </Table>):(<Spinner animation="border"/>)}</div>);
    }
};

class EntityModal extends Component
{
    data={
        id:0,
        name:'',
        tin:0,
        location:'',
        activity:'',
        counterparty_type:'',
        comment:'',
        mail:'',
        telephone:0,
        type:'Entity'};

    constructor(props)
    {
        super(props)

        this.state={
            isLoading:false,
            data:this.data,
            comdata:[],
        };
        this.state.data.id=this.props.param.id;
    }

    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "counterparties", "get-counterparty-by-id",{id:this.props.param.id})
            .then(result=>this.setState({data:{...result}, isLoading:true}))
            .then(() =>{
                api("GET", "counterparties", "get-entity-comments",{idp:this.props.param.id})
                .then(result=>{
                    this.setState({comdata:result, isLoading:true});
                    console.log(this.state.comdata)})
                });
        }
    }
    Save = () =>
    {
        api("GET", "counterparties", "set-counterparty-data",{id:this.state.data.id?this.state.data.id:0,
            data:{...this.state.data}, token:store.User.token})
            .then(()=>{
                this.props.LoadData();
                this.Close();
            });
            {console.log(this.data)}
    }

    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data,  isLoading:false});
    }

    Delete = () =>
    {
        api("GET", "counterparties", "delete-counterparty",{id:this.state.data.id})
           .then(()=>{
            this.props.LoadData();   
            this.Close()
        });   
    }


    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

    render()
    {
        const {data} = this.state;

        return(<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
              <Form>
                <Modal.Header>
                    <Modal.Title>
                      {this.props.param.id?'Редактировать контрагента':'Добавить контрагента'}
                      {this.props.param.id && <Button onClick={this.Delete}>Удалить</Button>}
                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Наименование</Form.Label>
                        <Form.Control type="text" name="name" size="sm"
                            value={data.name} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>ИНН</Form.Label>
                        <Form.Control type="number" name="tin" size="sm"
                            value={data.tin} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Местоположение</Form.Label>
                        <Form.Control type="text" name="location" size="sm"
                            value={data.location} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Вид деятельности</Form.Label>
                        <Form.Control type="text" name="activity" size="sm"
                            value={data.activity} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Тип контрагента</Form.Label>
						<select className="form-control" name="counterparty_type" value={data.counterparty_type}
                        onChange={this.changeValue}>
						<option value="Покупатель">Покупатель</option>
						<option value="Поставщик">Поставщик</option>
					</select>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Комментарий</Form.Label>
                        <Form.Control type="text" name="comment" size="sm"
                            value={data.comment} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Почта</Form.Label>
                        <Form.Control type="text" name="mail" size="sm"
                            value={data.mail} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Телефон</Form.Label>
                        <Form.Control type="number" name="telephone" size="sm"
                            value={data.telephone} onChange={this.changeValue}/>
                    </Form.Group>
                    <table class="table table-hover">
                        <tbody>
                            {this.state.comdata.map((comment, key)=>
                            <tr key={key}>
                                <td>{comment.comment}</td>
                                <td>{comment.datetime}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.Save}>Сохранить</Button>
                    <Button onClick={this.Close}>Отмена</Button>
                </Modal.Footer>
              </Form>
          </Modal>);

    };
};

class Individual extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {isLoad:false,
        Entities:{},
        IndividualModalParam:{show:false}};
    }

    LoadData = () =>{
        this.setState({isLoad:false});
        api("GET", "counterparties", "get-counterparties",{type:'Individual'})
        .then(Result=>{this.setState({Entities:Result, isLoad:true})});
    }
    componentDidMount()
    {
        this.LoadData();
    }
    render()
    {
        //const [EntityModalParam, setEntityModalParam] = React.useState({show:false});
        const {isLoad, Entities, IndividualModalParam} = this.state;
        return(<div>
            <IndividualModal show={IndividualModalParam.show}
                onHide={()=>{
                    this.setState({IndividualModalParam:{show:false}})
                }}
                LoadData={this.LoadData}
                param={IndividualModalParam}
            />
            {isLoad?(<Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Название организации</th>
                    <th>Фамилия</th>
                    <th>Имя</th>
                    <th>Отчество</th>
                    <th>Населённый пункт</th>
                    <th>Комментарий</th>
                    <th>Телефон</th>
                    <th style={{width:'30px'}}>
                        <Button size="sm"
                        onClick={()=>{this.setState({IndividualModalParam:{show:true}})}}>
                            Добавить
                        </Button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {Entities.map((Row, key)=>{return(<tr key={key}>
                <td>{Row.org_name}</td>
                <td>{Row.family}</td>
                <td>{Row.person_name}</td>
                <td>{Row.patronymic}</td>
                <td>{Row.location}</td>
                <td>{Row.comment}</td>
                <td>{Row.telephone}</td>
                <td>
                    <Button size="sm"
                        onClick={()=>{this.setState({IndividualModalParam:{show:true,
                            id:Row.id}})}}>
                        Редактировать
                    </Button>
                </td>
                </tr>)})}
            </tbody>
        </Table>):(<Spinner animation="border"/>)}</div>);
    }
};

class IndividualModal extends Component
{
    data={
        id:0,
        org_name:'',
        family:'',
        person_name:'',
        patronymic:'',
        location:'',
        comment:'',
        telephone:0,
        type:'Individual'};

    name={name:'', type:'Entity'};

    constructor(props)
    {
        super(props)

        this.state={
            isLoading:false,
            data:this.data,
            name:this.name,
        };
        this.state.data.id=this.props.param.id;
    }

    handleChange(e) {
        this.setState({data:{...this.state.data, org_name:e.target.label}});
    }

    componentDidUpdate()
    {
        if(this.props.param.id && this.state.isLoading===false)
        {
            api("GET", "counterparties", "get-counterparty-by-id",{id:this.props.param.id})
            .then(result=>this.setState({data:{...result}, name:{...result}, isLoading:true}))
            
        }
    }

    Save = () =>
    {   
        api("GET", "counterparties", "set-counterparty-data",{id:this.state.data.id?this.state.data.id:0,
            data:{...this.state.data}, token:store.User.token})
            .then(()=>{
                this.props.LoadData();
                this.Close();
            });
            
    }

    Close = () =>
    {
        this.props.onHide();
        this.setState({data:this.data,  isLoading:false});
    }

    Delete = () =>
    {
        api("GET", "counterparties", "delete-counterparty",{id:this.state.data.id})
           .then(()=>{
            this.props.LoadData();   
            this.Close()
        });   
    }


    changeValue = (e) => this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});

    render()
    {
        const {data, name} = this.state;

        return(<Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
          >
              <Form>
                <Modal.Header>
                    <Modal.Title>
                      {this.props.param.id?'Редактировать физическое лицо':'Добавить физическое лицо'}
                      {this.props.param.id && <Button onClick={this.Delete}>Удалить</Button>}
                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Наименование</Form.Label>
                        <Select placeholder="Введите название организации"
                                dictionary="counterparties" 
                                name="name"
                                value={this.state.name}
                                onChange={(e)=>{this.handleChange(e)}}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Фамилия</Form.Label>
                        <Form.Control type="text" name="family" size="sm"
                            value={data.family} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control type="text" name="person_name" size="sm"
                            value={data.person_name} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Отчество</Form.Label>
                        <Form.Control type="text" name="patronymic" size="sm"
                            value={data.patronymic} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Населённый пункт</Form.Label>
                        <Form.Control type="text" name="location" size="sm"
                            value={data.location} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Комментарий</Form.Label>
                        <Form.Control type="text" name="comment" size="sm"
                            value={data.comment} onChange={this.changeValue}/>
                    </Form.Group>
                    <Form.Group controlId="NameControlGroup">
                        <Form.Label>Телефон</Form.Label>
                        <Form.Control type="number" name="telephone" size="sm"
                            value={data.telephone} onChange={this.changeValue}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.Save}>Сохранить</Button>
                    <Button onClick={this.Close}>Отмена</Button>
                </Modal.Footer>
              </Form>
          </Modal>);

    };
};

class Staff extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {isLoad:false,
        Staff:{}};
    }

    componentDidMount()
    {
        this.setState({isLoad:false});
        api("GET", "counterparties", "get-counterparties",{type:'Staff'})
        .then(Result=>{this.setState({Staff:Result, isLoad:true})});
    }
    render()
    {
        const {isLoad, Staff} = this.state;
        return(<div>{isLoad?(<Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>Фамилия</th>
                    <th>Имя</th>
                    <th>Отчество</th>
                </tr>
            </thead>
            <tbody>
                {Staff.map((Row, key)=>{return(<tr key={key}>
                <td>{Row.family}</td>
                <td>{Row.name}</td>
                <td>{Row.patronymic}</td>
                </tr>)})}
            </tbody>
        </Table>):(<Spinner animation="border"/>)}</div>);
    }
};

function Counterparties (){
    const [tab, setTab] = useState('entity'); 
    return (
        <Container>
            <Tabs id="controlled-tab-example" activeKey={tab} onSelect={k => setTab(k)}>
                <Tab eventKey="entity" title="Организации">
                    <Entity/>
                </Tab>
                <Tab eventKey="Individual" title="Физ. лица">
                    <Individual/>
                </Tab>
                <Tab eventKey="Staff" title="Сотрудники" disabled>
                    <Staff/>
                </Tab>
            </Tabs>
        </Container>       
    );
}
export default Counterparties;